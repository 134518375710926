import { render, staticRenderFns } from "./FilterUserSegment.vue?vue&type=template&id=12ab8617&"
import script from "./FilterUserSegment.vue?vue&type=script&lang=js&"
export * from "./FilterUserSegment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2512099944/src/github.com/Hitobito-Inc-Taiwan-branch/chatbot-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12ab8617')) {
      api.createRecord('12ab8617', component.options)
    } else {
      api.reload('12ab8617', component.options)
    }
    module.hot.accept("./FilterUserSegment.vue?vue&type=template&id=12ab8617&", function () {
      api.rerender('12ab8617', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Segment/Components/FilterUserSegment.vue"
export default component.exports