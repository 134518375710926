import md5 from 'js-md5'
import axios from 'axios'
export const Utility = {
  deepCopy: function (inObject) {
    let outObject, value, key

    if (typeof inObject !== 'object' || inObject === null) {
      return inObject // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}

    for (key in inObject) {
      value = inObject[key]

      // Recursively (deep) copy for nested objects, including arrays
      outObject[key] = this.deepCopy(value)
    }

    return outObject
  },
  checkFlexMsgJson(json) {
    var err = null
    if (json.footer && json.footer.contents && Array.isArray(json.footer.contents)) {
      json.footer.contents.forEach((content) => {
        if (content.type && content.type === 'spacer') {
          err = 'Spacer is no longer supported'
        }
      })
    }
    return err
  },
  // Example: 1186c182-b9c9-11ea-985b-8e3943a729dd
  generateNewId(time, shift) {
    let id =
      Math.random().toString(36).slice(2) +
      '-' +
      Math.random().toString(36).substr(2, 4) +
      '-' +
      Math.random().toString(36).substr(2, 4) +
      '-' +
      Math.random().toString(36).substr(2, 4) +
      '-' +
      Math.random().toString(36).substr(2, 4) +
      md5(time).substr(2, 8) +
      shift

    return id
  },

  toDateString: function (datetime) {
    const date = new Date(datetime)
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
  },

  getAccountType: function (aesInstance, token) {
    const decrypt = aesInstance.decrypt(token).split(',')
    return decrypt[3]
  },

  getAdminId: function (aesInstance, token) {
    const decrypt = aesInstance.decrypt(token).split(',')
    return decrypt[0]
  },
  getAccountId: function (aesInstance, token) {
    const decrypt = aesInstance.decrypt(token).split(',')
    return decrypt[1]
  },
  uploadImage: async function (adminId, accountId, jwtToken, file, resized = false) {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = (ev) => {
        let img = new Image()
        img.onload = () => {
          const imageWidth = img.width
          const imageHeight = img.height
          const imageSrc = img.src

          let formData = {
            adminId: adminId,
            accountId: accountId,
            img: ev.target.result,
            category: 'ImageMap',
            resized: resized,
          }

          axios({
            method: 'POST',
            url: process.env.VUE_APP_API_ENDPOINT + 'imgMapfile',
            data: formData,
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          })
            .then((res) => {
              // Resolve with the image URL, width, and height
              resolve({
                url: res.data.Result.link,
                width: imageWidth,
                height: imageHeight,
                src: imageSrc,
              })
            })
            .catch((err) => {
              console.error(err)
              alert(this.$t('__Imagechangeerror'))
              reject(err)
            })
        }

        // Set the image source to trigger the `onload` event
        img.src = ev.target.result
      }
    })
  },
}
