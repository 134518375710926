<template>
  <!-- Create New Rich Menu Modal Start -->
  <div
    class="modal fade bd-example-modal"
    id="create-edit-rich-menu-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold" v-if="!editMode">
            {{ $t('__AddRichMenu') }}
          </p>
          <p class="modal-title text-bold" v-if="editMode">
            {{ $t('__EditRichMenu') }}
          </p>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid" style="height: 60vh; overflow: auto">
            <div><span class="text-danger">*</span> {{ $t('__requiredItem') }}</div>
            <label class="pt-3 mb-2"
              ><span class="text-danger">*</span>{{ $t('__RichMenuname') }}</label
            >
            <div class="row">
              <input
                type="text"
                class="form-control px-4 mx-3"
                :placeholder="$t('__RichMenuname')"
                v-model="formInput.name"
              />
            </div>
            <div v-if="getAccountType() === 'line'">
              <label class="pt-3 mb-2"
                ><span class="text-danger">*</span> {{ $t('__RichMenuText') }}</label
              >
              <div class="row">
                <input
                  type="text"
                  class="form-control px-4 mx-3"
                  :placeholder="$t('__RichMenuText')"
                  v-model="formInput.chatBarText"
                />
              </div>
            </div>
            <label class="pt-3 mb-2"
              ><span class="text-danger">*</span> {{ $t('__background') }}</label
            >
            <div
              v-bind:class="{
                'layout-image-container-1': isImageClass1,
                'layout-image-container-2': isImageClass2,
              }"
              @click="$refs.imageInput.click()"
            >
              <input
                ref="imageInput"
                type="file"
                accept="image/*"
                @change="onImageChanged($event)"
                style="display: none"
              />
              <img :src="formInput.imageB64" v-if="formInput.imageB64 != ''" style="width: 100%" />
              <span class="layout-image-hint" v-if="formInput.imageB64 == '' && !editMode">{{
                $t('__selectImage')
              }}</span>
            </div>
            <p class="mt-1" style="font-size: 11px">{{ imageSizeHint }}</p>

            <label class="pt-3 mb-2"
              ><span class="text-danger">*</span> {{ $t('__richMsgLayout') }}</label
            >
            <div class="container">
              <div class="row">
                <div class="container col-4" @click="onLayoutChanged('A')">
                  <div
                    class="row layout-container"
                    v-bind:class="{
                      'layout-selected': formInput.layout === 'A',
                    }"
                  >
                    <div
                      class="col-4 layout-unit"
                      v-bind:class="{
                        'layout-selected': formInput.layout === 'A',
                      }"
                      v-for="n in ['A', 'B', 'C', 'D', 'E', 'F']"
                      :key="n"
                    >
                      <p style="text-align: center; margin-top: 40%">{{ n }}</p>
                    </div>
                  </div>
                </div>
                <div class="container col-4" @click="onLayoutChanged('B')">
                  <div
                    class="row layout-container"
                    v-bind:class="{
                      'layout-selected': formInput.layout === 'B',
                    }"
                  >
                    <div
                      class="col-12 layout-unit"
                      v-bind:class="{
                        'layout-selected': formInput.layout === 'B',
                      }"
                    >
                      <p style="text-align: center; margin-top: 10%">
                        {{ 'A' }}
                      </p>
                    </div>
                    <div
                      class="col-4 layout-unit"
                      v-bind:class="{
                        'layout-selected': formInput.layout === 'B',
                      }"
                      v-for="n in ['B', 'C', 'D']"
                      :key="n"
                    >
                      <p style="text-align: center; margin-top: 40%">{{ n }}</p>
                    </div>
                  </div>
                </div>
                <div class="container col-4" @click="onLayoutChanged('C')">
                  <div
                    class="row layout-container"
                    v-bind:class="{
                      'layout-selected': formInput.layout === 'C',
                    }"
                  >
                    <div
                      class="col-4 layout-unit"
                      v-bind:class="{
                        'layout-selected': formInput.layout === 'C',
                      }"
                      v-for="n in ['A', 'B', 'C']"
                      :key="n"
                    >
                      <p style="text-align: center; margin-top: 40%">{{ n }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-4 mt-2" @click="onLayoutChanged('D')">
                  <div
                    class="row layout-container"
                    v-bind:class="{
                      'layout-selected': formInput.layout === 'D',
                    }"
                  >
                    <div
                      class="col-6 layout-unit"
                      v-bind:class="{
                        'layout-selected': formInput.layout === 'D',
                      }"
                      v-for="n in ['A', 'B', 'C', 'D']"
                      :key="n"
                    >
                      <p style="text-align: center; margin-top: 30%">{{ n }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="getAccountType() === 'line'">
              <input type="checkbox" id="useTabCheck" v-model="useTabCheck" />
              <label for="useTabCheck" class="ml-2">
                <span class="switch-txt" turnOn="On" turnOff="Off">{{
                  $t('__richMsgAliasLayout')
                }}</span>
              </label>
            </div>
            <div v-if="useTabCheck == true && getAccountType() === 'line'">
              <div
                class="action-container mb-2"
                v-for="(item, index) in formInput.tab_menu"
                :key="index"
              >
                <div>
                  <label class="ml-2 mt-2">{{ 'Tab' + (index + 1) }}</label>
                  <div class="float-right mr-2 mt-2">
                    <a
                      href="javascript:void(0)"
                      class="text-danger float-right"
                      @click="deleteTab(index)"
                    >
                      <i class="fas fa-trash text-danger"></i>
                    </a>
                  </div>
                  <div class="px-2 mt-2 mb-2">
                    <select
                      name=""
                      id=""
                      class="col-5 ml-4"
                      v-model="formInput.tab_menu[index].name"
                    >
                      <option value disabled selected>
                        {{ $t('__switchMenuName') }}
                      </option>
                      <option
                        v-for="(item, index) in $attrs.menuList"
                        :key="index"
                        :value="item.name"
                        class="h-auto"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div style="display: flex" v-if="formInput.tab_menu[index].name">
                    <div class="">
                      <div class="px-2 mt-2 mb-2 d-flex align-items-center">
                        <label class="mb-0">{{ $t('__xAxis') }}</label>
                        <input
                          type="text"
                          class="form-control ml-2"
                          v-model="formInput.tab_menu[index].xAxis"
                        />
                      </div>
                      <div class="px-2 mt-2 mb-2 d-flex align-items-center">
                        <label class="mb-0">{{ $t('__yAxis') }}</label>
                        <input
                          type="text"
                          class="form-control ml-2"
                          v-model="formInput.tab_menu[index].yAxis"
                        />
                      </div>
                    </div>
                    <div class="">
                      <div class="px-2 mt-2 mb-2 d-flex align-items-center">
                        <label class="mb-0">{{ $t('__tabWidth') }}</label>
                        <input
                          type="text"
                          class="form-control ml-2"
                          v-model="formInput.tab_menu[index].width"
                        />
                      </div>
                      <div class="px-2 mt-2 mb-2 d-flex align-items-center">
                        <label class="mb-0">{{ $t('__tabHeight') }}</label>
                        <input
                          type="text"
                          class="form-control ml-2"
                          v-model="formInput.tab_menu[index].height"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row pt-4 add-btn-input cursor-pointer"
              @click="addNewTab()"
              v-if="getAccountType() === 'line'"
            >
              <a href="javascript:void(0)" class="mx-auto my-3">
                <i class="fas fa-plus"></i>
              </a>
            </div>
            <label class="pt-3 mb-2"><span class="text-danger">*</span> {{ $t('__action') }}</label>
            <div
              class="action-container mb-1"
              v-for="(action, key) in formInput.areaOptions"
              :key="key"
            >
              <label class="ml-3 mt-2">{{ key }}</label>
              <div class="row px-4 mt-2">
                <md-radio type="radio" class="ml-3" value="text" v-model="action.type" />

                <input
                  type="text"
                  class="form-control col-10 ml-0"
                  :placeholder="$t('__Textcontent')"
                  :id="'text-' + key"
                  @focus="onActionTypeChanged($event, key)"
                  v-model="action.text"
                />
              </div>
              <div class="row px-4 mt-2">
                <md-radio type="radio" class="ml-3" value="uri" v-model="action.type" />

                <input
                  type="text"
                  class="form-control col-10 ml-0"
                  :placeholder="$t('__urllinkoptional')"
                  :id="'uri-' + key"
                  @focus="onActionTypeChanged($event, key)"
                  v-model="action.url"
                />
              </div>
            </div>

            <div class="pt-4" v-if="getAccountType() === 'line'">
              <div class="form-check">
                <input
                  id="useDateTime"
                  class="form-check-input"
                  type="checkbox"
                  v-model="formInput.use_datetime_trigger"
                />
                <label class="form-check-label" for="useDateTime">
                  {{ $t('__DateTimeTriggerEnable') }}
                </label>
              </div>
              <div class="form-row px-4">
                <div class="form-group col-6">
                  <label>{{ $t('__DateTimeTriggerStart') }}</label>
                  <datetime
                    v-model="formInput.start_time"
                    type="datetime"
                    :minute-step="5"
                    :min-datetime="currentTime"
                    input-class="form-control col-12"
                    input-style="width: 16rem;"
                  ></datetime>
                </div>
                <div class="form-group col-6">
                  <label>{{ $t('__DateTimeTriggerEnd') }}</label>
                  <datetime
                    v-model="formInput.end_time"
                    type="datetime"
                    :minute-step="5"
                    :min-datetime="currentTime"
                    input-class="form-control col-12"
                    input-style="width: 16rem;"
                  ></datetime>
                </div>
              </div>
            </div>
            <div class="mt-2" v-if="getAccountType() === 'line'">
              <input type="checkbox" id="switch" v-model="formInput.open_menu" />
              <label for="switch" class="ml-2">
                <span class="switch-txt" turnOn="On" turnOff="Off">{{ $t('__open_menu') }}</span>
              </label>
            </div>
            <label class="pt-4 mb-2" v-if="getAccountType() === 'line'"
              ><span class="text-danger">*</span> {{ $t('__segment') }}</label
            >
            <div class="container" v-if="getAccountType() === 'line'">
              <div class="row">
                <select class="form-control col-5 ml-2" v-model="formInput.segmentId">
                  <option value disabled selected>
                    {{ $t('__segment') }}
                  </option>
                  <option value="richAllUser">
                    {{ $t('__richAllUser') }}
                  </option>
                  <option :value="segment.id" v-for="(segment, index) in segmentList" :key="index">
                    {{ segment.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            style="width: 100%"
            @click="submitRichMenu()"
            v-if="!editMode"
          >
            {{ $t('__CreatenewRichMenu') }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            style="width: 100%"
            @click="submitRichMenu()"
            v-if="editMode"
          >
            {{ $t('__saveRichMenu') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Create New Rich Menu Modal End -->
</template>

<script>
import { apiFunction } from '../../../Axios/functions'
import { Datetime } from 'vue-datetime'
import { Utility } from '../../../utility'
import { RichMenuActions } from '../../Story/Models/RichMenu'
export default {
  name: 'create-edit-rich-menu-modal',
  components: { Datetime },
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    segmentList: Array,
    formInput: Object,
  },
  data() {
    return {
      editMode: false,
      currentTime: '',
      actionNames: ['A', 'B', 'C', 'D', 'E', 'F'],
      imageSizeHint: '2500 × 1686,  1200 × 810, 800 × 540',
      imageExpectSizes: {
        A: [
          [2500, 1686],
          [1200, 810],
          [800, 540],
        ],
        B: [
          [2500, 1686],
          [1200, 810],
          [800, 540],
        ],
        C: [
          [2500, 843],
          [1200, 405],
          [800, 270],
        ],
        D: [
          [2500, 1686],
          [1200, 810],
          [800, 540],
        ],
      },
      isImageClass1: true,
      isImageClass2: false,
      useTabCheck: false,
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    this.$watch(
      'formInput',
      () => {
        this.loadData()
      },
      { immediate: true },
    )
  },
  computed: {
    // Map areaOptions object to richMenu actions.
    templateActions() {
      // Transform areaOptions object to array.
      const areaOptionsArray = Object.values(this.formInput.areaOptions)
      const result = RichMenuActions.getActions(
        this.formInput.layout,
        areaOptionsArray,
        this.formInput.imgWidth,
        this.formInput.imgHeight,
      )
      return result
    },
  },
  watch: {
    'formInput.areaOptions': {
      immediate: true,
      deep: true,
      handler() {
        this.formInput.template.actions = this.templateActions
        this.formInput.template.layout = this.formInput.layout
      },
    },
    'formInput.imageB64': {
      immediate: true,
      deep: true,
      handler() {
        this.formInput.template.actions = this.templateActions
        this.formInput.template.layout = this.formInput.layout
      },
    },
  },

  methods: {
    getAccountType() {
      return Utility.getAccountType(this.$aes, localStorage._token)
    },
    loadData() {
      console.log(this.formInput)
      this.currentTime = this.$dateTime.getCurrentTime()
      if (this.formInput.tab_menu && this.formInput.tab_menu.length > 0) {
        this.useTabCheck = true
      }
      if (this.formInput.id && this.formInput.id !== '') {
        let formData = {
          adminId: this.adminId,
          accountId: this.accountId,
          richmenuId: this.formInput.id,
          is_webbot: this.getAccountType() === 'webbot',
        }
        apiFunction.getRichMenu(formData, this.jwtToken).then((data) => {
          if (data.error) {
            this.$fire({
              title: this.$t('__authExpired'),
              text: this.$t('__pleaseLogin'),
              type: 'error',
            })
            this.$router.push('/login')
          } else {
            this.formInput.imageB64 = data.data.Result.data.image_b64
          }
        })
      }
      this.updateImageSizeHint(this.imageExpectSizes[this.formInput.layout])
      this.updateImageClass(this.formInput.layout)
    },
    addNewTab() {
      if (this.useTabCheck === false) {
        return
      }
      let newTab = {
        name: '',
        width: '',
        height: '',
      }
      this.formInput.tab_menu.push(newTab)
    },
    deleteTab(index) {
      this.formInput.tab_menu.splice(index, 1)
    },
    // Trigger this function once user click a specific input.
    onActionTypeChanged(event, key) {
      const accountType = this.getAccountType()
      if (this.editMode) {
        return
      }
      if (accountType === 'line') {
        if (event.target.id.indexOf('uri') != -1) {
          this.formInput.areaOptions[key].type = 'uri'
        } else if (event.target.id.indexOf('text') != -1) {
          this.formInput.areaOptions[key].type = 'text'
        }
      }
    },
    // Add new rich menu
    submitRichMenu() {
      // Check all fields are fill in.
      if (!this.formInput.tab_menu) {
        this.formInput.tab_menu = []
      }
      console.log(this.formInput)
      if (
        this.formInput.name !== '' &&
        (this.getAccountType() === 'webbot' || this.formInput.segmentId !== '') &&
        this.formInput.layout !== '' &&
        this.formInput.imageB64 !== '' &&
        this.formInput.imgWidth !== '' &&
        this.formInput.imgHeight !== ''
      ) {
        //檢查圖片大小
        const byteSize = Math.ceil(this.formInput.imageB64.length * 0.75)
        if (byteSize > 1048576) {
          return this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__ImageSizeToobig'),
            type: 'error',
          })
        }
        //檢查是否tab欄位有漏寫
        for (let i = 0; i < this.formInput.tab_menu.length; i++) {
          let e = this.formInput.tab_menu[i]
          if (
            this.useTabCheck === true &&
            (e.name == '' || e.xAxis == '' || e.yAxis == '' || e.width == '' || e.height == '')
          ) {
            return this.$fire({
              title: this.$t('__Addfailed'),
              text: this.$t('__TabFieldseIncorrect'),
              type: 'error',
            })
          }
        }
        //若不使用tab則清空陣列
        if (this.useTabCheck === false) {
          this.formInput.tab_menu.length = 0
        }
        // Check image size follow the official Suggest.
        var sizeOk = false
        let targetSizes = this.imageExpectSizes[this.formInput.layout]
        targetSizes.forEach((size) => {
          if (this.formInput.imgWidth === size[0] && this.formInput.imgHeight === size[1]) {
            sizeOk = true
          }
        })

        if (sizeOk) {
          // Pass all validation, we can submit form.
          $('#create-edit-rich-menu-modal').modal('hide')
          this.$emit('submitRichMenu', this.formInput)
        } else {
          return this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__ImageSizeIncorrect'),
            type: 'error',
          })
        }
      } else {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Pleasefillinallfields'),
          type: 'error',
        })
      }
    },
    //Trigger this function once user selected a new image.
    async onImageChanged(e) {
      if (e && e.target.files.length > 0) {
        const file = e.target.files[0]

        const accountType = this.getAccountType()
        // if accountType is line, we get image size and base64.
        if (accountType === 'line') {
          const reader = new FileReader()
          reader.onload = (e) => {
            let img = new Image()
            img.setAttribute('crossOrigin', 'anonymous')
            img.onload = () => {
              this.formInput.imgWidth = img.width
              this.formInput.imgHeight = img.height
              this.formInput.imageB64 = img.src
            }
            img.src = e.target.result
          }
          reader.readAsDataURL(file)
        }
        // If accountType is webbot, we upload image to server and get the url.
        if (accountType === 'webbot') {
          const { url, width, height, src } = await Utility.uploadImage(
            this.adminId,
            this.accountId,
            this.jwtToken,
            file,
          )
          this.formInput.template.original_content_url = url
          this.formInput.template.base_size = {}
          this.formInput.template.base_size.width = width
          this.formInput.template.base_size.height = height
          this.formInput.imgWidth = width
          this.formInput.imgHeight = height
          this.formInput.imageB64 = src
        }
      }
    },

    // Update layout related data once user click layout picture.
    onLayoutChanged(layout) {
      this.formInput.layout = layout
      switch (layout) {
        case 'A':
          this.resetLayoutActions(6)
          break
        case 'B':
          this.resetLayoutActions(4)
          break
        case 'C':
          this.resetLayoutActions(3)
          break
        case 'D':
          this.resetLayoutActions(4)
          break
      }
      this.updateImageSizeHint(this.imageExpectSizes[layout])
      this.updateImageClass(layout)
    },
    // Reset action data.
    resetLayoutActions(numbers) {
      var tempAreaOptions = {}
      for (var i = 0; i < numbers; i++) {
        tempAreaOptions[this.actionNames[i]] = {
          type: 'text',
          text: '',
          url: '',
        }
      }
      this.formInput.areaOptions = tempAreaOptions
    },
    updateImageSizeHint(targetSizes) {
      this.imageSizeHint = ''
      targetSizes.forEach((size) => {
        if (this.imageSizeHint === '') {
          this.imageSizeHint = this.imageSizeHint + size[0] + ' x ' + size[1]
        } else {
          this.imageSizeHint = this.imageSizeHint + ', ' + size[0] + ' x ' + size[1]
        }
      })
      this.imageSizeHint = this.imageSizeHint + ' pixels'
    },
    updateImageClass(type) {
      if (type === 'A' || type === 'B' || type === 'D') {
        this.isImageClass1 = true
        this.isImageClass2 = false
      } else if (type === 'C') {
        this.isImageClass1 = false
        this.isImageClass2 = true
      }
    },
  },
}
</script>
