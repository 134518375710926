var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDisplayInput,
          expression: "closeDisplayInput"
        }
      ],
      staticClass: "web-bot-color-edit"
    },
    [
      _c("div", { staticClass: "curr-color" }, [
        _c("span", {
          style: { backgroundColor: _vm.hexInput },
          on: { click: _vm.toggleDisplayInput }
        }),
        _vm.displayInput
          ? _c("div", { staticClass: "color-picker" }, [
              _c(
                "div",
                {
                  ref: "sv",
                  staticClass: "sv-panel",
                  style: { backgroundColor: _vm.hueBackground },
                  on: {
                    mousedown: _vm.startSVPick,
                    mousemove: _vm.moveSVPick,
                    mouseup: _vm.stopPick,
                    mouseleave: _vm.stopPick
                  }
                },
                [
                  _c("div", {
                    staticClass: "sv-cursor",
                    style: _vm.svCursorStyle
                  })
                ]
              ),
              _c(
                "div",
                {
                  ref: "hue",
                  staticClass: "hue-slider",
                  on: {
                    mousedown: _vm.startHuePick,
                    mousemove: _vm.moveHuePick,
                    mouseup: _vm.stopPick,
                    mouseleave: _vm.stopPick
                  }
                },
                [
                  _c("div", {
                    staticClass: "hue-cursor",
                    style: _vm.hueCursorStyle
                  })
                ]
              ),
              _c("div", { staticClass: "color-picker__ctrl" }, [
                _c("div", {
                  staticClass: "preview",
                  style: { backgroundColor: _vm.hexInput }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hexInput,
                      expression: "hexInput"
                    }
                  ],
                  staticClass: "hex-input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.hexInput },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.hexInput = $event.target.value
                      },
                      _vm.onHexChange
                    ]
                  }
                })
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }