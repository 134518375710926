<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>{{ $t('__utilsName') }}</th>
      <th>{{ $t('__utilsType') }}</th>
      <th>{{ $t('__utilsStatus') }}</th>
      <th>{{ $t('__workTime') }}</th>
      <th></th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td>{{ item.name }}</td>
          <td>
            <span v-if="item.type === 'auto_reply_comments'">{{ $t('__autoReplyComments') }}</span>
            <span v-if="item.type === 'short_link'">{{ $t('__shortLink') }}</span>
            <span v-if="item.type === 'auto_reply_live_mention'">{{
              $t('__autoReplyLiveMention')
            }}</span>
            <span v-if="item.type === 'auto_reply_media'">{{ $t('__autoReplyMedia') }}</span>
          </td>
          <td>
            <div class="togglediv">
              <input
                type="checkbox"
                :id="'switch_' + index"
                v-model="item.enable"
                @change="handleEnableChange(item, $event)"
              />
              <label class="toggle" :for="'switch_' + index"></label>
            </div>
          </td>
          <td>{{ toDateString(item.range.start) }} ~ {{ toDateString(item.range.end) }}</td>
          <td>
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#util"
              class="px-1"
              @click="editUtil(item)"
            >
              <font-awesome-icon icon="edit" />
            </a>
            <a href="javascript:void(0)" @click="settingUtil(item)">
              <i class="fas fa-comment-dots mx-1"></i>
            </a>
            <a href="javascript:void(0)" @click="copyUtil(item)">
              <i class="fas fa-copy mx-1"></i>
            </a>
            <a href="javascript:void(0)" @click="deleteUtil(item.id)">
              <i class="fas fa-trash text-danger mx-1"></i>
            </a>
          </td>
        </slot>
      </tr>
    </tbody>
    <create-meta-util-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :utiloptionData="utilsType"
      :accountId="accountId"
      v-bind:modalData="modalData"
      :editId="editId"
      :editKey="isEdit"
      :utilsList="data"
      :objectKey="createMetaUtilModalKey"
      :key="createMetaUtilModalKey"
      v-if="createMetaUtilModalKey"
    ></create-meta-util-modal>
    <auto-reply-comments-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      v-bind:modalData="modalData"
      :editId="editId"
      :allScenario="allScenario"
      :key="autoReplyCommentsModalKey"
      :igAccountId="igAccountId"
      :appToken="appToken"
      :igPost="igPost"
      v-if="autoReplyCommentsModalKey"
    ></auto-reply-comments-modal>
    <!-- add new story icon-->
    <div class="btn-follow">
      <a href="javascript:void(0)" v-on:click="createUtil()" style="color: #fff">
        <i class="fas fa-plus"></i>
      </a>
    </div>
    <!-- add new story icon-->
  </table>
</template>
<script>
import { CreateMetaUtilModal, AutoReplyCommentsModal } from '../Modals/index'
import { Utility } from '../../../utility'
import { apiFunction } from '../Axios/functions'
export default {
  name: 'meta-utils-table',
  components: { CreateMetaUtilModal, AutoReplyCommentsModal },
  props: {
    adminId: String,
    accountId: String,
    allUtils: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: 'striped',
    },
    currentNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      appToken: '',
      jwtToken: '',
      igAccountId: '',
      editId: '',
      createMetaUtilModalKey: 0,
      isEdit: false,
      autoReplyCommentsModalKey: 0,
      createdModal: {},
      allScenario: [],
      utilsType: [
        { name: '', type: 'auto_reply_comments' },
        { name: '', type: 'short_link' },
        { name: '', type: 'auto_reply_live_mention' },
        { name: '', type: 'auto_reply_media' },
      ],
      modalData: {
        modalBtn: this.$t('__setup'),
        modalSuccessMsg: this.$t('__Anewcategoryhasbeensuccessfullycreated'),
        id: '',
        name: '',
        type: '',
        enable: true,
        range: {
          start: '',
          end: '',
        },
        defaultMessage: {
          useDefaultMessage: false,
          publicMessage: '',
          secretMessage: '',
        },
        conditionMessage: {
          useDefaultMessage: false,
          publicMessage: '',
          secretMessage: '',
          conditionFields: [],
        },
        mediaId: '',
        scenarioId: '',
      },
      igPost: [],
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type}`
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    // Prepare page content
    async loadData() {
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken

        // Get all scenario
        const formData = { adminId: this.adminId, accountId: this.accountId }
        await apiFunction
          .getScenario(formData, this.jwtToken)
          .then((data) => {
            // this.setLoadingMap("allScenario");
            if (data.error) {
              this.$fire({
                title: this.$t('__authExpired'),
                text: this.$t('__pleaseLogin'),
                type: 'error',
              }).then(() => {
                this.$Amplify.Auth.signOut()
                localStorage.clear()
                this.$router.push('/login')
              })
            } else {
              let storyArr = []
              data.data.Result.data.forEach((story) => {
                storyArr.push(story)
              })
              storyArr.sort((s1, s2) => {
                return s1.scenario.scenarioName.localeCompare(s2.scenario.scenarioName)
              })
              this.allScenario = storyArr
            }
          })
          .then(async (e) => {
            let formData = {
              adminId: this.adminId,
            }
            // Get this account
            await apiFunction.getAccount(formData, this.jwtToken).then((data) => {
              if (data.error) {
                this.$fire({
                  title: this.$t('__authExpired'),
                  text: this.$t('__pleaseLogin'),
                  type: 'error',
                }).then(() => {
                  this.$Amplify.Auth.signOut()
                  localStorage.clear()
                  this.$router.push('/login')
                })
              } else {
                let accounts = data.data.Result.accounts
                accounts.forEach((account) => {
                  if (account.accountId == this.accountId) {
                    this.appToken = account.accountToken
                  }
                })
              }
            })
          })
          // Get this Instagram bussiness account
          .then(async (e) => {
            return await apiFunction.getIGAccount(this.appToken).then((data) => {
              if (data.status === 200) {
                return data.data.instagram_business_account.id
              }
            })
          })
          // Use the Instagram bussiness account to get posts id
          .then(async (id) => {
            this.igAccountId = id
            const postData = await apiFunction.getAllIGPosts(id, this.appToken)
            const promises = postData.map((item) => apiFunction.getIGPost(this.appToken, item.id))
            const postDataResult = await Promise.all(promises)
            this.igPost = postDataResult.filter((data) => data.status === 200)
          })
          .catch((error) => {
            console.log(error)
            return this.$fire({
              title: this.$t('__Addfailed'),
              text: this.$t('__oopsSomethingwentwrong'),
              type: 'error',
            })
          })
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }
    },
    createUtil() {
      this.forceRerenderCreateMetaUtilModal()
      this.editId = '0001'
      this.modalData.name = this.getNewUtilName()
      this.modalData.type = ''
      this.modalData.range = {
        start: '',
        end: '',
      }
      this.modalData.enable = true
      this.modalData.modalBtn = this.$t('__setup')
      this.modalData.modalSuccessMsg = this.$t('__Anewuitlhasbeensuccessfullycreated')
    },
    editUtil(item) {
      this.forceRerenderCreateMetaUtilModal()
      this.editId = item.id
      this.modalData.name = item.name
      this.modalData.type = item.type
      this.modalData.range = item.range
      this.modalData.scenarioId = item.scenarioid
      this.modalData.defaultMessage = item.default
      this.modalData.multiCondition = item.multi_condition
      this.igPost.forEach((e) => {
        if (e.data.id === item.mediaid) {
          this.modalData.mediaId = e.data
        }
      })
      this.modalData.modalBtn = this.$t('__edit')
      this.modalData.modalSuccessMsg = this.$t('__Editsuccessfullyaccessed')
    },
    settingUtil(item) {
      this.forceRerenderAutoReplyCommentsModal()
      this.editId = item.id
      this.modalData.name = item.name
      this.modalData.type = item.type
      this.modalData.range = item.range
      this.modalData.scenarioId = item.scenarioid

      this.modalData.defaultMessage = item.default
      this.modalData.multiCondition = item.multi_condition
      if (item.mediaid !== null) {
        this.igPost.forEach((e) => {
          if (e.data.id === item.mediaid) {
            this.modalData.mediaId = e.data
          }
        })
      } else {
        this.modalData.mediaId = ''
      }
      this.modalData.modalBtn = this.$t('__edit')
      this.modalData.modalSuccessMsg = this.$t('__Editsuccessfullyaccessed')
    },
    copyUtil(item) {
      this.forceRerenderAutoReplyCommentsModal()
      this.editId = '0001'
      this.modalData.name = item.name
      this.modalData.type = item.type
      this.modalData.range = item.range
      this.modalData.scenarioId = item.scenarioid

      this.modalData.defaultMessage = item.default
      this.modalData.multiCondition = item.multi_condition
      this.igPost.forEach((e) => {
        if (e.data.id === item.mediaid) {
          this.modalData.mediaId = e.data
        }
      })
      this.modalData.modalBtn = this.$t('__Copycurrenttarget')
      this.modalData.modalSuccessMsg = this.$t('__Anewuitlhasbeensuccessfullycreated')
    },
    deleteUtil(utilId) {
      this.$fire({
        title: this.$t('__Areyousureyouwanttodeleteit'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      })
        .then(async () => {
          const formData = { adminId: this.adminId, accountId: this.accountId, utilId: utilId }
          await apiFunction.deleteUtil(formData, this.jwtToken).then((data) => {
            if (data.status === 200) {
              this.$fire({
                title: this.$t('__successfullydeleted'),
                text: this.$t('__Yourdatahasbeensuccessfullydeleted'),
                type: 'success',
                timer: 1500,
              }).then((e) => {
                this.$router.go()
              })
            }
          })
        })
        .catch((error) => {
          console.log(error)
          return this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        })
    },
    async handleEnableChange(item, event) {
      const isChecked = event.target.checked
      let utilObject = {
        adminId: this.adminId,
        accountId: this.accountId,
        utilId: item.id,
        name: item.name,
        type: item.type,
        enable: isChecked,
        mediaId: item.mediaid,
        multi_condition: item.multi_condition,
        default: item.default,
        range: item.range,
        scenarioId: item.scenarioid,
      }
      await apiFunction.saveUtil(utilObject, this.jwtToken).then((data) => {
        if (data.status !== 200) {
          $('#auto-reply-comments-modal').modal('hide')
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
    // Re render modal component
    forceRerenderCreateMetaUtilModal() {
      this.createMetaUtilModalKey += 1
    },
    // Re render modal component
    forceRerenderAutoReplyCommentsModal() {
      this.autoReplyCommentsModalKey = Date.now()
    },
    toDateString(datetime) {
      return Utility.toDateString(datetime)
    },
    getNewUtilName() {
      let totalUtil = this.data.length
      let defaultName = (Number(totalUtil) + 1).toString()
      return 'UT' + defaultName.padStart(5, '0')
    },
  },
}
</script>
<style>
.togglediv {
  display: flex;
  justify-content: center;
}
.togglediv label::after {
  margin: 0;
  padding: 0;
  transition: all 0.3s;
}
.togglediv [id^='switch_'] {
  display: none;
}
.togglediv label {
  width: 3.5rem;
  height: 2rem;
  background: #e9e9eb;
  border-radius: 1rem;
  position: relative;
}
.togglediv label::after {
  position: absolute;
  left: 0.15rem;
  top: 0.15rem;
  content: '';
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  background: #fff;
  pointer-events: none;
}
.togglediv input:checked + label {
  background: #34c85a;
}
.togglediv input:checked + label::after {
  left: 1.65rem;
}
.togglediv input:checked ~ .light {
  background: #fff;
}
</style>
