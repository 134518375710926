import axios from 'axios'

export const apiFunction = {
  // Delete user data
  // Remove specified user data.
  // Impact table -
  // 1."chatbot_broadcast"
  // 2."chatbot_history"
  // 3."chatbot_userlist"
  // 4."chatbot_segment". For "chatbot_segment", only empty the "segmentusers" data.
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  deleteUserData: function (formData, jwtToken) {
    return axios({
      method: 'POST',
      url: process.env.VUE_APP_API_ENDPOINT + 'deleteUserData',
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        console.log(e.response)
        return { error: e.response ? e.response : 'null' }
      })
  },

  // Show current account in account page
  // ***required fields
  // **
  // "adminid": "string"
  // **
  // ***
  getAccount: function (formData, jwtToken) {
    return axios({
      method: 'POST',
      url: process.env.VUE_APP_API_ENDPOINT + 'getAccount',
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        console.log(e.response)
        return { error: e.response ? e.response : 'null' }
      })
  },
  /**
   * Update token, secret, appid for SNS
   * @param {*} formData
   */
  updateAccount: function (formData, jwtToken) {
    return axios({
      method: 'POST',
      url: process.env.VUE_APP_API_ENDPOINT + 'updateAccount',
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        console.log(e.response)
        return { error: e.response ? e.response : 'null' }
      })
  },
  // Show scenario in scenario page
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // "scenarioId": "string" ( if with scenarioId get only one specific scenario )
  // **
  // ***
  getScenario: function (formData, jwtToken) {
    return axios({
      method: 'POST',
      url: process.env.VUE_APP_API_ENDPOINT + 'getScenario',
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        console.log(e.response)
        return { error: e.response ? e.response : 'null' }
      })
  },
}
