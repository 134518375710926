var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade bd-example-modal",
      attrs: {
        id: "create-user-icon-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal modal-dialog-centered modal-dialog-scrollable",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("p", { staticClass: "modal-title text-bold" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.editMode
                        ? _vm.$t("__editUserIcon")
                        : _vm.$t("__AddUserIcon")
                    ) +
                    "\n        "
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×")
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "pt-3 mb-2" }, [
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  _vm._v(
                    " " + _vm._s(_vm.$t("__requiredItem")) + "\n          "
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "border" },
                      [
                        _c("vue-dropzone", {
                          ref: "userIconVueDropzone",
                          attrs: {
                            id: "myDropzone",
                            options: _vm.dropzoneOptions
                          },
                          on: { "vdropzone-file-added": _vm.imgHandling }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "label",
                  {
                    staticClass: "pt-3 mb-2",
                    attrs: { "data-translate": "_selectColor.edit" }
                  },
                  [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__IconName")) + "\n          ")
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.saveUserIconData.name,
                        expression: "saveUserIconData.name"
                      }
                    ],
                    staticClass: "form-control px-4 mx-3",
                    attrs: { type: "text", placeholder: _vm.$t("__IconName") },
                    domProps: { value: _vm.saveUserIconData.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.saveUserIconData,
                          "name",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c(
                  "label",
                  {
                    staticClass: "pt-3 mb-2",
                    attrs: { "data-translate": "_selectColor.edit" }
                  },
                  [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__ImageUrl")) + "\n          ")
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.saveUserIconData.url,
                        expression: "saveUserIconData.url"
                      }
                    ],
                    staticClass: "form-control px-4 mx-3",
                    attrs: { type: "text", placeholder: _vm.$t("__ImageUrl") },
                    domProps: { value: _vm.saveUserIconData.url },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.saveUserIconData,
                          "url",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c("div", { staticClass: "row" }, [
                _vm.editMode
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-danger ml-2",
                        attrs: { type: "button", id: "delete-user-icon-btn" },
                        on: {
                          click: function($event) {
                            return _vm.deleteUserIcon()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("__deleteUserIcon")) +
                            "\n          "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary ml-2",
                    attrs: { type: "button", id: "create-user-icon-btn" },
                    on: {
                      click: function($event) {
                        return _vm.createUserIcon()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.editMode
                            ? _vm.$t("__saveUserIcon")
                            : _vm.$t("__CreateNewUserIcon")
                        ) +
                        "\n          "
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }