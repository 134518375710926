import FormGroupInput from './Inputs/formGroupInput.vue'
import DropDown from './Dropdown.vue'
import PaperTable from './PaperTable.vue'
import Button from './Button'
import Card from './Cards/Card.vue'
import ChartCard from './Cards/ChartCard.vue'
import StatsCard from './Cards/StatsCard.vue'
import SidebarPlugin from './SidebarPlugin/index'
import Modal from './Modals/ModalTemplate.vue'

let components = {
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  PaperTable,
  DropDown,
  SidebarPlugin,
}

export default components

export {
  Modal,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  PaperTable,
  DropDown,
  Button,
  SidebarPlugin,
}
