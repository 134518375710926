<template>
  <!-- segment create modal start -->
  <div
    class="modal fade"
    id="segment"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold" id="segment-create-title">
            {{ formInput.segmentName }}
          </p>
          <span id="segment-create-edit" @click="editName()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control element-hide"
            id="segment-create-input"
            v-model="formInput.segmentName"
            @keypress="confirmChangeName($event)"
          />
          <input type="hidden" id="current-number" />
          <input type="hidden" id="segment-target" />
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Loading spinner -->
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-if="!isLoading" class="container-fluid">
            <div class="row mb-2">
              <span class="text-danger">*</span> {{ $t('__requiredItem') }}
              <label class="ml-auto"
                ><input class="ml-2" type="checkbox" v-model="formInput.defaultUsersSegment" />
                {{ $t('__DefaultUserSegment') }}</label
              >
            </div>
            <div class="row mb-2">
              <select class="form-control segment-rule" v-model="selectRule">
                <option value="users" data-translate="_customize.segment">
                  {{ $t('__Customgroup') }}
                </option>
                <option
                  value="cvParam"
                  data-translate="_segmentByCv.segment"
                  v-if="adminId == '0001'"
                >
                  {{ $t('__CvParam') }}
                </option>
                <option value="conditions" data-translate="_segmentByCondition.segment">
                  {{ $t('__MultiCondition') }}
                </option>
              </select>
            </div>
            <!-- Choose conditions -->
            <div id="segment-block" v-if="selectRule === 'conditions'">
              <!-- Title -->
              <div class="row">
                <label class="mt-4" data-translate="_chooseTag.segment"
                  ><span class="text-danger">*</span> {{ $t('__Selectcategoryconditions') }}</label
                >
              </div>
              <div
                class="mb-3 py-1"
                style="overflow-y: scroll; max-height: 28rem; min-height: 12rem"
              >
                <div
                  class="border border-dark container mb-3 py-2"
                  v-for="(item, index) in formInput.segmentConditions"
                  :key="index"
                >
                  <a
                    href="javascript:void(0)"
                    class="text-danger pull-right"
                    @click="deleteCondition(index)"
                  >
                    <i class="fas fa-trash text-danger"></i>
                  </a>
                  <div class="clearfix"></div>
                  <div v-for="(subItem, subIndex) in item.multi_condition" :key="subIndex">
                    <div class="form-row pt-2">
                      <!-- Condition Source -->
                      <div class="form-group col-2">
                        <label>{{ $t('__source') }}</label>
                        <select
                          class="form-control"
                          v-model="subItem.source"
                          @change="onCondSourceChanged($event, index, subIndex)"
                        >
                          <option value="tag">{{ $t('__label') }}</option>
                          <option value="uservariable">
                            {{ $t('__variable') }}
                          </option>
                          <option value="followtime">
                            {{ $t('__FollowAccountTime') }}
                          </option>
                          <option value="cvkey">
                            {{ $t('__CvParam') }}
                          </option>
                          <option value="serverpushtime">
                            {{ $t('__ServerPushTime') }}
                          </option>
                          <option value="utils">
                            {{ $t('__metaUtils') }}
                          </option>
                          <option value="cros">CROS</option>
                        </select>
                      </div>
                      <!-- Condition Type / tag or uservariable -->
                      <div class="form-group col-2">
                        <label>{{ $t('__Condition') }}</label>
                        <select
                          class="form-control"
                          :aria-placeholder="$t('__fontcolor')"
                          v-model="subItem.expression"
                        >
                          <option
                            value="exactly_match"
                            v-if="
                              (subItem.source == 'tag') |
                                (subItem.source == 'uservariable') |
                                (subItem.source == 'cros')
                            "
                            selected
                          >
                            {{ $t('__ExactlyMatch') }}
                          </option>
                          <option
                            value="fuzzy_match"
                            v-if="
                              (subItem.source == 'tag') |
                                (subItem.source == 'uservariable') |
                                (subItem.source == 'cros')
                            "
                          >
                            {{ $t('__FuzzyMatch') }}
                          </option>
                          <option
                            value="has_value"
                            v-if="
                              (subItem.source == 'tag') |
                                (subItem.source == 'uservariable') |
                                (subItem.source == 'cvkey')
                            "
                          >
                            {{ $t('__segmentcondition') }}
                          </option>
                          <option
                            value="no_value"
                            v-if="
                              (subItem.source == 'tag') |
                                (subItem.source == 'uservariable') |
                                (subItem.source == 'cvkey')
                            "
                          >
                            {{ $t('__segmentnocondition') }}
                          </option>
                          <option value="no_exist" v-if="subItem.source == 'cvkey'">
                            {{ $t('__segmentnoexist') }}
                          </option>
                          <option
                            value="num_range"
                            v-if="
                              (subItem.source == 'tag') |
                                (subItem.source == 'uservariable') |
                                (subItem.source == 'cros')
                            "
                          >
                            {{ $t('__NumberRange') }}
                          </option>
                          <option
                            value="num_gt"
                            v-if="
                              (subItem.source == 'tag') |
                                (subItem.source == 'uservariable') |
                                (subItem.source == 'cros')
                            "
                          >
                            {{ $t('__GreaterThanNumber') }}
                          </option>
                          <option
                            value="num_lt"
                            v-if="
                              (subItem.source == 'tag') |
                                (subItem.source == 'uservariable') |
                                (subItem.source == 'cros')
                            "
                          >
                            {{ $t('__LessThanNumber') }}
                          </option>
                          <option
                            value="date_range"
                            v-if="
                              (subItem.source != 'serverpushtime') & (subItem.source != 'utils')
                            "
                          >
                            {{ $t('__DataRange') }}
                          </option>
                          <option value="datetime_range" v-if="subItem.source == 'serverpushtime'">
                            {{ $t('__DatatimeRange') }}
                          </option>
                          <option value="short_link" v-if="subItem.source == 'utils'">
                            {{ $t('__shortLink') }}
                          </option>
                          <option value="auto_reply_comments" v-if="subItem.source == 'utils'">
                            {{ $t('__autoReplyComments') }}
                          </option>
                          <option value="auto_reply_live_mention" v-if="subItem.source == 'utils'">
                            {{ $t('__autoReplyLiveMention') }}
                          </option>
                          <option value="auto_reply_media" v-if="subItem.source == 'utils'">
                            {{ $t('__autoReplyMedia') }}
                          </option>
                        </select>
                      </div>
                      <!-- Condition Tag -->
                      <div class="form-group col-3" v-if="subItem.source == 'tag'">
                        <label>{{ $t('__label') }}</label>
                        <select
                          class="form-control"
                          v-model="subItem.tag"
                          @change="onCondTagChanged(index, subIndex)"
                        >
                          <option value disabled selected>
                            {{ $t('__TagSelectOptional') }}
                          </option>
                          <option v-for="(tag, index) in tagList" :value="tag" :key="100 + index">
                            {{ tag.name }}
                          </option>
                        </select>
                      </div>
                      <!-- Condition Tag Value -->
                      <div
                        class="form-group col-3"
                        v-if="subItem.source == 'tag' && subItem.expression == 'exactly_match'"
                      >
                        <label>{{ $t('__value') }}</label>
                        <select
                          class="form-control"
                          :aria-placeholder="$t('__fontcolor')"
                          v-model="subItem.value"
                        >
                          <option value disabled selected>
                            {{ $t('__TagValueSelectOptional') }}
                          </option>
                          <option
                            v-for="(value, index) in valuesOfTag(
                              subItem.tag.color,
                              subItem.tag.name,
                            )"
                            :value="value"
                            :key="100 + index"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      <!-- Condition User Variable -->
                      <div class="form-group col-3" v-if="subItem.source == 'uservariable'">
                        <label>{{ $t('__variable') }}</label>
                        <select class="form-control" v-model="subItem.uservariable.name">
                          <option value disabled selected>
                            {{ $t('__UserVariableSelectOptional') }}
                          </option>
                          <option
                            v-for="(value, index) in allVariables"
                            :value="value"
                            :key="'variable-' + index"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      <!-- Condition CROS -->
                      <div class="form-group col-3" v-if="subItem.source == 'cros'">
                        <label>類型</label>
                        <select class="form-control" v-model="subItem.cros.type">
                          <option value disabled selected>
                            {{ $t('__CrosTypeOptional') }}
                          </option>
                          <option value="cros_latest" selected>最近一次紀錄</option>
                          <option value="cros_history" selected>歷史紀錄</option>
                        </select>
                      </div>
                      <div
                        class="form-group col-4"
                        v-if="subItem.source == 'cros' && subItem.cros.type == 'cros_latest'"
                      >
                        <label>CROS</label>
                        <select class="form-control" v-model="subItem.cros.item">
                          <option value disabled selected>
                            {{ $t('__CrosOptional') }}
                          </option>
                          <option value="line_registered_date" selected>加入好友日</option>
                          <option value="birth" selected>出生年月日</option>
                          <option value="latest_order_id">最近一次購買商品ID</option>
                          <option value="create_date_latest">最近一次購買日期</option>
                          <option value="actual_shipping_date_latest">最近一次發貨日期</option>
                          <option value="sum">最近一次購買總金額</option>
                          <option value="count">購買次數</option>
                          <option value="teiki_flg">定期購買者</option>
                          <option value="promotion_code">最近一次促銷代碼</option>
                          <option value="product_code">最近一次商品代碼</option>
                          <option value="regular_purchase_flg">定期標誌</option>
                          <option value="current_delivery_number_of_time">定期次數</option>
                        </select>
                      </div>
                      <div
                        class="form-group col-4"
                        v-if="subItem.source == 'cros' && subItem.cros.type == 'cros_history'"
                      >
                        <label>CROS</label>
                        <select class="form-control" v-model="subItem.cros.item">
                          <option value disabled selected>
                            {{ $t('__CrosOptional') }}
                          </option>
                          <option value="order_id">訂單ID</option>
                          <option value="promotion_code">促銷代碼</option>
                          <option value="create_date">訂單創建日期</option>
                          <option value="actual_shipping_date">發貨日期</option>
                          <option value="stock_warehousing_date">退貨日期</option>
                          <option value="status">訂單狀態</option>
                          <option value="payment_total">訂單金額</option>
                          <option value="product_code">訂單明細:商品代碼</option>
                          <option value="price">訂單明細:商品代碼金額</option>
                          <option value="quantity">訂單明細:商品代碼數量</option>
                        </select>
                      </div>
                      <!-- Condition Value Input -->
                      <div
                        class="form-group col-3"
                        v-if="
                          (subItem.expression == 'exactly_match') &
                            (subItem.source == 'uservariable' || subItem.source == 'cros') ||
                          subItem.expression == 'fuzzy_match' ||
                          subItem.expression == 'num_gt' ||
                          subItem.expression == 'num_lt'
                        "
                      >
                        <label>{{ $t('__value') }}</label>
                        <input class="form-control" type="text" v-model="subItem.value" />
                      </div>
                      <!-- Condition CV Key Value -->
                      <div
                        class="form-group col-3"
                        v-if="subItem.source == 'cvkey' && subItem.expression !== 'no_exist'"
                      >
                        <label>{{ $t('__SelectCvParam') }}</label>
                        <select class="form-control" v-model="subItem.value">
                          <option value disabled selected>
                            {{ $t('__CvParam') }}
                          </option>
                          <option value="orderId" selected>orderId</option>
                          <option value="cvdate" selected>cvdate</option>
                          <option value="from_server_push" selected>from_server_push</option>
                        </select>
                      </div>
                      <div class="form-group col-3" v-if="subItem.source == 'utils'">
                        <label>{{ $t('__utilsId') }}</label>
                        <input class="form-control" type="text" v-model="subItem.value" />
                      </div>
                      <!-- Condition Date Range -->
                      <div class="form-group col-2" v-if="subItem.expression == 'date_range'">
                        <label>{{ $t('__timepickerStartTime') }}</label>
                        <datetime
                          v-model="subItem.range.start"
                          type="date"
                          input-class="form-control"
                        ></datetime>
                      </div>
                      <div class="form-group col-2" v-if="subItem.expression == 'date_range'">
                        <label>{{ $t('__timepickerEndTime') }}</label>
                        <datetime
                          v-model="subItem.range.end"
                          type="date"
                          input-class="form-control"
                        ></datetime>
                      </div>
                      <!-- Condition Datetime Range -->
                      <div class="form-group col-3" v-if="subItem.expression == 'datetime_range'">
                        <label>{{ $t('__timepickerStartTime') }}</label>
                        <datetime
                          v-model="subItem.range.start"
                          type="datetime"
                          input-class="form-control"
                        ></datetime>
                      </div>
                      <div class="form-group col-3" v-if="subItem.expression == 'datetime_range'">
                        <label>{{ $t('__timepickerEndTime') }}</label>
                        <datetime
                          v-model="subItem.range.end"
                          type="datetime"
                          input-class="form-control"
                        ></datetime>
                      </div>
                      <!-- Condition Number Range -->
                      <div class="form-group col-2" v-if="subItem.expression == 'num_range'">
                        <label>{{ $t('__Start') }}</label>
                        <input class="form-control" type="text" v-model="subItem.range.start" />
                      </div>
                      <div class="form-group col-2" v-if="subItem.expression == 'num_range'">
                        <label>{{ $t('__End') }}</label>
                        <input class="form-control" type="text" v-model="subItem.range.end" />
                      </div>
                    </div>
                    <!-- Condition Operations -->
                    <div class="d-flex justify-content-end mt-3">
                      <button
                        v-if="item.multi_condition.length > 1"
                        class="btn btn-danger mr-2"
                        @click="deleteSubCondition(index, subIndex)"
                      >
                        Remove
                      </button>
                      <button
                        v-if="subIndex == item.multi_condition.length - 1"
                        class="btn btn-info"
                        @click="addSubCondition(index)"
                      >
                        AND
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row pt-4">
                  <button class="btn btn-secondary mx-auto" @click="addNewCondition()">OR</button>
                </div>
                <p class="m-auto py-4" v-if="tagList.length == 0">
                  {{ $t('__Nolabelsavailable') }}
                </p>
              </div>
            </div>
            <!-- Choose users -->
            <div id="group-block" v-if="selectRule === 'users'">
              <!-- search -->
              <div class="row mt-4">
                <div class="mt-auto">
                  <label data-translate="_chooseUser.segment"
                    ><span class="text-danger">*</span> {{ $t('__Selectuser') }}</label
                  >
                  <label
                    ><input
                      class="ml-2"
                      type="checkbox"
                      v-model="allSelected"
                      @change="selectAllChanged($event)"
                    />
                    All</label
                  >
                </div>
                <div class="ml-auto py-2 pr-0">
                  <input
                    id="segment-search-user"
                    type="text"
                    class="search-box"
                    style="width: 500px"
                    name="q"
                    v-model="searchUser"
                    v-on:keyup.enter="searchUsers"
                  />
                  <label for="segment-search-user" v-on:click="searchUsers">
                    <span class="fas fa-search search-icon"></span>
                  </label>
                </div>
              </div>
              <div
                class="row border mb-3 py-2"
                style="overflow-y: scroll; max-height: 20rem; min-height: 12rem"
              >
                <div
                  class="input-group mb-2 col-4"
                  v-for="(user, index) in userList"
                  :key="index"
                  style="max-height: 3rem"
                >
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input
                        type="checkbox"
                        :value="user.userId"
                        :id="'user' + index"
                        v-model="formInput.checkedUserIds"
                      />
                    </div>
                  </div>

                  <div class="py-2 border rounded col" style="max-height: 3rem; max-width: 23rem">
                    <img :src="user.userPicture" width="30" height="30" />
                    <span class="px-2" style="max-width: 21rem">{{
                      hideNameLength(user.userName)
                    }}</span>
                  </div>
                </div>
                <p class="m-auto py-4" v-if="userList.length == 0">
                  {{ $t('__Nousersfound') }}
                </p>
              </div>
            </div>
            <!-- Choose CV Value -->
            <div id="group-block" v-if="selectRule === 'cvParam'">
              <div class="row">
                <label class="mt-4" data-translate="_segmentByCv.segment"
                  ><span class="text-danger">*</span> {{ $t('__SelectCvParam') }}</label
                >
              </div>
              <div class="row mt-2 py-2" id="list-button-area">
                <!-- CV Key selection -->
                <select class="col-3 form-control ml-2" v-model="formInput.cvParam.key">
                  <option value="orderId" selected>orderId</option>
                </select>
                <input
                  class="col-8 form-control ml-2"
                  type="text"
                  v-model="formInput.cvParam.value"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            style="width: 100%"
            data-translate="_create.segment"
            @click="submitSegment()"
          >
            {{ modalData.modalBtn }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- segment modal end -->
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { apiFunction } from '../Axios/functions'
import { Utility } from '../../../utility'

export default {
  components: { Datetime },
  name: 'create-segment-modal',
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    modalData: Object,
    editId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      searchUser: '',
      selectRule: 'users',
      allUserMap: {},
      allTags: [],
      allVariables: [],
      tagList: [],
      userList: [],
      allSelected: false,
      formInput: {
        segmentId: '',
        segmentName: '',
        defaultUsersSegment: false,
        checkedUserIds: [],
        segmentConditions: [],
        cvParam: {
          key: 'orderId',
          value: '',
        },
      },
    }
  },
  mounted() {
    this.$watch(
      'modalData',
      (modalData) => {
        this.loadData()
      },
      { immediate: true },
    )
  },
  created() {
    this.getTagAndUser()
    this.getAllVariables()
  },
  methods: {
    // Prepare page content
    loadData() {
      this.formInput.segmentName = this.modalData.segmentName
      this.formInput.segmentConditions = this.modalData.segmentConditions
      this.formInput.defaultUsersSegment = this.modalData.defaultUsersSegment
      this.formInput.checkedUserIds.splice(0)
      this.modalData.segmentUsers.forEach((user) => {
        this.formInput.checkedUserIds.push(user.userId)
        this.allUserMap[user.userId] = user
      })
      if (this.modalData.mode === 'Conditions') {
        this.formInput.segmentConditions.forEach((item) => {
          item.multi_condition.forEach((subItem) => {
            if (subItem.expression == 'date_range') {
              subItem.range = {
                start: this.$dateTime.toIsoFormat(subItem.range.start + ' UTC'),
                end: this.$dateTime.toIsoFormat(subItem.range.end + ' UTC'),
              }
            }
          })
        })
        this.selectRule = 'conditions'
      } else if (this.modalData.mode === 'Custom') {
        this.selectRule = 'users'
      } else if (this.modalData.mode === 'CVKey') {
        this.formInput.cvParam.value = this.modalData.cvKey[this.formInput.cvParam.key]
        this.selectRule = 'cvParam'
      }
      $('#segment').modal('show')
    },
    searchUsers(val, oldval) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        keywords: this.searchUser,
      }
      this.isLoading = true
      apiFunction.getUserList(formData, this.jwtToken).then((data) => {
        this.isLoading = false
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          let userArr = []
          this.removeDupUser(data.data.Result.data).forEach((user) => {
            userArr.push(user)
            this.allUserMap[user.userId] = user
          })
          this.userList = userArr
        }
      })
    },
    // Segment page push message title edit
    editName() {
      $('#segment-create-title').hide()
      $('#segment-create-input').show()
      $('#segment-create-edit').hide()
    },
    // Segment page push message title submit
    confirmChangeName($event) {
      if ($event.which == 13) {
        $('#segment-create-title').show()
        $('#segment-create-input').hide()
        $('#segment-create-edit').show()
        return false
      }
    },
    // Example: SG0001
    setNameRule(totalSegment) {
      let defaultName = (Number(totalSegment) + 1).toString()
      this.formInput.segmentName = 'SG' + defaultName.padStart(5, '0')
    },
    // Remove users with same user id in history, keep status = 1 first
    removeDupUser(arr) {
      let followers = []
      arr.forEach((follower) => {
        if (follower.accountStatus == '1') {
          followers.push(follower)
        }
      })
      const filteredArr = followers.reduce((acc, current) => {
        const x = acc.find((item) => item.userId === current.userId)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
      return filteredArr
    },
    // hide name if name length larger than 20
    hideNameLength(name) {
      if (name.length > 20) {
        name = name.substr(0, 20) + '...'
      }
      return name
    },
    // Prepare tag and user when page load
    getTagAndUser() {
      let formData = { adminId: this.adminId, accountId: this.accountId }

      apiFunction.getTag(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          // reset tag list
          this.allTags = data.data.Result.data
          this.tagList.splice(0)
          this.allTags.forEach((tag) => {
            this.tagList.push({
              name: tag.name,
              color: tag.color,
            })
          })
        }
      })
      // Get user
      apiFunction.getUserList(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          let userArr = []
          this.removeDupUser(data.data.Result.data).forEach((user) => {
            userArr.push(user)
            this.allUserMap[user.userId] = user
          })
          this.userList = userArr
        }
      })
    },
    // Prepare user variable when page load
    getAllVariables() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
      }
      // Get variables
      apiFunction.getVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          })
          this.$router.push('/login')
        } else {
          if (data.data) {
            this.allVariables = data.data.Result.data
          }
        }
      })
    },
    // Get value array from all tags
    valuesOfTag(color, name) {
      var values = []
      let tag = this.allTags.find((tag) => tag.color === color && tag.name === name)
      if (tag) {
        values = tag.values
      }
      return values
    },
    onCondSourceChanged(event, index, subIndex) {
      let newSource = event.target.value
      this.formInput.segmentConditions[index].multi_condition[subIndex].value = ''
      this.formInput.segmentConditions[index].multi_condition[subIndex].tag = ''
      this.formInput.segmentConditions[index].multi_condition[subIndex].cros = {
        type: '',
        item: '',
      }
      this.formInput.segmentConditions[index].multi_condition[subIndex].uservariable = {
        name: '',
      }
      this.formInput.segmentConditions[index].multi_condition[subIndex].range = {
        start: '',
        end: '',
      }
      let newExpression = ''
      if (newSource === 'followtime') {
        newExpression = 'date_range'
      } else if (newSource === 'cvkey') {
        newExpression = 'has_value'
        this.formInput.segmentConditions[index].multi_condition[subIndex].value = ''
      } else if (newSource === 'serverpushtime') {
        newExpression = 'datetime_range'
      } else {
        newExpression = 'exactly_match'
      }
      this.formInput.segmentConditions[index].multi_condition[subIndex].expression = newExpression
    },
    onCondTagChanged(index, subIndex) {
      this.formInput.segmentConditions[index].multi_condition[subIndex].value = ''
    },
    addNewCondition() {
      let responseForm = {
        multi_condition: [
          {
            source: 'tag',
            tag: '',
            value: '',
            uservariable: {
              name: '',
            },
            cros: {
              type: '',
              item: '',
            },
            range: {
              start: '',
              end: '',
            },
            expression: 'exactly_match',
          },
        ],
      }
      this.formInput.segmentConditions.push(responseForm)
    },
    addSubCondition(index) {
      this.formInput.segmentConditions[index].multi_condition.push({
        source: 'tag',
        tag: '',
        value: '',
        uservariable: {
          name: '',
        },
        cros: {
          type: '',
          item: '',
        },
        range: {
          start: '',
          end: '',
        },
        expression: 'exactly_match',
      })
    },
    deleteCondition(index) {
      this.formInput.segmentConditions.splice(index, 1)
    },
    deleteSubCondition(index, subIndex) {
      this.formInput.segmentConditions[index].multi_condition.splice(subIndex, 1)
    },
    selectAllChanged() {
      if (this.allSelected) {
        // select all user, push all user into checkedUserIds if not exist.
        this.userList.forEach((user) => {
          if (this.formInput.checkedUserIds.indexOf(user.userId) <= -1) {
            this.formInput.checkedUserIds.push(user.userId)
          }
        })
      } else {
        // unselect all user, remove all userList user from checkedUserIds.
        let tmpArray = []
        this.formInput.checkedUserIds.forEach((userId) => {
          let user = this.userList.find((user) => user.userId === userId)
          if (!user) {
            // cannot find user in the userlist(the list showing on the UI), keep user in the checkedUserIds
            tmpArray.push(userId)
          }
        })
        this.formInput.checkedUserIds.splice(0)
        this.formInput.checkedUserIds = tmpArray
      }
    },
    // Create & edit segment
    submitSegment() {
      var self = this
      let segmentId = this.editId
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        mode: '',
        segmentName: this.formInput.segmentName,
        segmentConditions: this.formInput.segmentConditions,
        segmentUsers: this.modalData.segmentUsers,
        defaultUsersSegment: this.formInput.defaultUsersSegment,
        cvKey: {},
      }
      if (segmentId !== '') {
        formData['segmentId'] = segmentId
      }

      // バリデーション
      if (this.selectRule == 'conditions') {
        formData.mode = 'Conditions'
        // タグによる分類の場合
        var err = false
        if (this.formInput.segmentConditions.length == 0) {
          err = true
        } else {
          this.formInput.segmentConditions.forEach(function (ele) {
            ele.multi_condition.forEach((cond) => {
              if (cond.source == 'tag') {
                // Didn't select tag. show error.
                if (cond.tag == '') {
                  err = true
                } else if (cond.tag.name == '' || cond.tag.color == '') {
                  err = true
                }
              } else if (cond.source == 'uservariable' && cond.uservariable == '') {
                err = true
              } else if (cond.source == 'cros' && (cond.cros.type == '' || cond.cros.item == '')) {
                err = true
              } else if (
                (cond.expression == 'exactly_match' && cond.value == '') ||
                (cond.expression == 'fuzzy_match' && cond.value == '')
              ) {
                err = true
              }
              // Validate date range
              if (cond.expression == 'date_range') {
                if (cond.range.start == '' || cond.range.end == '') {
                  err = true
                } else {
                  cond.range = {
                    start: self.$dateTime.toBackendTimeFormat(cond.range.start),
                    end: self.$dateTime.toBackendTimeFormat(cond.range.end),
                  }
                }
              }
            })
          })
        }
        if (err) {
          // 選択されているタグがない場合はエラー
          this.$fire({
            title: this.$t('__createSegmentInputError'),
            text: this.$t('__Pleasefillinallfields'),
            type: 'error',
          })
          return
        }
      } else if (this.selectRule == 'users') {
        formData.mode = 'Custom'
        // 手動設定の場合
        let segmentUsers = []
        if (this.formInput.checkedUserIds.length > 0) {
          this.formInput.checkedUserIds.forEach((userId) => {
            let user = this.allUserMap[userId]
            if (user) {
              segmentUsers.push(user)
            }
          })
        }
        formData.segmentUsers = segmentUsers
      } else if (this.selectRule == 'cvParam') {
        formData.mode = 'CVKey'
        if (this.formInput.cvParam.key == '' || this.formInput.cvParam.value == '') {
          this.$fire({
            title: this.$t('__createSegmentInputError'),
            text: this.$t('__createSegmentNoCvValue'),
            type: 'error',
          })
          return
        }
        formData.cvKey[this.formInput.cvParam.key] = this.formInput.cvParam.value
      }
      this.isLoading = true
      apiFunction.saveUserSegment(formData, this.jwtToken).then((data) => {
        this.isLoading = false
        if (data.error) {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        } else {
          $('#segment').modal('hide')
          this.$fire({
            title: this.$t('__addedsuccessfully'),
            text: this.modalData.modalSuccessMsg,
            type: 'success',
            timer: 1500,
          }).then((e) => {
            this.$router.go()
          })
        }
      })
    },
  },
}
</script>
