import CreateMetaUtilModal from './CreateMetaUtilModal.vue'
import AutoReplyCommentsModal from './AutoReplyCommentsModal.vue'

let modals = {
  CreateMetaUtilModal,
  AutoReplyCommentsModal,
}

export default modals

export { CreateMetaUtilModal, AutoReplyCommentsModal }
