<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th style="cursor: pointer" @click="onClickScenarioNameColumnHeader">
        {{ this.$t('__Conversationname') }} {{ getSortOrderLabel(scenarioNameColumnSortState) }}
      </th>
      <th>{{ this.$t('__NodeTags') }}</th>
      <th>{{ this.$t('__Triggerpoint') }}</th>
      <th>{{ this.$t('__NextScenario') }}</th>
      <th>{{ this.$t('__ReservedScenario') }}</th>
      <th v-if="accountType == 'line'">User Icon</th>
      <th>{{ this.$t('__Numberofnodes') }}</th>
      <th>{{ this.$t('__Editdateandtime') }}</th>
      <th></th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td>{{ item.scenario.scenarioName }}</td>
          <td>{{ getTagNames(item) }}</td>
          <td
            v-html="triggerValue(item.scenario.trigger)"
            @click="showKeyords(item.scenario.trigger, item.scenario.keywords)"
            :class="{ pointer: item.scenario.trigger == 'keyword' }"
          ></td>
          <td>{{ item.scenario.nextScenarioName }}</td>
          <td>{{ item.scenario.reservedScenarioName }}</td>
          <td v-if="accountType == 'line'">
            <select v-model="item.scenario.iconUrlId" @change="onUserIconChanged(item)">
              <option value selected>
                {{ $t('__UserIconSelectOptional') }}
              </option>
              <option :value="userIcon.id" v-for="(userIcon, index) in userIconList" :key="index">
                {{ userIcon.name }}
              </option>
            </select>
          </td>
          <td>{{ item.nodes.length }}</td>
          <td>{{ toFrontendTimeFormat(item.updateTime) }}</td>
          <td>
            <a href="javascript:void(0)" class="editStoryIcon" @click="editStory(item)">
              <i class="fas fa-edit mx-1"></i>
            </a>
            <router-link
              :to="{
                name: 'nodes',
                params: {
                  id: item.scenario.scenarioId,
                  adminId: adminId,
                  jwtToken: jwtToken,
                  accountId: accountId,
                },
              }"
            >
              <i class="fas fa-comment-dots mx-1"></i>
            </router-link>
            <a href="javascript:void(0)" @click="copyScenario(item)">
              <i class="fas fa-copy mx-1"></i>
            </a>
            <a href="javascript:void(0)" @click="deleteScenario(item.scenario.scenarioId)">
              <i class="fas fa-trash text-danger mx-1"></i>
            </a>
          </td>
        </slot>
      </tr>
    </tbody>
    <create-edit-story-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      :allScenario="allScenario"
      v-bind:modalData="modalData"
      v-bind:existTrigger="trigger"
      :editId="editId"
      :key="componentKey"
      v-if="componentKey"
    ></create-edit-story-modal>
    <!-- add new story icon-->
    <div class="btn-follow">
      <a href="javascript:void(0)" v-on:click="createStory()" style="color: #fff">
        <i class="fas fa-plus"></i>
      </a>
    </div>
    <!-- add new story icon-->
  </table>
</template>
<script>
import { apiFunction } from '../Axios/functions'
import { CreateEditStoryModal } from '../Modals/index'
import { Utility } from '../../../utility'
export default {
  name: 'story-table',
  components: { CreateEditStoryModal },
  props: {
    columns: Array,
    adminId: String,
    accountId: String,
    allScenario: Array,
    data: Array,
    userIconList: Array,
    type: {
      type: String, // striped | hover
      default: 'striped',
    },
    currentNumber: {
      type: Number,
      default: 0,
    },
    trigger: Object,
  },
  data() {
    return {
      accountType: '',
      scenarioId: '',
      jwtToken: '',
      copyScenarioItem: {
        scenario: {},
        nodes: [],
      },
      modalData: {
        scenarioName: '',
        trigger: '',
        keywords: [],
        nodes: [],
        modalBtn: this.$t('__edit'),
        modalSuccessMsg: this.$t('__Anewconversationhasbeensuccessfullyestablished'),
      },
      editId: '',
      componentKey: 0,
      isScenarioSortAsc: true,
      // テーブルのソート状態（none, asc, desc）
      scenarioNameColumnSortState: 'none',
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type}`
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    getTagNames(item) {
      // Create an empty Set to avoid duplicate tag names (if desired)
      const tagSet = new Set()
      // Loop through each node in item.nodes
      item.nodes.forEach((node) => {
        // Check if nodeTag exists and is an array
        if (node.nodeTag && Array.isArray(node.nodeTag)) {
          node.nodeTag.forEach((tag) => {
            if (!/^Lv\d+$/i.test(tag.name)) tagSet.add(tag.name)
          })
        }
      })
      // Convert the set to an array and join them with commas
      return [...tagSet].join(', ')
    },
    // Prepare page content
    async loadData() {
      try {
        this.accountType = this.getAccountType()
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }
    },
    // Get account type from _token
    getAccountType() {
      let decrypt = this.$aes.decrypt(localStorage._token).split(',')
      let accountType = decrypt[3]
      return accountType
    },
    // Tirgger type: follow, keyword, error, ""
    triggerValue(item) {
      if (item == 'follow') {
        return '<span>' + this.$t('__Userjoin') + '</span>'
      }
      if (item == 'keyword') {
        return '<span>' + this.$t('__Keyword') + '</span>'
      }
      if (item == 'error') {
        return '<span>' + this.$t('__Errormessage') + '</span>'
      }
      if (item == '') {
        return '<span>' + this.$t('__Manualsending') + '</span>'
      }
    },
    // Show keywords when click 關鍵字
    showKeyords(trigger, keywords) {
      if (trigger == 'keyword') {
        this.$alert(`${this.$t('__keywords')}: ${keywords}`)
      }
    },
    // Example: 2020-06-30 15:03
    toFrontendTimeFormat(dateTime) {
      return this.$dateTime.toFrontEndTimeFormat(dateTime)
    },
    // Edit scenario
    editStory(item) {
      this.forceRerender()
      this.editId = item.scenario.scenarioId
      this.modalData.nodes = item.nodes
      this.modalData.scenarioName = item.scenario.scenarioName
      this.modalData.trigger = item.scenario.trigger
      this.modalData.keywords = Utility.deepCopy(item.scenario.keywords)
      this.modalData.modalBtn = this.$t('__edit')
      this.modalData.modalSuccessMsg = this.$t('__Editsuccessfullyaccessed')
    },
    // Re render modal component
    forceRerender() {
      this.componentKey += 1
    },
    // Init new name
    // Example C00001
    getNewScenarioName() {
      // シナリオ名のリストを取得
      const scenarioNames = this.data.map((e) => e.scenario.scenarioName)
      // 連番部分の初期値はシナリオ総数 + 1
      let consecutiveNumber = scenarioNames.length + 1
      while (scenarioNames.includes(`C${consecutiveNumber.toString().padStart(5, '0')}`)) {
        // 生成したシナリオ名が既に存在する場合は、連番部分をインクリメント
        consecutiveNumber++
      }
      return 'C' + consecutiveNumber.toString().padStart(5, '0')
    },
    // Add scenario
    createStory() {
      this.forceRerender()
      this.editId = ''
      this.modalData.scenarioName = this.getNewScenarioName()
      this.modalData.trigger = ''
      this.modalData.nodes = []
      this.modalData.keywords = []
      this.modalData.modalBtn = this.$t('__setup')
      this.modalData.modalSuccessMsg = this.$t('__Anewconversationhasbeensuccessfullyestablished')
    },
    // Copy scenario
    copyScenario(target) {
      this.copyScenarioItem.scenario = {
        keywords: target.scenario.keywords,
        scenarioName: target.scenario.scenarioName + '(copy)',
        trigger: target.scenario.trigger,
      }
      this.copyScenarioItem.nodes = Utility.deepCopy(target.nodes)
      // Follow & Error are not allow to copy
      if (target.scenario.trigger == 'follow' || target.scenario.trigger == 'error') {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Theerrormessageoruserjoinmessagealreadyexists'),
          type: 'error',
        })
      }

      this.$fire({
        title: this.$t('__Copycurrenttarget'),
        text: this.$t('__Createacopyofthisconversationwiththenode'),
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__Add'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          // Update nodeId
          var nodeIdMap = {}
          this.copyScenarioItem.nodes.forEach((node, index) => {
            const newNodeId = Utility.generateNewId(this.$dateTime.getDateTime(), index)
            nodeIdMap[node.nodeId] = newNodeId
            node.nodeId = newNodeId
          })
          // Update nextNode Id
          this.copyScenarioItem.nodes.forEach((node, index) => {
            if (node.nextNode != '') {
              const oldNodeId = node.nextNode
              node.nextNode = nodeIdMap[oldNodeId] ? nodeIdMap[oldNodeId] : ''
            }
          })

          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            scenario: this.copyScenarioItem.scenario,
            nodes: this.copyScenarioItem.nodes,
          }

          apiFunction.saveScenario(formData, this.jwtToken).then((data) => {
            if (data) {
              this.$fire({
                title: this.$t('__addedsuccessfully'),
                text: this.$t('__Anewcopyhasbeencreated'),
                type: 'success',
                timer: 1500,
              }).then((e) => {
                this.$router.go()
              })
            } else {
              this.$fire({
                title: this.$t('__Addfailed'),
                text: this.$t('__oopsSomethingwentwrong'),
                type: 'error',
              })
            }
          })
        }
      })
    },
    // Delete scenario
    deleteScenario(scenarioId) {
      this.scenarioId = scenarioId
      this.$fire({
        title: this.$t('__Areyousureyouwanttodeleteit'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            scenarioId: this.scenarioId,
          }

          apiFunction.deleteScenario(formData, this.jwtToken).then((data) => {
            if (data) {
              this.$fire({
                title: this.$t('__successfullydeleted'),
                text: this.$t('__Yourdatahasbeensuccessfullydeleted'),
                type: 'success',
                timer: 1500,
              }).then((e) => {
                this.$router.go()
              })
            } else {
              this.$fire({
                title: this.$t('__failedtodelete'),
                text: this.$t('__oopsSomethingwentwrong'),
                type: 'error',
              })
            }
          })
        }
      })
    },
    getSortOrderLabel(order) {
      if (order == 'none') return ''
      return order == 'asc' ? '▲' : '▼'
    },
    onClickScenarioNameColumnHeader() {
      this.scenarioNameColumnSortState = this.scenarioNameColumnSortState == 'asc' ? 'desc' : 'asc'
      this.sortTableByScenarioNameColumn()
    },
    sortTableByScenarioNameColumn() {
      const ascFunc = (a, b) => {
        if (a.scenario.scenarioName < b.scenario.scenarioName) return -1
        if (a.scenario.scenarioName > b.scenario.scenarioName) return 1
        return 0
      }
      const descFunc = (a, b) => {
        if (a.scenario.scenarioName > b.scenario.scenarioName) return -1
        if (a.scenario.scenarioName < b.scenario.scenarioName) return 1
        return 0
      }
      this.data.sort(this.scenarioNameColumnSortState == 'asc' ? ascFunc : descFunc)
    },
    onUserIconChanged(item) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        scenario: item.scenario,
        nodes: item.nodes,
      }

      apiFunction.saveScenario(formData, this.jwtToken).then((data) => {
        if (data) {
          this.$fire({
            title: this.$t('__updatecompleted'),
            text: this.$t('__Editsuccessfullyaccessed'),
            type: 'success',
            timer: 1500,
          })
        } else {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
  },
}
</script>
<style>
td a:not(.md-button) {
  color: #212529 !important;
}
</style>
