import Notify from 'vue-notifyjs'
import SideBar from '@/components/SidebarPlugin'
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import DateTimeFunctions from './datetimeFunctions'
import 'es6-promise/auto'

//css assets
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/sass/paper-dashboard.scss'
// import "@/assets/css/themify-icons.css";
// from old chatbot
// import "@/assets/css/sb-admin.css";
import '@/assets/css/common.css'
// <!-- Custom fonts for this template-->
// <link href="vendor/fontawesome-free/css/all.min.css" rel="stylesheet" type="text/css">
// <!-- Page level plugin CSS-->
// <link href="vendor/datatables/dataTables.bootstrap4.css" rel="stylesheet">
// <!-- Custom styles for this template-->
// <link href="css/sb-admin.css" rel="stylesheet">
// <link href="css/common.css" rel="stylesheet">
// <script src="vendor/jquery/jquery.min.js"></script>

export default {
  install(Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(DateTimeFunctions)
    Vue.use(SideBar)
    Vue.use(Notify)
  },
}
