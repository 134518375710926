<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>#</th>
      <th style="cursor: pointer" @click="onClickSegmentNameColumnHeader">
        {{ this.$t('__CategoryName') }} {{ getSortOrderLabel(segmentNameColumnSortState) }}
      </th>
      <th>{{ this.$t('__Classificationrules') }}</th>
      <th>{{ this.$t('__Numberofpeopleincategory') }}</th>
      <th>{{ this.$t('__Sendconversation') }}</th>
      <th></th>
    </thead>
    <tbody>
      <tr
        :class="{ 'table-info': item.defaultUsersSegment }"
        v-for="(item, index) in data"
        :key="index"
      >
        <slot :row="item">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>
            <div v-if="item.conditions.length > 0">
              <div
                class="mb-1"
                v-for="(condition, condition_index) in item.conditions"
                :key="'tag_' + condition_index"
              >
                <ul
                  class="list-group list-group-horizontal"
                  v-for="(subItem, subIndex) in condition.multi_condition"
                  :key="'cond_' + subIndex"
                >
                  <li class="list-group-item" style="width: 150px">
                    <span v-if="subItem.source == 'uservariable'">{{ $t('__variable') }}</span>
                    <span v-if="subItem.source == 'tag'">{{ $t('__label') }}</span>
                    <span v-if="subItem.source == 'followtime'">{{
                      $t('__FollowAccountTime')
                    }}</span>
                    <span v-if="subItem.source == 'cvkey'">{{ $t('__CvParam') }}</span>
                    <span v-if="subItem.source == 'serverpushtime'">{{
                      $t('__ServerPushTime')
                    }}</span>
                    <span v-if="subItem.source == 'cros'">CROS</span>
                    <span v-if="subItem.source == 'utils'">{{ $t('__metaUtils') }}</span>
                  </li>
                  <li class="list-group-item" style="width: 160px" v-if="subItem.source != 'utils'">
                    <span v-if="subItem.source == 'uservariable'">{{
                      subItem.uservariable.name
                    }}</span>
                    <span v-if="subItem.source == 'tag'">{{ subItem.tag.name }}</span>
                    <span v-if="subItem.source == 'cvkey'">{{ subItem.value }}</span>
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'line_registered_date'
                      "
                      >加入好友日</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'birth'
                      "
                      >出生年月日</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'latest_order_id'
                      "
                      >最近一次購買商品ID</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'create_date_latest'
                      "
                      >最近一次購買日期</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'actual_shipping_date_latest'
                      "
                      >最近一次發貨日期</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'sum'
                      "
                      >最近一次購買總金額</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'count'
                      "
                      >購買次數</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'teiki_flg'
                      "
                      >定期購買者</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'promotion_code'
                      "
                      >最近一次促銷代碼</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'product_code'
                      "
                      >最近一次商品代碼</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'regular_purchase_flg'
                      "
                      >定期標誌</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_latest' &&
                        subItem.cros.item == 'current_delivery_number_of_time'
                      "
                      >定期次數</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'order_id'
                      "
                      >訂單ID</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'promotion_code'
                      "
                      >促銷代碼</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'create_date'
                      "
                      >訂單創建日期</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'actual_shipping_date'
                      "
                      >發貨日期</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'stock_warehousing_date'
                      "
                      >退貨日期</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'status'
                      "
                      >訂單狀態</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'payment_total'
                      "
                      >訂單金額</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'product_code'
                      "
                      >訂單明細:商品代碼</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'price'
                      "
                      >訂單明細:商品代碼金額</span
                    >
                    <span
                      v-if="
                        subItem.source == 'cros' &&
                        subItem.cros.type == 'cros_history' &&
                        subItem.cros.item == 'quantity'
                      "
                      >訂單明細:商品代碼數量</span
                    >
                  </li>
                  <li class="list-group-item" style="width: 100px">
                    <span v-if="subItem.expression == 'exactly_match'">{{
                      $t('__ExactlyMatch')
                    }}</span>
                    <span v-if="subItem.expression == 'fuzzy_match'">{{ $t('__FuzzyMatch') }}</span>
                    <span v-if="subItem.expression == 'has_value'">{{
                      $t('__segmentcondition')
                    }}</span>
                    <span v-if="subItem.expression == 'no_value'">{{
                      $t('__segmentnocondition')
                    }}</span>
                    <span v-if="subItem.expression == 'no_exist'">{{
                      $t('__segmentnoexist')
                    }}</span>
                    <span v-if="subItem.expression == 'num_range'">{{ $t('__NumberRange') }}</span>
                    <span v-if="subItem.expression == 'num_gt'">{{
                      $t('__GreaterThanNumber')
                    }}</span>
                    <span v-if="subItem.expression == 'num_lt'">{{ $t('__LessThanNumber') }}</span>
                    <span v-if="subItem.expression == 'date_range'">{{ $t('__DataRange') }}</span>
                    <span v-if="subItem.expression == 'datetime_range'">{{
                      $t('__DatatimeRange')
                    }}</span>
                    <span v-if="subItem.expression == 'short_link'">{{ $t('__shortLink') }}</span>
                    <span v-if="subItem.expression == 'auto_reply_comments'">{{
                      $t('__autoReplyComments')
                    }}</span>
                    <span v-if="subItem.expression == 'auto_reply_live_mention'">{{
                      $t('__autoReplyLiveMention')
                    }}</span>
                    <span v-if="subItem.expression == 'auto_reply_media'">{{
                      $t('__autoReplyMedia')
                    }}</span>
                  </li>
                  <li
                    class="list-group-item"
                    style="width: 240px"
                    v-if="
                      (subItem.expression != 'has_value') &
                      (subItem.expression != 'no_value') &
                      (subItem.expression != 'no_exist')
                    "
                  >
                    <span v-if="subItem.expression == 'exactly_match'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'fuzzy_match'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'num_gt'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'num_lt'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'num_range'"
                      >{{ subItem.range.start }} ~ {{ subItem.range.end }}</span
                    >
                    <span v-if="subItem.expression == 'date_range'"
                      >{{ toDateString(subItem.range.start) }} ~
                      {{ toDateString(subItem.range.end) }}</span
                    >
                    <span v-if="subItem.expression == 'datetime_range'"
                      >{{ toDatetimeString(subItem.range.start) }} ~
                      {{ toDatetimeString(subItem.range.end) }}</span
                    >
                    <span v-if="subItem.expression == 'short_link'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'auto_reply_comments'">{{
                      subItem.value
                    }}</span>
                    <span v-if="subItem.expression == 'auto_reply_live_mention'">{{
                      subItem.value
                    }}</span>
                    <span v-if="subItem.expression == 'auto_reply_media'">{{ subItem.value }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="item.mode == 'CVKey'">
              <span>orderId: {{ item.cvKey.orderId }}</span>
            </div>
            <span v-else>{{ $t('__Custom') }}</span>
          </td>
          <td @click="showUser(item.users)" :class="{ pointer: item.users.length != 0 }">
            {{ item.usersCount }}
          </td>
          <td>
            <a href="javascript:void(0);" @click="broadcastInSegment(item)">
              <font-awesome-icon icon="bullhorn" />
            </a>
          </td>
          <td>
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#segment"
              class="px-1"
              @click="editSegment(item)"
            >
              <font-awesome-icon icon="edit" />
            </a>
            <a href="javascript:void(0)" class="px-1" @click="showLiffQrCode(item)">
              <i class="fas fa-qrcode"></i>
            </a>
            <a href="javascript:void(0)" class="px-1" @click="deleteSegment(item.id)">
              <i class="fas fa-trash text-danger"></i>
            </a>
          </td>
        </slot>
      </tr>
    </tbody>
    <create-segment-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      v-bind:modalData="modalData"
      :editId="editId"
      :key="componentKey"
      v-if="componentKey"
    ></create-segment-modal>
    <create-push-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      v-bind:modalData="broadcastData"
      :key="pushMessageKey"
      v-if="pushMessageKey"
    ></create-push-modal>

    <qr-code-modal
      ref="QrCodeModal"
      :modalData="liffModalData"
      :allScenario="allScenario"
      :updateSegment="updateSegmentScenario"
    ></qr-code-modal>

    <!-- add -->
    <div class="btn-follow" @click="createSegment()">
      <a style="color: #fff">
        <i class="fas fa-plus"></i>
      </a>
    </div>
  </table>
</template>
<script>
import { apiFunction } from '../Axios/functions'
import { CreatePushModal, CreateSegmentModal, QrCodeModal } from '../Modals/index'
import { Utility } from '../../../utility'
export default {
  name: 'segment-table',
  components: { CreateSegmentModal, CreatePushModal, QrCodeModal },
  props: {
    columns: Array,
    adminId: String,
    jwtToken: String,
    accountId: String,
    data: Array,
    type: {
      type: String, // striped | hover
      default: 'striped',
    },
    newSegment: Object,
    currentNumber: {
      type: Number,
      default: 0,
    },
    allScenario: Array,
  },
  data() {
    return {
      segmentId: '',
      modalData: {
        mode: '',
        segmentName: '',
        segmentConditions: [],
        segmentUsers: [],
        cvKey: {},
        defaultUsersSegment: false,
        modalBtn: this.$t('__setup'),
        modalSuccessMsg: this.$t('__Anewcategoryhasbeensuccessfullycreated'),
      },
      broadcastData: {
        broadcastName: '',
        segment: [],
        broadcastUsers: [],
        modalSuccessMsg: this.$t('__Theappointmentschedulehasbeensuccessfullycreated'),
      },
      editId: '',
      componentKey: 0,
      pushMessageKey: 0,
      // テーブルのソート状態（none, asc, desc）
      segmentNameColumnSortState: 'none',
      liffModalData: {
        segmentId: '',
        liffUrl: '',
        liffScenarioId: '',
      },
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type}`
    },
    tagColor(color) {
      return this
    },
  },
  methods: {
    // Render modal component each click
    forceRerender() {
      this.componentKey += 1
    },
    // Render modal component each click
    forceRerenderPush() {
      this.pushMessageKey += 1
    },
    // Init new name
    // Example: SG00001
    getNewSegmentName() {
      let totalSegment = this.currentNumber
      let defaultName = (Number(totalSegment) + 1).toString()
      return 'SG' + defaultName.padStart(5, '0')
    },
    // Broadcast
    broadcastInSegment(segment) {
      this.forceRerenderPush()
      this.broadcastData.broadcastName = segment.name
      this.broadcastData.broadcastUsers = segment.users
    },
    // Show users name when click number of users
    showUser(users) {
      if (users.length == 0) return
      const userNames = users.map((user) => user.userName)
      this.$alert(`${this.$t('__segmentedUsers')}: ${userNames.join(',')}`)
    },
    // Add segment
    createSegment() {
      this.forceRerender()
      this.editId = ''
      this.modalData.mode = 'Tags'
      this.modalData.segmentName = this.getNewSegmentName()
      this.modalData.segmentConditions = []
      this.modalData.segmentUsers = []
      this.modalData.cvKey = {}
      this.modalData.defaultUsersSegment = false
      this.modalData.modalBtn = this.$t('__setup')
      this.modalData.modalSuccessMsg = this.$t('__Anewcategoryhasbeensuccessfullycreated')
    },
    // Edit segment
    editSegment(segment) {
      this.forceRerender()
      this.editId = segment.id
      this.modalData.mode = segment.mode ? segment.mode : ''
      this.modalData.segmentName = segment.name
      this.modalData.segmentConditions = Utility.deepCopy(segment.conditions)
      this.modalData.segmentUsers = Utility.deepCopy(segment.users)
      this.modalData.cvKey = segment.cvKey ? segment.cvKey : {}
      this.modalData.defaultUsersSegment = segment.defaultUsersSegment
      this.modalData.modalBtn = this.$t('__edit')
      this.modalData.modalSuccessMsg = this.$t('__Editsuccessfullyaccessed')
    },
    // Delete segment
    deleteSegment(segmentId) {
      this.segmentId = segmentId
      this.$fire({
        title: this.$t('__Areyousureyouwanttodeleteit'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            segmentId: this.segmentId,
          }

          apiFunction.deleteUserSegment(formData, this.jwtToken).then((data) => {
            if (data) {
              this.$fire({
                title: this.$t('__successfullydeleted'),
                text: this.$t('__Yourdatahasbeensuccessfullydeleted'),
                type: 'success',
                timer: 1500,
              }).then((e) => {
                this.$router.go()
              })
            } else {
              this.$fire({
                title: this.$t('__failedtodelete'),
                text: this.$t('__oopsSomethingwentwrong'),
                type: 'error',
              })
            }
          })
        }
      })
    },
    // Update segment
    updateSegmentScenario(segmentId, scenarioId) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        segmentId: segmentId,
        scenarioId: scenarioId,
      }

      apiFunction.saveUserSegment(formData, this.jwtToken).then((data) => {
        if (data) {
          this.$fire({
            title: this.$t('__addedsuccessfully'),
            text: this.$t('__Editsuccessfullyaccessed'),
            type: 'success',
            timer: 1500,
          }).then((e) => {
            this.$router.go()
          })
        } else {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
    // Show QR code of Liff Url
    showLiffQrCode(segment) {
      this.liffModalData.segmentId = segment.id
      this.liffModalData.scenarioId = segment.scenarioId ? segment.scenarioId : ''
      this.liffModalData.liffUrl = segment.liffurl
      $('#qr-code-modal').modal('show')
    },
    getSortOrderLabel(order) {
      if (order == 'none') return ''
      return order == 'asc' ? '▲' : '▼'
    },
    onClickSegmentNameColumnHeader() {
      this.segmentNameColumnSortState = this.segmentNameColumnSortState == 'asc' ? 'desc' : 'asc'
      this.sortTableBySegmentNameColumn()
    },
    sortTableBySegmentNameColumn() {
      if (this.segmentNameColumnSortState == 'none') return
      const ascFunc = (a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      const descFunc = (a, b) => {
        if (a.name > b.name) return -1
        if (a.name < b.name) return 1
        return 0
      }
      this.data.sort(this.segmentNameColumnSortState == 'asc' ? ascFunc : descFunc)
    },
    toDateString(datetime) {
      return Utility.toDateString(datetime)
    },
    toDatetimeString(datetime) {
      let time = new Date(datetime)
      return time.toLocaleDateString() + ' ' + time.toLocaleTimeString()
    },
  },
}
</script>
<style></style>
