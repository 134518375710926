<template>
  <div>
    <!-- Loading spinner -->
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!isLoading" id="myKanban">
      <div
        class="kanban-container"
        v-for="index in maxLevel + 1"
        :key="'level' + index"
        :id="'level' + index"
      >
        <h5 class="text-center">Lv{{ index }}</h5>
        <draggable
          class="kanban-board mr-5 p-4 list-group"
          v-model="nodes"
          :id="index"
          handle=".draggable-item"
          group="node"
          @change="nodeDragChangedHandler"
          @start="drag = true"
          @end="nodeDragEndHandler"
        >
          <!-- node -->
          <div
            v-for="nodeItem in nodes"
            :key="nodeItem.nodeId"
            ghost-class="ghost"
            :id="nodeItem.nodeId"
            :name="nodeItem.level"
          >
            <div
              v-if="nodeItem.level == index"
              :class="{
                'error list-group-item': nodeItem.error == 1,
                'list-group-item': nodeItem.error != 1,
                'draggable-item': draggableNode(nodeItem.nodeId),
              }"
            >
              <div v-if="draggableNode(nodeItem.nodeId)" class="float-left">
                <a>
                  <i class="fas fa-align-justify"></i>
                </a>
              </div>
              <div class="float-right">
                <a
                  href="javascript:void(0)"
                  class="text-danger float-right"
                  @click="deleteNode(nodeItem)"
                >
                  <i class="fas fa-trash text-danger"></i>
                </a>
              </div>

              <div
                class="column mb-0 node-card"
                style="padding: 0"
                @contextmenu.prevent="$refs.menu.open($event, { nodeItem })"
                @click="editNode(nodeItem, 0)"
              >
                <div class="card-header item_handle drag_handler">
                  <span style="font-size: 1em; font-weight: bolder">{{
                    getNodeCardSubTitle(nodeItem)
                  }}</span>
                </div>
                <div class="card-header item_handle drag_handler pt-0">
                  <span style="font-size: 0.8em">{{ getNodeCardTitle(nodeItem) }}</span>
                </div>
                <div class="card-body item_handle drag_handler">
                  <div class="nodeIcon" v-html="nodeIcon(nodeItem)"></div>
                  <!-- TODO connector -->
                  <!-- <span class="ep" id="node.connectSp"></span> -->
                </div>
              </div>
            </div>
          </div>
          <!-- node -->
        </draggable>

        <button class="btn create-node-btn" @click="createNode(index)">
          {{ $t('__createNewNode') }}
        </button>
      </div>
    </div>

    <!-- 右クリックメニュー -->
    <vue-context ref="menu" v-slot="{ data }">
      <li>
        <a @click.prevent="copyNode(data.nodeItem)">
          {{ $t('__Copycurrenttarget') }}
        </a>
      </li>
    </vue-context>
    <!-- 右クリックメニュー -->
    <create-edit-node-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      :scenarioId="scenarioId"
      :allNodes="nodes"
      v-bind:nodeData="nodeModal"
      v-bind:allTags="allTags"
      v-bind:allVariables="allVariables"
      v-bind:allScenario="allScenario"
      v-bind:allSegments="allSegments"
      v-bind:crosApiErrs="crosApiErrs"
      v-bind:allVarForms="allVarForms"
      :key="componentKey"
      v-on:childToParent="onChildClick"
      v-if="componentKey"
    ></create-edit-node-modal>
    <create-tag-modal v-on:newTag="newTag"></create-tag-modal>
    <exist-tag-modal
      :key="tagComponentKey"
      v-on:addTag="addTag"
      v-bind:allTags="allTags"
    ></exist-tag-modal>
  </div>
</template>
<script>
import { CreateEditNodeModal, ExistTagModal, CreateTagModal } from '../Modals/index'
import draggable from 'vuedraggable'
import { apiFunction } from '../Axios/functions'
import '@/assets/css/flaticon.css'
import VueContext from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'
import { Utility } from '../../../utility'

export default {
  display: 'Simple',
  order: 0,
  name: 'nodes',
  components: {
    draggable,
    CreateEditNodeModal,
    ExistTagModal,
    CreateTagModal,
    VueContext,
  },
  props: {
    adminId: String,
    accountId: String,
    scenarioId: String,
    data: { type: Array, default: null },
  },
  data() {
    return {
      isLoading: true,
      loadingMap: {
        scenario: true,
        allScenario: true,
        allTags: true,
        allVariables: true,
        allSegments: true,
        allVarForms: true,
        crosApiErrs: true,
      },
      maxLevel: 0,
      scenario: [],
      nodes: [],

      enabled: true,
      list: [
        { name: 'John', id: 0 },
        { name: 'Joao', id: 1 },
        { name: 'Jean', id: 2 },
      ],
      nodeModal: {
        bubbleSize: ['giga', 'mega', 'kilo', 'micro', 'nano'],
        flexSize: '',
        nodeName: '',
        subtitle: '',
        boldText1: '',
        urlPic: '',
        urlPicTransition: '',
        picRatioX: '',
        picRatioY: '',
        boldText2: '',
        text: '',
        textMsg: {
          text: '',
          emojis: [],
        },
        quickReply: {
          items: [],
        },
        buttonArea: [],
        buttonStyleSelect: '',
        tags: [],
        totalNode: [],
        level: 1,
        sticker: {},
        flexMessage: {},
        carouselMsg: [
          {
            image: {
              url: '',
              aspectRatio: '16:9',
            },
            title: '',
            subtitle: '',
            buttons: [
              {
                actionType: 'uri',
                value: '',
                label: '',
                color: '#000000',
                fontColor: '#FFFFFF',
              },
            ],
          },
        ],
        carouselImageMsg: [
          {
            image: {
              url: '',
              aspectRatio: '1:1',
              linkUri: '',
            },
          },
        ],
        richMsg: {
          title: '',
          layout: 'A',
          baseUrl: '',
          areaOptions: [],
        },
        datetimepicker: {
          color: '#00b900',
          style: 'primary',
          action: {
            type: 'datetimepicker',
            label: '',
            data: '',
            mode: 'datetime',
            initial: '',
            max: '',
            min: '',
          },
          tag: {
            color: '',
            name: '',
          },
          tagValue: '',
          userVars: [],
          varName: '',
          nextNode: '',
          nextScenario: '',
        },
        flexMsgConfigs: [],
        nextNode: '',
        nextScenario: '',
        userAction: {
          userReply: {
            selectCompare: '',
            lensCompare: [
              {
                value: '<',
                label: 'lessThan',
              },
              {
                value: '>',
                label: 'moreThan',
              },
              {
                value: '=',
                label: 'equal',
              },
            ],
            variable: {},
            validate: {
              inputList: [
                {
                  type: null,
                  value: '',
                },
              ],
              success: {
                nextNodeId: '',
                scenarioId: '',
              },
              failed: {
                nextNodeId: '',
                scenarioId: '',
              },
            },
            encryption: false,
          },
          emptyVariable: {
            variable: {},
          },
        },
        constant: {
          ecsite: false,
          chichatcatBox: false,
        },
        externalApi: {
          cros: {
            enable: false,
            responseSetting: {
              success: {
                nextNodeId: '',
                scenarioId: '',
              },
              failed: {},
            },
            others: {
              orderIdReplace: false,
            },
          },
        },
        fbGenericElements: [],
        varForms: [],
        originalImageUrl: '',
        originalVideoUrl: '',
        linkUri: '',
      },
      allTags: [],
      allVariables: [],
      allScenario: [],
      allSegments: [],
      allVarForms: [],
      crosApiErrs: [],
      dragEndLevel: 1,
      dragStartLevel: 1,
      componentKey: 0,
      tagComponentKey: 0,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    setLoadingMap(target) {
      this.loadingMap[target] = false
      let isLoading = false
      for (var key in this.loadingMap) {
        if (this.loadingMap[key]) {
          isLoading = true
        }
      }
      this.isLoading = isLoading
    },
    copyNode(nodeItem) {
      const level = nodeItem.level
      // 即時增加level 列數
      if (level == this.maxLevel + 1) {
        this.maxLevel += 1
      }
      this.editNode(nodeItem, 1)
    },
    // Prepare page content
    async loadData() {
      this.scenarioId = this.$route.params.id
      let user = await this.$Amplify.Auth.currentAuthenticatedUser()
      this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      let maxLevel = 0

      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        scenarioId: this.scenarioId,
      }
      apiFunction.getScenario(formData, this.jwtToken).then((data) => {
        this.setLoadingMap('scenario')
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.scenario = data.data.Result.data.scenario
          let nodes = data.data.Result.data.nodes
          $.each(nodes, function (i, node) {
            if (Number(node.level) > maxLevel) {
              maxLevel = Number(node.level)
            }
          })

          this.maxLevel = maxLevel
          this.nodes = this.sortIndex(nodes)

          let saveformData = {
            adminId: this.adminId,
            accountId: this.accountId,
            scenario: this.scenario,
            nodes: this.nodes,
          }

          const nodeDatas = saveformData.nodes
          $.each(nodeDatas, function (nodeIndex, nodeData) {
            if (nodeData.type == 'condition') {
              //Check condition node
              const config = nodeData.condition_config
              if (config.config_url) {
                // If Condition is Url setting
                if (config.config_url != 'bysegment' && config.config_url != 'bytag') {
                  // config_url shouldn't be empty
                  if (
                    config.config_url == null ||
                    config.config_url == '' ||
                    config.config_url.indexOf('http') != 0
                  ) {
                    nodeDatas[nodeIndex].error = 1
                  }
                  // Required field validation.
                  config.options.forEach(function (configOptions) {
                    if (configOptions.value == null || configOptions.value == '') {
                      nodeDatas[nodeIndex].error = 1
                    }
                    if (
                      configOptions.nextnodeType == 'nnode' &&
                      (configOptions.nextNodeId == null || configOptions.nextNodeId == '')
                    ) {
                      nodeDatas[nodeIndex].error = 1
                    }
                    if (
                      configOptions.nextnodeType == 'snode' &&
                      (configOptions.scenario_id == null || configOptions.scenario_id == '')
                    ) {
                      nodeDatas[nodeIndex].error = 1
                    }
                  })
                }
              }
            } else {
              if (nodeData.template) {
                //Check textbox space of conversation node
                if (nodeData.template.body) {
                  nodeData.template.body.contents.forEach(function (bodyText) {
                    if (bodyText.text && bodyText.text.trim() == '') {
                      nodeDatas[nodeIndex].error = 1
                    }
                  })
                }
                //Check Button settings of conversation node
                if (nodeData.template.footer) {
                  nodeData.template.footer.contents.forEach(function (button) {
                    if (button.action.label == null || button.action.label == '') {
                      nodeDatas[nodeIndex].error = 1
                    }
                    if (button.action.type == 'uri') {
                      if (button.action.uri.indexOf('http') != 0) {
                        nodeDatas[nodeIndex].error = 1
                      }
                    }
                    // else if (button.action.type != "postback") {
                    //   nodeDatas[nodeIndex].error = 1;
                    // }
                  })
                }
              } else if (nodeData.template === null) {
                if (nodeData.title) {
                  nodeDatas[nodeIndex].error = 1
                }
              }
            }
          })

          // Pass saving data to Story/Nodes.vue
          this.$emit('saveformData', saveformData)
        }
      })

      // Get tags
      apiFunction.getTag(formData, this.jwtToken).then((data) => {
        this.setLoadingMap('allTags')
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.allTags = data.data.Result.data
        }
      })

      // Get variables
      apiFunction.getVariable(formData, this.jwtToken).then((data) => {
        this.setLoadingMap('allVariables')
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          })
          this.$router.push('/login')
        } else {
          if (data.status === 200) {
            this.allVariables = data.data.Result.data
          }
        }
      })
      // Get all scenario
      formData = { adminId: this.adminId, accountId: this.accountId }
      apiFunction.getScenario(formData, this.jwtToken).then((data) => {
        this.setLoadingMap('allScenario')
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          let storyArr = []
          data.data.Result.data.forEach((story) => {
            storyArr.push(story)
          })
          storyArr.sort((s1, s2) => {
            return s1.scenario.scenarioName.localeCompare(s2.scenario.scenarioName)
          })
          this.allScenario = storyArr
        }
      })
      // Get segment
      apiFunction.getUserSegment(formData, this.jwtToken).then((data) => {
        this.setLoadingMap('allSegments')
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.allSegments = data.data.Result.data
        }
      })
      // Get CROS API error code
      apiFunction.getCrosError(formData, this.jwtToken).then((data) => {
        this.setLoadingMap('crosApiErrs')
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.crosApiErrs = [{ code: 'default', msg: '' }].concat(data.data.Result.data)
        }
      })

      // Get forms
      apiFunction.getForm(formData, this.jwtToken).then((data) => {
        this.setLoadingMap('allVarForms')
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.allVarForms = data.data.Result.data
        }
      })
    },
    // Sort node index
    sortIndex(nodes) {
      nodes = nodes.sort(function (a, b) {
        return a.sort > b.sort ? 1 : -1
      })
      return nodes
    },
    sortByLevel(nodes) {
      nodes = nodes.sort(function (a, b) {
        return Number(a.level) > Number(b.level) ? 1 : -1
      })
      return nodes
    },
    getNodeCardTitle(node) {
      let nodeCardTitle = node.title
      if (nodeCardTitle.length > 18) {
        nodeCardTitle = nodeCardTitle.substr(0, 18) + '...'
      }
      return nodeCardTitle
    },
    getNodeCardSubTitle(node) {
      let nodeCardSubTitle = node.subtitle ? node.subtitle : 'subtitle'
      if (nodeCardSubTitle.length > 10) {
        nodeCardSubTitle = nodeCardSubTitle.substr(0, 10) + '...'
      }
      return nodeCardSubTitle
    },
    // Add tag to create & edit node
    addTag(tags) {
      this.forceRerender()
      // ノード編集ダイアログを表示するために、最新の内容をnodeModalに設定しておく
      const baseNode = this.nodes.find((e) => e.nodeId == this.nodeModal.nodeId)
      if (baseNode) {
        this.editNode(baseNode, 0)
      }

      tags.forEach((tag) => {
        if (
          !this.nodeModal.tags.find(
            (nodeTag) => nodeTag.color === tag.color && nodeTag.name === tag.name,
          )
        ) {
          this.nodeModal.tags.push({
            color: tag.color,
            name: tag.name,
          })
        }
      })
    },
    // Pass new tag arr to saving node
    newTag(tag) {
      this.forceRerender()
      this.$emit('newTag', tag)
      this.nodeModal.tags.push({
        color: tag.color,
        name: tag.name,
      })
      this.allTags.push(tag)
    },
    // Pass saving data to Story/Node.vue
    onChildClick(item) {
      let isNewNode = true
      let newNodeArr = Utility.deepCopy(this.nodes)
      $.each(newNodeArr, function (key, node) {
        if (node.nodeId === item.nodeId) {
          newNodeArr.splice(key, 1, item)
          isNewNode = false
        }
      })
      if (isNewNode === true) {
        newNodeArr.push(item)
      }
      this.nodes = newNodeArr

      let saveformData = {
        adminId: this.adminId,
        accountId: this.accountId,
        scenario: this.scenario,
        nodes: this.nodes,
      }
      this.$emit('saveformData', saveformData)
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    draggableNode(nodeId) {
      let draggable = true
      this.nodes.forEach((node) => {
        if (node.nodeId == nodeId) {
          if (node.nextScenario != '' || node.nextNode != '') {
            draggable = false
          }
        }
      })
      return draggable
    },
    // End of drag event
    nodeDragEndHandler(evt) {
      this.dragStartLevel = Number(evt.from.id)
      this.dragEndLevel = Number(evt.to.id)
    },
    // Change of drag event
    nodeDragChangedHandler: async function (evt) {
      // Since this function is called before endpoint, sleep and wait till endpoint is called.
      await this.sleep(100)
      if (evt.added) {
        // Change level
        let newSort = evt.added.newIndex + 1
        let source = evt.added.element
        let newNodeArr = []
        let newNode = {}
        let newLevel = this.dragEndLevel
        //tags
        let newTag = []
        source.nodeTag.forEach((element) => {
          if (element.name == 'Lv' + this.dragStartLevel && element.color == 'bg-danger') {
            //update level tag
            newTag.push({
              name: 'Lv' + newLevel,
              color: 'bg-danger',
            })
          } else {
            //keep other tags
            newTag.push(element)
          }
        })

        newNode = this.generateNewNode(source, newLevel, newSort.toString(), newTag)
        this.nodes.push(newNode)
        this.nodes = this.sortByLevel(this.nodes)
        // Putting new sort number
        this.nodes.forEach((node, index) => {
          newNode = this.generateNewNode(node, node.level, (index + 1).toString(), node.nodeTag)
          newNodeArr.push(newNode)
        })

        this.nodes = this.sortIndex(newNodeArr)
        let saveformData = {
          adminId: this.adminId,
          accountId: this.accountId,
          scenario: this.scenario,
          nodes: this.nodes,
        }
        this.$emit('saveformData', saveformData)
      } else if (evt.moved) {
        // Change sort
        let newSort = evt.moved.newIndex + 1
        let newNode = {}
        let newNodeArr = []
        this.nodes.forEach((node, index) => {
          // Changing Items, change sort and add
          if (index + 1 >= newSort - 1) {
            newNode = this.generateNewNode(node, node.level, (index + 1).toString(), node.nodeTag)
            newNodeArr.push(newNode)
          }
          // None Changing Items, directly add to array
          else {
            newNodeArr.push(node)
          }
        })
        this.nodes = newNodeArr
        let saveformData = {
          adminId: this.adminId,
          accountId: this.accountId,
          scenario: this.scenario,
          nodes: this.nodes,
        }
        this.$emit('saveformData', saveformData)
      }
    },
    generateNewNode(source, level, sort, tags) {
      return {
        level: level,
        nextNode: source.nextNode,
        nextScenario: source.nextScenario,
        nodeId: source.nodeId,
        nodeTag: tags,
        sort: sort,
        template: source.template,
        title: source.title,
        type: source.type,
        condition_config: source.condition_config,
        reservations_config: source.reservations_config,
        subtitle: source.subtitle,
        flex_config: source.flex_config,
        userAction: source.userAction,
        error: source.error,
        nodeConst: source.nodeConst,
        externalApi: source.externalApi,
        variable: source.variable,
        varForms: source.varForms,
        datetimeTrigger: source.datetimeTrigger,
      }
    },
    // Init new name
    // Example: ND00001
    getNewNodeName() {
      let totalNode = this.nodes.length
      let defaultName = (Number(totalNode) + 1).toString()
      return 'ND' + defaultName.padStart(5, '0')
    },
    // Re render modal component
    forceRerender() {
      this.componentKey += 1
    },
    // Append icon of content in node
    nodeIcon(node) {
      let icon = ''
      if (
        node.condition_config != null &&
        node.condition_config.options != null &&
        node.condition_config.options.length > 0
      ) {
        icon = icon + '<span class="px-1"><i class="glyph-icon flaticon-algorithm"></i></span>'
      } else if (node.type == 'input') {
        if ('hero' in node.template) {
          icon = icon + '<span class="px-1"><i class="fas fa-images"></i></span>'
        }

        let flex = []
        if ('body' in node.template) {
          flex = node.template.body.contents
        }

        if (flex.length > 0) {
          flex.forEach((item) => {
            // if (item.type == "image") {
            //   icon =
            //     icon +
            //     '<span class="px-1"><i class="fas fa-images"></i></span>';
            // }
            if (item.type == 'text' && item.size == 'md') {
              icon = icon + '<span class="px-1"><i class="fas fa-font"></i></span>'
            }
            if (item.type == 'text' && item.size == 'xl') {
              icon = icon + '<span class="px-1"><i class="fas fa-bold"></i></span>'
            }
            if (item.type == 'text' && item.size == 'lg') {
              icon = icon + '<span class="px-1"><i class="fas fa-bold"></i></span>'
            }
          })
        }
        if (node.template.footer) {
          icon = icon + '<span class="px-1"><i class="fas fa-link"></i></span>'
        }
      } else if (node.type == 'sticker') {
        icon = icon + '<span class="px-1"><i class="fas fa-smile"></i></span>'
      } else if (node.type == 'flex') {
        icon = icon + '<span class="px-1"><i class="fas fa-code"></i></span>'
      } else if (node.type == 'image') {
        icon = icon + '<span class="px-1"><i class="fas fa-camera"></i></span>'
      } else if (node.type == 'imagemap') {
        icon = icon + '<span class="px-1"><i class="fas fa-image"></i></span>'
      } else if (node.type == 'fb_generic') {
        icon = icon + '<span class="px-1"><i class="fas fa-columns"></i></span>'
      }
      // reservation icon
      if (node.reservations_config != null && node.reservations_config.length > 0) {
        icon = icon + '<span class="px-1"><i class="fas fa-calendar-alt"></i></span>'
      }
      // quickReply icon
      if (node.template.quickReply) {
        icon = icon + '<span class="px-1"><i class="fas fa-reply-all"></i></span>'
      }
      // Next Scenario or Next Node
      if ((node.nextScenario != '') | (node.nextNode != '')) {
        icon = icon + '<span class="px-1"><i class="fas fa-chevron-circle-right"></i></span>'
      }
      // User Reply Variable icon
      if (node.userAction.userReply.variable) {
        let hasVariable = false
        for (var key in node.userAction.userReply.variable) {
          hasVariable = true
        }
        if (hasVariable) {
          icon = icon + '<span class="px-1"><i class="fas fa-user-plus"></i></span>'
        }
      }
      // Datetime trigger icon
      if (
        node.datetimeTrigger &&
        (node.datetimeTrigger.startTime != '' || node.datetimeTrigger.endTime != '')
      ) {
        icon = icon + '<span class="px-1"><i class="fas fa-calendar-check"></i></span>'
      }
      return icon
    },
    // Create new node
    createNode(level) {
      // 即時增加level 列數
      if (level == this.maxLevel + 1) {
        this.maxLevel += 1
      }
      this.forceRerender()
      this.clearNodeModal()
      this.nodeModal.nodeName = this.getNewNodeName() // init name
      this.nodeModal.tags = [{ name: 'Lv' + level, color: 'bg-danger' }]
      this.nodeModal.level = level
    },
    // Edit exist node
    editNode(node, copyFlag) {
      this.forceRerender()
      this.clearNodeModal()
      this.nodeModal.nextNode = node.nextNode
      this.nodeModal.nextScenario = node.nextScenario ? node.nextScenario : ''
      if (copyFlag == 1) {
        this.nodeModal.nodeId = ''
        this.nodeModal.nodeName = this.getNewNodeName() // init name
      } else {
        this.nodeModal.nodeId = node.nodeId
        this.nodeModal.nodeName = node.title
      }
      this.nodeModal.subtitle = node.subtitle ? node.subtitle : ''
      node.nodeTag.forEach((tag) => {
        this.nodeModal.tags.push({
          color: tag.color,
          name: tag.name,
        })
      })
      this.nodeModal.level = node.level
      this.nodeModal.condition_config = node.condition_config
      this.nodeModal.reservations_config = node.reservations_config
      this.nodeModal.type = node.type
      // Message type
      // 一般輸入
      if (node.type == 'input') {
        this.nodeModal.flexSize = node.template.size
        if ('hero' in node.template) {
          if ('title' in node.template.hero) {
            this.nodeModal.urlTitle = node.template.hero.title
          } else {
            this.nodeModal.urlTitle = node.title
          }
          this.nodeModal.urlPic = node.template.hero.url
          const ratios = node.template.hero.aspectRatio.split(':')
          this.nodeModal.picRatioX = ratios[0]
          this.nodeModal.picRatioY = ratios[1]
          if ('action' in node.template.hero) {
            this.nodeModal.urlPicTransition = node.template.hero.action.uri
          }
        }

        let flex = []
        if ('body' in node.template) {
          flex = node.template.body.contents
        }

        if (flex.length > 0) {
          flex.forEach((item) => {
            // if (item.type == "image") {
            //   this.nodeModal.urlPic = item.url;
            // }
            if (item.type == 'text' && item.size == 'md') {
              this.nodeModal.text = item.text
            }
            if (item.type == 'text' && item.size == 'xl') {
              this.nodeModal.boldText1 = item.text
            }
            if (item.type == 'text' && item.size == 'lg') {
              this.nodeModal.boldText2 = item.text
            }
          })
        }

        if (node.template.footer) {
          node.template.footer.contents.forEach((content) => {
            if (content.action.type == 'datetimepicker') {
              this.nodeModal.datetimepicker = content
            } else {
              this.nodeModal.buttonArea.push(content)
            }
          })
          // this.nodeModal.buttonArea = node.template.footer.contents;
          this.nodeModal.buttonStyleSelect = node.template.footer.layout
        }
      }
      // Sticker
      if (node.type == 'sticker') {
        this.nodeModal.sticker = node.template
      }
      // Image message
      if (node.type == 'image') {
        this.nodeModal.originalImageUrl = node.template.originalContentUrl
        this.nodeModal.linkUri = node.template.linkUri
      }
      // Image message for Fb
      if ('attachmentId' in node.template) {
        this.nodeModal.originalImageUrl = node.template.attachmentId
      }
      // Video message
      if (node.type == 'video') {
        this.nodeModal.originalVideoUrl = node.template.originalContentUrl
        this.nodeModal.originalImageUrl = node.template.previewImageUrl
        this.nodeModel.linkUri = node.template.linkUri
      }
      // Flex Message and title
      if (node.type == 'flex') {
        this.nodeModal.flexMessage = node.template
        this.nodeModal.flexMsgTitle = node.title
      }
      // Carousel Message
      if (node.type == 'carousel') {
        const parsedMsg = this.parseBackendMessageToCarouselMsg(node.template)
        this.nodeModal.carouselMsgTitle = node.title
        this.nodeModal.carouselMsg = parsedMsg
      }
      // Carousel Image Message
      if (node.type == 'carousel_image') {
        const parsedMsg = this.parseBackendMessageToCarouselImageMsg(node.template)
        this.nodeModal.carouselMsgTitle = node.title
        this.nodeModal.carouselImageMsg = parsedMsg
      }
      // Text Message
      if (node.type == 'text') {
        if (node.template.text) {
          this.nodeModal.textMsg.text = node.template.text
        }
        if (node.template.emojis) {
          this.nodeModal.textMsg.emojis = node.template.emojis
        }
      }
      // Quick reply
      if (node.template.quickReply && node.template.quickReply.items) {
        this.nodeModal.quickReply.items = node.template.quickReply.items
      }
      // Rich Message
      if (node.type == 'imagemap') {
        this.nodeModal.richMsg.layout = node.template.layout
        this.nodeModal.richMsg.title = node.template.altText
        this.nodeModal.richMsg.baseUrl = node.template.baseUrl
        this.nodeModal.richMsg.areaOptions.splice(0)
        if (node.template.areaOptions) {
          this.nodeModal.richMsg.areaOptions = node.template.areaOptions
        }
      }
      // Facebook Generic Template
      if (node.type == 'fb_generic') {
        this.nodeModal.fbGenericElements = node.template.elements
      }
      // Flex config
      if (node.flex_config) {
        this.nodeModal.flexMsgConfigs = node.flex_config
      }
      // User Reply and Remove Variable
      if (node.userAction) {
        this.nodeModal.userAction.userReply = node.userAction.userReply
        this.nodeModal.userAction.emptyVariable = node.userAction.emptyVariable
      }
      // Node Constant
      if (node.nodeConst) {
        this.nodeModal.constant = node.nodeConst
      }
      // External Api enable
      if (node.externalApi) {
        this.nodeModal.externalApi = node.externalApi
        // If data of cros.responseSetting.failed is old format, set the data as default.
        if (
          node.externalApi.cros &&
          'nextNodeId' in node.externalApi.cros.responseSetting.failed &&
          'scenarioId' in node.externalApi.cros.responseSetting.failed
        ) {
          this.nodeModal.externalApi.cros.responseSetting.failed = {
            default: {
              nextNodeId: node.externalApi.cros.responseSetting.failed.nextNodeId,
              scenarioId: node.externalApi.cros.responseSetting.failed.scenarioId,
            },
          }
        }
      }
      // Facebook Tag type
      if (node.fb_tag && node.fb_tag != '') {
        this.nodeModal['fbTag'] = node.fb_tag
      } else {
        delete this.nodeModal['fbTag']
      }
      // varForms
      if (node.varForms) {
        this.nodeModal.varForms = node.varForms
      }
      // datetimeTrigger
      if (node.datetimeTrigger) {
        this.nodeModal.datetimeTrigger = node.datetimeTrigger
      }
    },
    // Delete node
    deleteNode(target) {
      var self = this
      this.$fire({
        title: this.$t('__Areyousureyouwanttodeleteit'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          // Sort level again to make sure sort content number currect
          let nodes = this.sortByLevel(this.nodes)
          let newNodeArr = []
          let newNode = {}
          let sortCount = 1
          $.each(nodes, function (key, node) {
            if (node.nodeId !== target.nodeId) {
              // Resorting
              newNode = self.generateNewNode(node, node.level, sortCount.toString(), node.nodeTag)
              sortCount = sortCount + 1
              newNodeArr.push(newNode)
            }
          })
          this.nodes = this.sortIndex(newNodeArr)
          let saveformData = {
            adminId: this.adminId,
            accountId: this.accountId,
            scenario: this.scenario,
            nodes: this.nodes,
          }
          this.$emit('saveformData', saveformData)
        }
      })
    },

    parseBackendMessageToCarouselMsg(backendMessage) {
      if (!backendMessage.contents || !Array.isArray(backendMessage.contents)) {
        throw new Error('Invalid backend message format')
      }

      return backendMessage.contents.map((bubble) => {
        const hero = bubble.hero || {}
        const body = bubble.body || []
        const footer = bubble.footer || []

        return {
          image: { url: hero.url, aspectRatio: hero.aspect_ratio },
          title: { text: body[0].text, color: body[0].color },
          subtitle: { text: body[1].text, color: body[1].color },
          buttons: footer.map((button) => ({
            actionType: button.action.type === 'message' ? 'text' : 'uri',
            value: button.action.type === 'message' ? button.action.text : button.action.link_uri,
            label: button.text,
            color: button.color,
            fontColor: button.font_color,
          })),
        }
      })
    },
    parseBackendMessageToCarouselImageMsg(backendMessage) {
      if (!backendMessage.contents || !Array.isArray(backendMessage.contents)) {
        throw new Error('Invalid backend message format')
      }

      return backendMessage.contents.map((bubble) => {
        const hero = bubble.hero || {}

        return {
          image: {
            url: hero.url,
            aspectRatio: hero.aspect_ratio,
            linkUri: hero.link_uri,
          },
        }
      })
    },
    // Clear NodeModal
    clearNodeModal() {
      this.nodeModal.subtitle = ''
      this.nodeModal.totalNode = this.nodes
      this.nodeModal.nodeId = ''
      this.nodeModal.flexSize = ''
      this.nodeModal.bubbleSize = ['giga', 'mega', 'kilo', 'micro', 'nano']
      this.nodeModal.urlPic = ''
      this.nodeModal.urlPicTransition = ''
      this.nodeModal.picRatioX = ''
      this.nodeModal.picRatioY = ''
      this.nodeModal.text = ''
      this.nodeModal.textMsg = {
        text: '',
        emojis: [],
      }
      this.nodeModal.quickReply = {
        items: [],
      }
      this.nodeModal.boldText1 = ''
      this.nodeModal.boldText2 = ''
      this.nodeModal.buttonArea = []
      this.nodeModal.buttonStyleSelect = 'horizontal'
      this.nodeModal.sticker = null
      this.nodeModal.flexMsgTitle = ''
      this.nodeModal.originalImageUrl = ''
      this.nodeModal.originalVideoUrl = ''
      this.nodeModal.flexMessage = null
      this.nodeModal.flexMsgConfigs = []
      this.nodeModal.nextNode = ''
      this.nodeModal.nextScenario = ''
      this.nodeModal.tags.splice(0)
      this.nodeModal.condition_config = ''
      this.nodeModal.carouselMsg = [
        {
          image: {
            url: '',
            aspectRatio: '16:9',
          },
          title: { text: '', color: '#000000' },
          subtitle: { text: '', color: '#000000' },
          buttons: [
            {
              actionType: 'uri',
              value: '',
              label: '',
              color: '#000000',
              fontColor: '#FFFFFF',
            },
          ],
        },
      ]
      this.nodeModal.carouselImageMsg = [
        {
          image: {
            url: '',
            aspectRatio: '1:1',
            linkUri: '',
          },
        },
      ]
      this.nodeModal.richMsg = {
        title: '',
        layout: 'A',
        baseUrl: '',
        areaOptions: [
          {
            type: 'message',
            label: '',
            url: '',
            text: '',
          },
        ],
      }
      this.nodeModal.constant = {
        ecsite: false,
        chichatcatBox: false,
      }
      this.nodeModal.userAction = {
        userReply: {
          selectCompare: '',
          lensCompare: [
            {
              value: '<',
              label: 'lessThan',
            },
            {
              value: '>',
              label: 'moreThan',
            },
            {
              value: '=',
              label: 'equal',
            },
          ],
          variable: {},
          validate: {
            inputList: [
              {
                type: null,
                value: '',
              },
            ],
            success: {
              nextNodeId: '',
              scenarioId: '',
            },
            failed: {
              nextNodeId: '',
              scenarioId: '',
            },
          },
          encryption: false,
        },
        emptyVariable: {
          variable: {},
        },
      }
      this.nodeModal.datetimepicker = {
        color: '#00b900',
        style: 'primary',
        action: {
          type: 'datetimepicker',
          label: '',
          data: '',
          mode: 'datetime',
          initial: '',
          max: '',
          min: '',
        },
        tag: {
          color: '',
          name: '',
        },
        tagValue: '',
        userVars: [],
        varName: '',
        nextNode: '',
        nextScenario: '',
      }
      this.nodeModal.externalApi = {
        cros: {
          enable: false,
          responseSetting: {
            success: {
              nextNodeId: '',
              scenarioId: '',
            },
            failed: {},
          },
          others: {
            orderIdReplace: false,
          },
        },
      }
      this.nodeModal.fbGenericElements = []
      this.nodeModal.varForms = []
      this.nodeModal.datetimeTrigger = {
        enable: false,
        startTime: '',
        endTime: '',
      }
    },
  },
}
</script>
<style>
.ghost {
  opacity: 0.8;
  background: #c8ebfb;
}
.sortable-ghost {
  background: #c8ebfb !important;
}
#myKanban {
  width: max-content;
}
</style>
