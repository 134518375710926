<template>
  <div class="border p-2 mb-2">
    <label for=""
      ><b>{{ this.$t('__userlist_userBlock') }}</b></label
    >
    <div>
      <a
        href="javascript:void(0)"
        @click="deleteCondition"
        class="text-danger float-right userlist-delete-icon-a"
      >
        <svg
          class="svg-inline--fa fa-trash text-danger userlist-delete-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="trash"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
          ></path></svg
        ><!-- <i class="fas fa-trash text-danger"></i> Font Awesome fontawesome.com -->
      </a>
    </div>
    <form>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">{{
          this.$t('__userlist_matchType')
        }}</label>
        <div class="col-sm-10">
          <select class="custom-select" v-model="is_blocked" @change="swStatus()">
            <option value="1">{{ this.$t('__userlist_userBlock') }}</option>
            <option value="0">{{ this.$t('__userlist_userBlockWhite') }}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: 'FilterUserBlock',
  props: {
    condition: Object,
  },

  data() {
    return {
      matchType: 'match_any',
      is_blocked: '1',
    }
  },
  mounted() {
    this.condition.filters.is_blocked = this.is_blocked
  },
  methods: {
    swStatus() {
      this.condition.filters.is_blocked = this.is_blocked
    },
    deleteCondition() {
      this.$emit('deleteCondition', this.condition)
    },
  },
}
</script>
<style></style>
