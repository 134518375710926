import CreatePushModal from './CreatePush.vue'
import CreateSegmentModal from './CreateSegment.vue'
import QrCodeModal from './QrCodeModal.vue'

let modals = {
  CreatePushModal,
  CreateSegmentModal,
  QrCodeModal,
}

export default modals

export { CreatePushModal, CreateSegmentModal, QrCodeModal }
