<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>#</th>
      <th>
        <input
          class="form-check-input select-user"
          type="checkbox"
          value=""
          v-model="isCheckAll"
          @change="checkAll($this)"
        />
        ALL
      </th>
      <th style="cursor: pointer" @click="onClickUserNameColumnHeader">
        {{ this.$t('__username') }} {{ getSortOrderLabel(userNameColumnSortState) }}
      </th>
      <th v-if="accountType == 'ig'">IG ID</th>
      <th v-if="accountType != 'ig'">Line/Wechat/Fb ID</th>
      <th>{{ this.$t('__userlist_userLast') }}</th>
      <th>{{ this.$t('__userstatuse') }}</th>
      <th>{{ this.$t('__Followtime') }}</th>
      <th></th>
      <th v-if="accountType == 'ig'"></th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td>{{ index + 1 }}</td>
          <td>
            <input
              class="form-check-input select-user"
              type="checkbox"
              value=""
              v-model="item.isChecked"
            />
          </td>
          <td>
            <img :src="item.userPicture" width="30" />
            <span class="px-2">
              <a class="absoluteit">
                {{ item.userName }}
              </a>
            </span>
          </td>
          <td>{{ item.userId }}</td>
          <td>{{ toFrontendTimeFormat(item.lastaccesstime) }}</td>
          <td v-html="accountStatus(item.accountStatus)"></td>

          <td>{{ toFrontendTimeFormat(item.followTime) }}</td>
          <td>
            <svg
              @click="viewInfo(data, item)"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-eye-fill"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
              <path
                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"
              />
            </svg>
            <div
              class="filter-container view-info"
              style="width: 800px"
              v-bind:class="[{ dnone: item.isShowInfo }]"
            >
              <div class="card card-border" style="height: 400px">
                <div class="card-body" style="overflow: auto">
                  <div class="container">
                    <div v-if="isInfoLoading == true" style="text-align: center">
                      <img
                        width="40"
                        height="40"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABI9JREFUaEPtmc2rVlUUhx+1TMo+IEGJggaSAyeCUNEgAhEcKDgIo5o0KRqW/QHixJGWQ6lJTYo+BkEOBBFqEiQIDXRgRAVFNKiUPjW1eh/YO/Y97nPO2uf1vSDdBS/n3vvuvdb6rfVba6997ipuclm1QP//KXQvzM7CFAMrACLsaM3AXcDzwC5g54iBlgysAdYBl4BrEcfzmlYAjwEfpM0vA+8OGGsBcAewGvgb+H2RANQtAIH8AjycnjWbUQC3puir449FZ0AjDwCfJY/fmBXrgZ6IRQDIAKPvU+oIoElaKZSVvwL4UR4Bvq1YjQC4DVib9kodKdQkUwFYzKcBn58CT04AIOeNvvIXcLnJ87R4KgC3PwW8lvQIQCCljGXgdsDu4zqjX64PY6kBuHNGj1+DGk4CWxOFpFIUgI4LQLF1XgnYc891LbYG4DBwH3AEODOiuGyrFrNFnWUoA61t06C650KXal0ADwFvF058POP5q8D3A0CkkXTqttU+ABatxauMtU0Pt7sT1XK2fi59qWVge2qNZkGRTu+kT41atlWpZEF7sHnAKTUAZdu8CvzZExjPBvVloBmsQVrSqYaK+Jk0Npg+xSxIkY8qRm2pjhfSqFvM3eU6dUtyvts27Uw6nuvDvXYnHa/WyVgX0nlnH8FksS5eD9THAOuqX8lx7QlCsWDN+ODhNgYgW7I2jLL0ymImrI9ox+oDZEbuKXie56HQwRYFkI0/MUvn/tSl/JsgDraGulhva9xY/G60DUh4Im0FkG29ADwNPDvSoSLYpI3dRp43n8ZTAUQcW5Y1KwCWJcwDRnIGar19zLc9Ywsavi+7W8M2zqwAaAnXwNq5M3CD/Fh+Nf/bLrQN2J1GieaLeCdP9wPrgW/S5aYpja0ZcHTeN7szOBspTp5vNVlcutip9NE0nTpef5c+/hySKADHWyO+o9D6RZqFfM4jDwJmQTCKV0yz8UNEaQSATut8ntF/Ao4H5v6I/bxG5zcDm4pNF4Evgd+GFA0BkCbPAfcWCnT8VGVGlwZG8gSg4SHRyS0pAN1LirUgEMfrLGZCIFVa1QDosI5nnqvoc+A9wOh3xUnypTRRuu7DAe/NYqbhV8C5nrUbEhB1K7k+pNYS6QLQ+UPFCt+46fgQz71KmgHlaJEBR+4s3uCyGP0cnFo28zppZW2U9fHj7CXa2RJBLQPevNz0foDnptroK77B8JOlD4AXdrPg04yO3aHNgvSUejoviP+kj0K+LYj0d6mmcjuH0fc5BsDvbceeJYoAatQs/fRn6+O6go50oa6i/LuOC0CR9/K/lL4M5DW+FJOyBkoqTZJ5AEgdKWSXOFaxPgZA5wWhWGPnpyCYCsDLvR/lzXTwdO2PAXC9NJJOtlOzEHlHusTOFADRthkBYFt9PBW0Ha9Lw9GkTAGwN0XOgpU6fQdXBIAOlm31k4F/WVXBtAKwlb2YNHXb5hQKucd2ahbMRqStzkUhi9YM+DT6ZducCsB9BsZ6+Lq1mFszkJ3M/9Md4miUQlmHmViWIh4trLSgFUBU71wUajFy0wNoATt57b/vzeQxioQtCwAAAABJRU5ErkJggg=="
                      />
                    </div>
                    <div v-if="isInfoLoading == false" class="row">
                      <div class="col-sm">
                        <h3>{{ $t('__label') }}</h3>
                        <div v-if="item.tags.length <= 0">目前尚無標籤</div>
                        <table v-if="item.tags.length > 0" class="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">{{ $t('__Labelname') }}</th>
                              <th scope="col">{{ $t('__TagColor') }}</th>
                              <th scope="col">{{ $t('__value') }}</th>
                              <th scope="col">{{ $t('__userlist_delete') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(tag, tindex) in item.tags">
                              <td>{{ tag.name }}</td>
                              <td>
                                <div
                                  href="javascript:void(0)"
                                  class="colorsquare"
                                  :class="tag.color"
                                >
                                  &nbsp;
                                </div>
                              </td>
                              <td>{{ tag.value }}</td>
                              <td>
                                <a
                                  href="javascript:void(0)"
                                  @click="deleteUserOneTag($event)"
                                  class="text-danger"
                                  :tagname="tag.name"
                                  :userid="item.userId"
                                >
                                  <svg
                                    class="svg-inline--fa fa-trash text-danger userlist-delete-icon"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="trash"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                                    ></path></svg
                                  ><!-- <i class="fas fa-trash text-danger"></i> Font Awesome fontawesome.com -->
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-sm">
                        <h3>{{ $t('__Userreply') }}</h3>
                        <div v-if="item.variables.length <= 0">目前尚無使用者回覆</div>
                        <table v-if="item.variables.length > 0" class="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">{{ $t('__Variablename') }}</th>
                              <th scope="col">{{ $t('__value') }}</th>
                              <th scope="col">{{ $t('__userlist_lastUpdateTime') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(variable, tindex) in item.variables">
                              <td>{{ variable.name }}</td>
                              <td>{{ variable.value }}</td>
                              <td>{{ toFrontendTimeFormat(variable.updated_time) }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <button
                        type="button"
                        @click="viewInfo(data, item)"
                        data-dismiss="modal"
                        aria-label="Close"
                        class="close closeposition"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td v-if="accountType == 'ig'">
            <button
              v-if="item.is_blocked == false"
              @click="blockUser(data, item, '1')"
              class="btn btn-secondary ml-auto mr-3"
            >
              {{ $t('__userlist_userBlock') }}
            </button>

            <button
              v-if="item.is_blocked == true"
              @click="blockUser(data, item, '0')"
              type="button"
              class="btn btn-outline-secondary"
            >
              {{ $t('__userlist_unblock') }}
            </button>
          </td>
        </slot>
      </tr>
    </tbody>
    <span class="dnone">{{ this.componentKey }}</span
    ><!--for UI fresh-->
  </table>
</template>
<script>
import { apiFunction as rootApiFunction } from '@/Axios/functions'

export default {
  name: 'userList-table',
  props: {
    columns: Array,
    data: Array,
    accountType: String,
    adminId: String,
    accountId: String,
    jwtToken: String,
    isAll: String,
    type: {
      type: String, // striped | hover
      default: 'striped',
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  mounted() {
    console.log('table mounted')
  },
  computed: {
    tableClass() {
      return `table-${this.type}`
    },
  },
  watch: {
    isAll: {
      handler: function (val, oldval) {
        if (val == 'false') this.isCheckAll = false
      },
    },
  },
  data() {
    return {
      // テーブルのソート状態（none, asc, desc）
      userNameColumnSortState: 'none',
      componentKey: 0,
      isCheckAll: false,
      isInfoLoading: false,
    }
  },
  methods: {
    // Example: 2020-06-29 09:10
    toFrontendTimeFormat(dateTime) {
      if (dateTime == undefined || dateTime == '') return ''
      return this.$dateTime.toFrontEndTimeFormat(dateTime)
    },
    // Status type: 0, 1
    accountStatus(status) {
      if (status === '0') {
        return '<span class="text-danger">' + this.$t('__blockade') + '</span>'
      }
      return '<span class="text-success">' + this.$t('__Active') + '</span>'
    },
    getSortOrderLabel(order) {
      if (order == 'none') return ''
      return order == 'asc' ? '▲' : '▼'
    },
    onClickUserNameColumnHeader() {
      this.userNameColumnSortState = this.userNameColumnSortState == 'asc' ? 'desc' : 'asc'
      this.sortTableByUserNameColumn()
    },
    sortTableByUserNameColumn() {
      if (this.userNameColumnSortState == 'none') return
      const ascFunc = (a, b) => {
        if (a.userName < b.userName) return -1
        if (a.userName > b.userName) return 1
        return 0
      }
      const descFunc = (a, b) => {
        if (a.userName > b.userName) return -1
        if (a.userName < b.userName) return 1
        return 0
      }
      this.data.sort(this.userNameColumnSortState == 'asc' ? ascFunc : descFunc)
    },
    async viewInfo(data, item) {
      // refresh page
      this.componentKey++
      this.userName += ''

      // close all other variable/tags tooltip
      data.forEach(function (itm) {
        if (item.userId == itm.userId) return
        itm.isShowInfo = true
      })

      // switch tooltip
      item.isShowInfo = !item.isShowInfo
      if (item.isShowInfo == true) return

      // query data
      let formData = { adminId: this.adminId, accountId: this.accountId, userId: item.userId }

      this.isInfoLoading = true
      await rootApiFunction.getUser(formData, this.jwtToken).then((data) => {
        this.isInfoLoading = false
        if (
          data.data == null ||
          data.data.Result == null ||
          data.data.Result.data == null ||
          data.data.Result.data.length <= 0
        ) {
          return
        }
        if (data.data.Result.data.tagsaccesstime == null) return

        let tagsaccesstime = JSON.parse(data.data.Result.data.tagsaccesstime)

        let tags = []
        for (const [key, tagcontent] of Object.entries(tagsaccesstime)) {
          if (tagcontent == null) continue
          tags.push({
            name: key,
            color: tagcontent.color,
            value: tagcontent.tagvalue,
          })
        }
        item.tags = tags

        let saved_variable = JSON.parse(data.data.Result.data.saved_variable)
        let variables = []
        for (const [key, variable] of Object.entries(saved_variable)) {
          if (variable == null) continue
          variables.push({
            name: key,
            value: variable.value,
            updated_time: variable.updated_time,
          })
        }
        item.tags = tags
        item.variables = variables
        // refresh page
        this.componentKey++
        this.userName += ''

        return
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
        }
      })
    },
    async blockUser(data, item, sw) {
      // refresh page
      this.componentKey++
      this.userName += ''

      // close all other variable/tags tooltip
      data.forEach(function (itm) {
        if (item.userId == itm.userId) return
        itm.isShowInfo = true
      })

      // query data
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        userId: item.userId,
        sw: sw,
      }

      await rootApiFunction.blockUser(formData, this.jwtToken).then((data) => {
        item.is_blocked = sw == '1' ? true : false
        if (
          data.data == null ||
          data.data.Result == null ||
          data.data.Result.data == null ||
          data.data.Result.data.length <= 0
        ) {
          return
        }
        if (data.data.Result.data.tagsaccesstime == null) return

        let tagsaccesstime = JSON.parse(data.data.Result.data.tagsaccesstime)

        let tags = []
        for (const [key, tagcontent] of Object.entries(tagsaccesstime)) {
          if (tagcontent == null) continue
          tags.push({
            name: key,
            color: tagcontent.color,
            value: tagcontent.tagvalue,
          })
        }
        item.tags = tags

        // refresh page
        this.componentKey++
        this.userName += ''
      })
    },
    checkAll() {
      this.$emit('swCheckAll', this.isCheckAll)
      for (let i = 0; i < this.data.length; i++) {
        let d = this.data[i]
        d.isChecked = this.isCheckAll
      }
    },
    deleteUserOneTag(event) {
      if (event == null) return
      if (event.currentTarget == null) return
      let tagname = event.currentTarget.attributes['tagname'].textContent
      let userid = event.currentTarget.attributes['userid'].textContent

      let isAll = false
      let checkedUsers = [
        {
          userId: userid,
        },
      ]
      let tags = [
        {
          name: tagname,
        },
      ]
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        tags: tags,
        users: checkedUsers,
        isAll: isAll,
        conditionType: '',
        conditions: [],
      }
      rootApiFunction.deleteTagUser(formData, this.jwtToken).then((data) => {
        if (data && !data.error) {
          let item = this.data.find((d) => d.userId == userid)
          item.isShowInfo = !item.isShowInfo
          this.viewInfo(this.data, item)
        } else {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
  },
}
</script>
<style>
.filter-container {
  width: 38rem;
  position: absolute;
  margin-top: 10px;
}
.dnone {
  display: none;
}
.colorsquare {
  width: 24px;
  display: inline-block;
  height: 24px;
  border-radius: 6px;
}
.closeposition {
  position: absolute;
  right: 30px;
  top: 6px;
}
.view-info {
  margin-left: -750px;
  width: 800px;
}
.select-user {
  height: 18px;
  width: 18px;
}
</style>
