<template>
  <div class="border p-2 mb-2">
    <label for=""
      ><b>{{ this.$t('__userlist_userReply') }}</b></label
    >
    <div>
      <a
        href="javascript:void(0)"
        @click="deleteCondition"
        class="text-danger float-right userlist-delete-icon-a"
      >
        <svg
          class="svg-inline--fa fa-trash text-danger userlist-delete-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="trash"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
          ></path></svg
        ><!-- <i class="fas fa-trash text-danger"></i> Font Awesome fontawesome.com -->
      </a>
    </div>
    <form>
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <div class="form-group">
              <label for="exampleFormControlInput1">{{ this.$t('__userlist_matchType') }}</label>

              <select class="custom-select" v-model="matchType" @change="changeMatch">
                <option value="match_all">{{ this.$t('__ExactlyMatch') }}</option>
                <option value="match_any">{{ this.$t('__FuzzyMatch') }}</option>
                <option value="has_value">{{ this.$t('__userlist_hasValue') }}</option>
                <option value="not_exist">{{ this.$t('__userlist_notExist') }}</option>
              </select>
            </div>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <label for="exampleFormControlInput1">{{ this.$t('__variable') }}</label>
              <select class="custom-select" v-model="variable_name" @change="changeVariable()">
                <option
                  v-for="variable in condition.variables"
                  :value="variable.name"
                  :key="variable.name"
                >
                  {{ variable.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm">
            <div class="form-group">
              <label
                v-if="matchType == 'match_all' || matchType == 'match_any'"
                for="exampleFormControlInput1"
                >{{ this.$t('__value') }}</label
              >
              <input
                class="form-control"
                v-if="matchType == 'match_all' || matchType == 'match_any'"
                required
                type="text"
                v-model="variable_value"
                value=""
                @blur="changeVariableValue()"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: 'FilterUserReply',
  props: {
    condition: Object,
  },

  data() {
    return {
      matchType: 'match_any',
      variable_name: '',
      variable_value: '',
    }
  },
  methods: {
    select(variable, index) {
      if (this.condition.filters.variables == undefined) {
        this.condition.filters.variables = []
      }

      let findvariable = this.condition.filters.variables.find((t) => t.name == variable.name)
      if (findvariable == null) {
        if (variable.isChecked == true) {
          this.condition.filters.variables.push(variable)
        }
      } else {
        if (variable.isChecked == false) {
          // if checkbox is unchecked, and findTag is not null, remove it from condition
          this.condition.filters.variables = this.condition.filters.variables.filter(
            (t) => t.name != variable.name,
          )
        }
      }
    },
    changeMatch() {
      this.condition.matchType = this.matchType
    },
    changeVariable() {
      this.condition.filters.variable_name = this.variable_name
    },
    changeVariableValue() {
      this.condition.filters.variable_value = this.variable_value
    },
    deleteCondition() {
      this.$emit('deleteCondition', this.condition)
    },
  },
}
</script>
<style></style>
