var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar navbar-expand-lg navbar-light" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "navbar-brand", attrs: { href: "javascript:void(0)" } },
        [_vm._v(_vm._s(_vm.routeName))]
      ),
      _c(
        "button",
        {
          staticClass: "navbar-toggler navbar-burger",
          attrs: {
            type: "button",
            "aria-expanded": _vm.$sidebar.showSidebar,
            "aria-label": "Toggle navigation"
          },
          on: { click: _vm.toggleSidebar }
        },
        [
          _c("span", { staticClass: "navbar-toggler-bar" }),
          _c("span", { staticClass: "navbar-toggler-bar" }),
          _c("span", { staticClass: "navbar-toggler-bar" })
        ]
      ),
      _c("div", { staticClass: "collapse navbar-collapse" }, [
        _c("div", { staticClass: "ml-auto mr-0" }, [
          _c(
            "div",
            {
              staticClass: "search-container",
              staticStyle: {
                display: "inline-block",
                width: "40%",
                position: "relative"
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery"
                  }
                ],
                staticClass: "form-control mr-2",
                attrs: { type: "text", placeholder: _vm.$t("__AccountName") },
                domProps: { value: _vm.searchQuery },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchQuery = $event.target.value
                    },
                    _vm.debounceSearch
                  ]
                }
              }),
              _vm.searchQuery && _vm.showSearchResults
                ? _c("div", { staticClass: "search-results-modal" }, [
                    _c("div", { staticClass: "search-results-content" }, [
                      _vm.filteredAccounts.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.filteredAccounts, function(account) {
                              return _c(
                                "div",
                                {
                                  key: account.id,
                                  staticClass: "search-result-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.selectAccount(account)
                                    }
                                  }
                                },
                                [
                                  account.icon
                                    ? _c("img", {
                                        staticClass: "account-icon",
                                        attrs: { src: account.icon }
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(account.accountType) +
                                        " - " +
                                        _vm._s(account.accountName)
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        : _c("div", { staticClass: "no-results" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("__EmptyAccount")) +
                                "\n              "
                            )
                          ])
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm.chineselang == true
            ? _c(
                "a",
                {
                  staticClass: "nav-item ml-5",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.switchJa()
                    }
                  }
                },
                [_vm._v("日本語に切り替える")]
              )
            : _vm._e(),
          _vm.chineselang == false
            ? _c(
                "a",
                {
                  staticClass: "nav-item ml-2",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.switchTw()
                    }
                  }
                },
                [_vm._v("切換中文")]
              )
            : _vm._e()
        ]),
        _c("ul", { staticClass: "navbar-nav" }, [
          _c("li", { staticClass: "nav-item" }, [
            _vm.isAdmin && !_vm.isAccToken()
              ? _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.logOut()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("__Signout")))]
                )
              : _vm._e()
          ]),
          _c("li", { key: _vm.renderKey, staticClass: "nav-item dropdown" }, [
            _vm.isLogIn()
              ? _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: {
                      href: "#",
                      id: "navbarDropdownMenuLink",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.profileImg, width: "25px" }
                    }),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.accountName) +
                        "\n          "
                    )
                  ]
                )
              : _vm._e(),
            _vm.isLogIn()
              ? _c(
                  "div",
                  {
                    staticClass: "dropdown-menu",
                    attrs: { "aria-labelledby": "navbarDropdownMenuLink" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.changeAccount()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("__Switchaccount")))]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.logOut()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("__Signout")))]
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }