/*

 =========================================================
 * ChatAnnie - v1.0.0 
 =========================================================

 * Product Page: http://www.creative-tim.com/product/paper-dashboard
 * Copyright 2020 Asnet

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import App from './App'
import router from './router/index'
import i18n from '@/i18n'

import PaperDashboard from './plugins/paperDashboard'

import 'vue-notifyjs/themes/default.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { dom } from '@fortawesome/fontawesome-svg-core'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSimpleAlert from 'vue-simple-alert'
import VueCrypt from 'vue-crypt'
import Pagination from 'vue-pagination-2'
import { MdRadio } from 'vue-material/dist/components'
import VueCookie from 'vue-cookie'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsmobile from './aws-exports'
Amplify.configure(awsmobile)

Vue.use(MdRadio)

Vue.component('pagination', Pagination)
Vue.use(VueCrypt)
Vue.use(VueSimpleAlert)
Vue.use(VueCookie)
Vue.use(AmplifyPlugin, AmplifyModules)

// Custom scripts for all pages
// import "./assets/js/sb-admin.js";

Vue.use(VueAxios, axios)
dom.watch()
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(PaperDashboard)

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
