var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade bd-example-modal",
      attrs: {
        id: "create-edit-rich-menu-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              !_vm.editMode
                ? _c("p", { staticClass: "modal-title text-bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("__AddRichMenu")) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm.editMode
                ? _c("p", { staticClass: "modal-title text-bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("__EditRichMenu")) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                {
                  staticClass: "container-fluid",
                  staticStyle: { height: "60vh", overflow: "auto" }
                },
                [
                  _c("div", [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__requiredItem")))
                  ]),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(_vm._s(_vm.$t("__RichMenuname")))
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formInput.name,
                          expression: "formInput.name"
                        }
                      ],
                      staticClass: "form-control px-4 mx-3",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("__RichMenuname")
                      },
                      domProps: { value: _vm.formInput.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formInput, "name", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm.getAccountType() === "line"
                    ? _c("div", [
                        _c("label", { staticClass: "pt-3 mb-2" }, [
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v("*")
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("__RichMenuText")))
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formInput.chatBarText,
                                expression: "formInput.chatBarText"
                              }
                            ],
                            staticClass: "form-control px-4 mx-3",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("__RichMenuText")
                            },
                            domProps: { value: _vm.formInput.chatBarText },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.formInput,
                                  "chatBarText",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__background")))
                  ]),
                  _c(
                    "div",
                    {
                      class: {
                        "layout-image-container-1": _vm.isImageClass1,
                        "layout-image-container-2": _vm.isImageClass2
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.imageInput.click()
                        }
                      }
                    },
                    [
                      _c("input", {
                        ref: "imageInput",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", accept: "image/*" },
                        on: {
                          change: function($event) {
                            return _vm.onImageChanged($event)
                          }
                        }
                      }),
                      _vm.formInput.imageB64 != ""
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.formInput.imageB64 }
                          })
                        : _vm._e(),
                      _vm.formInput.imageB64 == "" && !_vm.editMode
                        ? _c("span", { staticClass: "layout-image-hint" }, [
                            _vm._v(_vm._s(_vm.$t("__selectImage")))
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mt-1",
                      staticStyle: { "font-size": "11px" }
                    },
                    [_vm._v(_vm._s(_vm.imageSizeHint))]
                  ),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__richMsgLayout")))
                  ]),
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "container col-4",
                          on: {
                            click: function($event) {
                              return _vm.onLayoutChanged("A")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row layout-container",
                              class: {
                                "layout-selected": _vm.formInput.layout === "A"
                              }
                            },
                            _vm._l(["A", "B", "C", "D", "E", "F"], function(n) {
                              return _c(
                                "div",
                                {
                                  key: n,
                                  staticClass: "col-4 layout-unit",
                                  class: {
                                    "layout-selected":
                                      _vm.formInput.layout === "A"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "40%"
                                      }
                                    },
                                    [_vm._v(_vm._s(n))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "container col-4",
                          on: {
                            click: function($event) {
                              return _vm.onLayoutChanged("B")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row layout-container",
                              class: {
                                "layout-selected": _vm.formInput.layout === "B"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12 layout-unit",
                                  class: {
                                    "layout-selected":
                                      _vm.formInput.layout === "B"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "10%"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s("A") +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._l(["B", "C", "D"], function(n) {
                                return _c(
                                  "div",
                                  {
                                    key: n,
                                    staticClass: "col-4 layout-unit",
                                    class: {
                                      "layout-selected":
                                        _vm.formInput.layout === "B"
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "text-align": "center",
                                          "margin-top": "40%"
                                        }
                                      },
                                      [_vm._v(_vm._s(n))]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "container col-4",
                          on: {
                            click: function($event) {
                              return _vm.onLayoutChanged("C")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row layout-container",
                              class: {
                                "layout-selected": _vm.formInput.layout === "C"
                              }
                            },
                            _vm._l(["A", "B", "C"], function(n) {
                              return _c(
                                "div",
                                {
                                  key: n,
                                  staticClass: "col-4 layout-unit",
                                  class: {
                                    "layout-selected":
                                      _vm.formInput.layout === "C"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "40%"
                                      }
                                    },
                                    [_vm._v(_vm._s(n))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-4 mt-2",
                          on: {
                            click: function($event) {
                              return _vm.onLayoutChanged("D")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row layout-container",
                              class: {
                                "layout-selected": _vm.formInput.layout === "D"
                              }
                            },
                            _vm._l(["A", "B", "C", "D"], function(n) {
                              return _c(
                                "div",
                                {
                                  key: n,
                                  staticClass: "col-6 layout-unit",
                                  class: {
                                    "layout-selected":
                                      _vm.formInput.layout === "D"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "30%"
                                      }
                                    },
                                    [_vm._v(_vm._s(n))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm.getAccountType() === "line"
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.useTabCheck,
                              expression: "useTabCheck"
                            }
                          ],
                          attrs: { type: "checkbox", id: "useTabCheck" },
                          domProps: {
                            checked: Array.isArray(_vm.useTabCheck)
                              ? _vm._i(_vm.useTabCheck, null) > -1
                              : _vm.useTabCheck
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.useTabCheck,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.useTabCheck = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.useTabCheck = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.useTabCheck = $$c
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "ml-2",
                            attrs: { for: "useTabCheck" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "switch-txt",
                                attrs: { turnOn: "On", turnOff: "Off" }
                              },
                              [_vm._v(_vm._s(_vm.$t("__richMsgAliasLayout")))]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.useTabCheck == true && _vm.getAccountType() === "line"
                    ? _c(
                        "div",
                        _vm._l(_vm.formInput.tab_menu, function(item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "action-container mb-2"
                            },
                            [
                              _c("div", [
                                _c("label", { staticClass: "ml-2 mt-2" }, [
                                  _vm._v(_vm._s("Tab" + (index + 1)))
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "float-right mr-2 mt-2" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-danger float-right",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteTab(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-trash text-danger"
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "px-2 mt-2 mb-2" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.formInput.tab_menu[index].name,
                                          expression:
                                            "formInput.tab_menu[index].name"
                                        }
                                      ],
                                      staticClass: "col-5 ml-4",
                                      attrs: { name: "", id: "" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.formInput.tab_menu[index],
                                            "name",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "",
                                            disabled: "",
                                            selected: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("__switchMenuName")
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.$attrs.menuList, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: index,
                                            staticClass: "h-auto",
                                            domProps: { value: item.name }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(item.name) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]),
                                _vm.formInput.tab_menu[index].name
                                  ? _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-2 mt-2 mb-2 d-flex align-items-center"
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("__xAxis"))
                                                  )
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ].xAxis,
                                                    expression:
                                                      "formInput.tab_menu[index].xAxis"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control ml-2",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.tab_menu[
                                                      index
                                                    ].xAxis
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ],
                                                      "xAxis",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-2 mt-2 mb-2 d-flex align-items-center"
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("__yAxis"))
                                                  )
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ].yAxis,
                                                    expression:
                                                      "formInput.tab_menu[index].yAxis"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control ml-2",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.tab_menu[
                                                      index
                                                    ].yAxis
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ],
                                                      "yAxis",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]),
                                        _c("div", {}, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-2 mt-2 mb-2 d-flex align-items-center"
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("__tabWidth"))
                                                  )
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ].width,
                                                    expression:
                                                      "formInput.tab_menu[index].width"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control ml-2",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.tab_menu[
                                                      index
                                                    ].width
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ],
                                                      "width",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-2 mt-2 mb-2 d-flex align-items-center"
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("__tabHeight")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ].height,
                                                    expression:
                                                      "formInput.tab_menu[index].height"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control ml-2",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.tab_menu[
                                                      index
                                                    ].height
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput.tab_menu[
                                                        index
                                                      ],
                                                      "height",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.getAccountType() === "line"
                    ? _c(
                        "div",
                        {
                          staticClass: "row pt-4 add-btn-input cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.addNewTab()
                            }
                          }
                        },
                        [_vm._m(1)]
                      )
                    : _vm._e(),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__action")))
                  ]),
                  _vm._l(_vm.formInput.areaOptions, function(action, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "action-container mb-1" },
                      [
                        _c("label", { staticClass: "ml-3 mt-2" }, [
                          _vm._v(_vm._s(key))
                        ]),
                        _c(
                          "div",
                          { staticClass: "row px-4 mt-2" },
                          [
                            _c("md-radio", {
                              staticClass: "ml-3",
                              attrs: { type: "radio", value: "text" },
                              model: {
                                value: action.type,
                                callback: function($$v) {
                                  _vm.$set(action, "type", $$v)
                                },
                                expression: "action.type"
                              }
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: action.text,
                                  expression: "action.text"
                                }
                              ],
                              staticClass: "form-control col-10 ml-0",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("__Textcontent"),
                                id: "text-" + key
                              },
                              domProps: { value: action.text },
                              on: {
                                focus: function($event) {
                                  return _vm.onActionTypeChanged($event, key)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(action, "text", $event.target.value)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "row px-4 mt-2" },
                          [
                            _c("md-radio", {
                              staticClass: "ml-3",
                              attrs: { type: "radio", value: "uri" },
                              model: {
                                value: action.type,
                                callback: function($$v) {
                                  _vm.$set(action, "type", $$v)
                                },
                                expression: "action.type"
                              }
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: action.url,
                                  expression: "action.url"
                                }
                              ],
                              staticClass: "form-control col-10 ml-0",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("__urllinkoptional"),
                                id: "uri-" + key
                              },
                              domProps: { value: action.url },
                              on: {
                                focus: function($event) {
                                  return _vm.onActionTypeChanged($event, key)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(action, "url", $event.target.value)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _vm.getAccountType() === "line"
                    ? _c("div", { staticClass: "pt-4" }, [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formInput.use_datetime_trigger,
                                expression: "formInput.use_datetime_trigger"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { id: "useDateTime", type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.formInput.use_datetime_trigger
                              )
                                ? _vm._i(
                                    _vm.formInput.use_datetime_trigger,
                                    null
                                  ) > -1
                                : _vm.formInput.use_datetime_trigger
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.formInput.use_datetime_trigger,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formInput,
                                        "use_datetime_trigger",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formInput,
                                        "use_datetime_trigger",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.formInput,
                                    "use_datetime_trigger",
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "useDateTime" }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("__DateTimeTriggerEnable")) +
                                  "\n              "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "form-row px-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-6" },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("__DateTimeTriggerStart")))
                              ]),
                              _c("datetime", {
                                attrs: {
                                  type: "datetime",
                                  "minute-step": 5,
                                  "min-datetime": _vm.currentTime,
                                  "input-class": "form-control col-12",
                                  "input-style": "width: 16rem;"
                                },
                                model: {
                                  value: _vm.formInput.start_time,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formInput, "start_time", $$v)
                                  },
                                  expression: "formInput.start_time"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group col-6" },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.$t("__DateTimeTriggerEnd")))
                              ]),
                              _c("datetime", {
                                attrs: {
                                  type: "datetime",
                                  "minute-step": 5,
                                  "min-datetime": _vm.currentTime,
                                  "input-class": "form-control col-12",
                                  "input-style": "width: 16rem;"
                                },
                                model: {
                                  value: _vm.formInput.end_time,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formInput, "end_time", $$v)
                                  },
                                  expression: "formInput.end_time"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.getAccountType() === "line"
                    ? _c("div", { staticClass: "mt-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formInput.open_menu,
                              expression: "formInput.open_menu"
                            }
                          ],
                          attrs: { type: "checkbox", id: "switch" },
                          domProps: {
                            checked: Array.isArray(_vm.formInput.open_menu)
                              ? _vm._i(_vm.formInput.open_menu, null) > -1
                              : _vm.formInput.open_menu
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.formInput.open_menu,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formInput,
                                      "open_menu",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formInput,
                                      "open_menu",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.formInput, "open_menu", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          { staticClass: "ml-2", attrs: { for: "switch" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "switch-txt",
                                attrs: { turnOn: "On", turnOff: "Off" }
                              },
                              [_vm._v(_vm._s(_vm.$t("__open_menu")))]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.getAccountType() === "line"
                    ? _c("label", { staticClass: "pt-4 mb-2" }, [
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v("*")
                        ]),
                        _vm._v(" " + _vm._s(_vm.$t("__segment")))
                      ])
                    : _vm._e(),
                  _vm.getAccountType() === "line"
                    ? _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formInput.segmentId,
                                  expression: "formInput.segmentId"
                                }
                              ],
                              staticClass: "form-control col-5 ml-2",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.formInput,
                                    "segmentId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("__segment")) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _c(
                                "option",
                                { attrs: { value: "richAllUser" } },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("__richAllUser")) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _vm._l(_vm.segmentList, function(segment, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: segment.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(segment.name) +
                                        "\n                "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                2
              )
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              !_vm.editMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.submitRichMenu()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("__CreatenewRichMenu")) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.editMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.submitRichMenu()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("__saveRichMenu")) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }