export const RichMenuActions = {
  getActions: function (layout, areaOptions, containerWidth, containerHeight) {
    let actions
    switch (layout) {
      case 'A':
        actions = this.action_layoutA(areaOptions, containerWidth, containerHeight)
        break
      case 'B':
        actions = this.action_layoutB(areaOptions, containerWidth, containerHeight)
        break
      case 'C':
        actions = this.action_layoutC(areaOptions, containerWidth, containerHeight)
        break
      case 'D':
        actions = this.action_layoutD(areaOptions, containerWidth, containerHeight)
        break
      default:
        actions = []
    }

    return actions
  },

  action_layoutA: function (areaOptions, containerWidth, containerHeight) {
    // Calculate cell width and height (3x2 grid)
    const cellWidth = containerWidth / 3 // 3 columns
    const cellHeight = containerHeight / 2 // 2 rows

    // Map each option to its corresponding area in the grid
    return areaOptions.map((option, index) => {
      const col = index % 3
      const row = Math.floor(index / 3)

      let layout = {
        type: option.type,
        x: Math.round(col * cellWidth),
        y: Math.round(row * cellHeight),
        width: Math.round(cellWidth),
        height: Math.round(cellHeight),
      }
      if (option.type === 'uri') {
        layout.link_uri = option.data
      }
      if (option.type === 'text') {
        layout.text = option.data
      }
      return layout
    })
  },
  action_layoutB: function (areaOptions, containerWidth, containerHeight) {
    // Calculate heights and widths
    const rowHeightA = containerHeight * 0.5 // A takes half the height
    const rowHeightBCD = containerHeight * 0.5 // B, C, D share the other half
    const colWidthBCD = containerWidth / 3 // Each of B, C, D takes one-third width

    return areaOptions.map((option, index) => {
      let layout
      if (index === 0) {
        // A occupies the first row
        layout = {
          type: option.type,
          x: 0,
          y: 0,
          width: Math.round(containerWidth),
          height: Math.round(rowHeightA),
        }
      }
      if (index >= 1 && index <= 3) {
        // B, C, D occupy the second row
        const col = index - 1 // Columns for B, C, D are 0, 1, 2
        layout = {
          type: option.type,
          x: Math.round(col * colWidthBCD),
          y: Math.round(rowHeightA),
          width: Math.round(colWidthBCD),
          height: Math.round(rowHeightBCD),
        }
      }

      if (option.type === 'uri') {
        layout.link_uri = option.data
      }
      if (option.type === 'text') {
        layout.text = option.data
      }
      return layout
    })
  },
  action_layoutC: function (areaOptions, containerWidth, containerHeight) {
    // Calculate the width of each section (A, B, C)
    const sectionWidth = containerWidth / 3 // 3 equal sections
    const sectionHeight = containerHeight // Full height for each section

    return areaOptions.map((option, index) => {
      let layout = {
        type: option.type,
        x: Math.round(index * sectionWidth),
        y: 0,
        width: Math.round(sectionWidth),
        height: Math.round(sectionHeight),
      }
      if (option.type === 'uri') {
        layout.link_uri = option.data
      }
      if (option.type === 'text') {
        layout.text = option.data
      }
      return layout
    })
  },
  action_layoutD: function (areaOptions, containerWidth, containerHeight) {
    // Calculate the width and height for each quadrant
    const quadrantWidth = containerWidth / 2 // Two columns
    const quadrantHeight = containerHeight / 2 // Two rows

    return areaOptions.map((option, index) => {
      const row = Math.floor(index / 2) // Row index (0 or 1)
      const col = index % 2 // Column index (0 or 1)

      let layout = {
        type: option.type,
        x: Math.round(col * quadrantWidth), // Horizontal position
        y: Math.round(row * quadrantHeight), // Vertical position
        width: Math.round(quadrantWidth),
        height: Math.round(quadrantHeight),
      }
      if (option.type === 'uri') {
        layout.link_uri = option.data
      }
      if (option.type === 'text') {
        layout.text = option.data
      }
      return layout
    })
  },
}
