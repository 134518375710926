<template>
  <div class="modal fade" id="qr-code-modal" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">LIFF LINK</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <qrcode-vue class="text-center" :value="modalData.liffUrl" size="200" level="H" />
          <div class="my-2">
            <label> LIFF URL</label>
            <span v-if="isSupportClipboardApi()" @click="onClickCopyText(modalData.liffUrl)">
              <i class="fas fa-copy cursor-pointer mx-2" />
            </span>
            <input class="form-control" v-model="modalData.liffUrl" disabled />
          </div>
          <div class="my-2">
            <label> {{ $t('__jumpToSpecificScenario') }}</label>
            <select class="form-control" v-model="modalData.scenarioId">
              <option value disabled selected>
                {{ $t('__NextScenariooptional') }}
              </option>
              <option
                :value="scenario.scenario.scenarioId"
                v-for="(scenario, index) in allScenario"
                :key="100 + index"
              >
                {{ scenario.scenario.scenarioName }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            style="width: 100%"
            @click="submitSegment()"
          >
            {{ this.$t('__determine') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: { QrcodeVue },
  name: 'qr-code-modal',
  props: {
    modalData: Object,
    allScenario: Array,
    updateSegment: Function,
  },
  data() {
    return {}
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    submitSegment() {
      this.updateSegment(this.modalData.segmentId, this.modalData.scenarioId)
      $('#qr-code-modal').modal('hide')
    },
    isSupportClipboardApi() {
      return navigator.clipboard != undefined
    },
    onClickCopyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$fire({
          text: this.$t('__CopiedToClipboard'),
          type: 'success',
        })
      })
    },
  },
}
</script>
