<template>
  <card class="main-content">
    <!-- content place -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">
        {{ $t('__webhookUrl') }}
      </div>
      <div class="col-9 my-2">
        <span>{{ webhookUrl }}</span>
        <span v-if="isSupportClipboardApi()" @click="onClickCopyText(webhookUrl)">
          <i class="fas fa-copy cursor-pointer mx-2" />
        </span>
      </div>
    </div>
    <!-- account id -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">
        {{ $t('__accountId') }}
      </div>
      <div class="col-9 my-2">
        <span>{{ accountId }}</span>
      </div>
    </div>
    <!-- account type -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">
        {{ $t('__ConnectionType') }}
      </div>
      <div class="col-2 my-2">
        <select class="custom-select" id="account-type" v-model="accountType" disabled>
          <!-- <option></option> -->
          <option value="line">{{ $t('__line') }}</option>
          <option value="wechat">{{ $t('__wechat') }}</option>
          <option value="fb">{{ $t('__facebook') }}</option>
          <option value="ig">{{ $t('__instagram') }}</option>
        </select>
      </div>
    </div>

    <!-- line -->
    <div class="" id="line-block" v-if="accountType == 'line'">
      <!-- app secret -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">{{ $t('__channelSecret_MsgAPI') }}</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-9" id="line-appsecret-title">{{ appSecret }}</p>
          <span id="line-appsecret-edit" @click="editSecret()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-9 element-hide"
            id="line-appsecret-input"
            v-model="appSecret"
            @keypress="confirmChangeSecret($event)"
          />
        </div>
      </div>

      <!-- app token -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">{{ $t('__channelAccessToken_MsgAPI') }}</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-9" id="line-apptoken-title">{{ appToken }}</p>
          <span id="line-apptoken-edit" @click="editToken()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-9 element-hide"
            id="line-apptoken-input"
            v-model="appToken"
            @keypress="confirmChangeToken($event)"
          />
        </div>
      </div>

      <!-- login channel id -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">{{ $t('__channelId_LineLogin') }}</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-9" id="line-loginchannelid-title">{{ loginChannelId }}</p>
          <span id="line-loginchannelid-edit" @click="editLoginChannelId()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-9 element-hide"
            id="line-loginchannelid-input"
            v-model="loginChannelId"
            @keypress="confirmChangeLoginChannelId($event)"
          />
        </div>
      </div>

      <!-- login secret -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">{{ $t('__channelSecret_LineLogin') }}</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-9" id="line-loginsecret-title">{{ loginSecret }}</p>
          <span id="line-loginsecret-edit" @click="editLoginSecret()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-9 element-hide"
            id="line-loginsecret-input"
            v-model="loginSecret"
            @keypress="confirmChangeLoginSecret($event)"
          />
        </div>
      </div>

      <!-- Liff Id -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">LIFF ID</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-9" id="line-liffid-title">{{ liffId }}</p>
        </div>
      </div>
    </div>

    <!-- wechat -->
    <div class="" id="wechat-block" v-if="accountType == 'wechat'">
      <!-- app id -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">appid</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-7" id="wechat-appid-title">{{ appId }}</p>
          <span id="wechat-appid-edit" @click="editId()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-8 element-hide"
            id="wechat-appid-input"
            v-model="appId"
            @keypress="confirmChangeId($event)"
          />
        </div>
      </div>
      <!-- app token -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">app token</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-7" id="wechat-apptoken-title">{{ appToken }}</p>
          <span id="wechat-apptoken-edit" @click="editToken()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-8 element-hide"
            id="wechat-apptoken-input"
            v-model="appToken"
            @keypress="confirmChangeToken($event)"
          />
        </div>
      </div>
      <!-- app secret -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">app secret</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-7" id="wechat-appsecret-title">{{ appSecret }}</p>
          <span id="wechat-appsecret-edit" @click="editSecret()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-8 element-hide"
            id="wechat-appsecret-input"
            v-model="appSecret"
            @keypress="confirmChangeSecret($event)"
          />
        </div>
      </div>
    </div>

    <!-- facebook / Instagram -->
    <div class="" id="facebook-block" v-if="accountType == 'fb' || accountType == 'ig'">
      <!-- app token -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">app token</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-7" id="facebook-apptoken-title">{{ appToken }}</p>
          <span id="facebook-apptoken-edit" @click="editToken()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-8 element-hide"
            id="facebook-apptoken-input"
            v-model="appToken"
            @keypress="confirmChangeToken($event)"
          />
        </div>
      </div>
      <!-- app secret -->
      <div class="row mt-4 my-2">
        <div class="col-3 my-2">app secret</div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-7" id="facebook-appsecret-title">
            {{ appSecret }}
          </p>
          <span id="facebook-appsecret-edit" @click="editSecret()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-8 element-hide"
            id="facebook-appsecret-input"
            v-model="appSecret"
            @keypress="confirmChangeSecret($event)"
          />
        </div>
      </div>
      <!-- Fb start message -->
      <div class="row mt-4 my-2" v-if="accountType == 'fb'">
        <div class="col-3 my-2">
          {{ $t('__FbStartMessage') }}
        </div>
        <div class="col-4 my-2">
          <select
            class="custom-select"
            id="fb-stat-msg-scenario"
            v-model="fbProfile.get_started.scenarioId"
            @change="onFbStartScenarioChanged()"
          >
            <option value disabled selected>
              {{ $t('__NextScenariooptional') }}
            </option>
            <option
              v-for="(scenario, index) in allScenario"
              :value="scenario.scenario.scenarioId"
              :key="100 + index"
            >
              {{ scenario.scenario.scenarioName }}
            </option>
          </select>
        </div>
      </div>
      <!-- Fb greeting msg -->
      <div class="row mt-4 my-2" v-if="accountType == 'fb'">
        <div class="col-3 my-2">
          {{ $t('__FbGreetingMessage') }}
        </div>
        <div class="row col-9 my-2">
          <p class="mb-2 col-7" id="facebook-greeting-title">
            {{ fbProfile.greeting.text }}
          </p>
          <span id="facebook-greeting-edit" @click="editFbGreetingMsg()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control mb-2 col-8 element-hide"
            id="facebook-greeting-input"
            v-model="fbProfile.greeting.text"
            @keypress="confirmChangeFbGreetingMsg($event)"
          />
        </div>
      </div>
    </div>

    <!-- image and name -->
    <div class="row mt-4 my-2">
      <div class="col-3 my-2">{{ $t('__changeAccountImage') }}</div>
      <div class="col-9 pb-4 my-1">
        <div class="border" style="min-height: 150px; max-width: 75%">
          <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            v-on:vdropzone-success="showSuccess"
            v-on:vdropzone-removed-file="removeFile"
          ></vue-dropzone>
        </div>
      </div>
    </div>

    <div class="row mt-4 my-2">
      <div class="col-3 my-2">{{ $t('__changeAccountName') }}</div>
      <div class="row col-9 my-2">
        <p class="mb-2 col-9" id="accountName-title">{{ accountName }}</p>
        <span id="accountName-appsecret" @click="editAccountName()">
          <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
        </span>
        <input
          type="text"
          class="form-control mb-2 col-9 element-hide"
          id="accountName-input"
          v-model="accountName"
          @keypress="confirmChangeToken($event)"
        />
      </div>
    </div>

    <div class="row mt-4 my-2">
      <div class="col-3 my-2" data-translate="_keepRecord.setting">
        {{ $t('__Keepsessionrecords') }}
        <i
          class="fas fa-question-circle tips tooltip-test"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('__Sessionrecord')"
        ></i>
      </div>
      <div class="col-2 my-2">
        <select class="custom-select">
          <option disabled>{{ $t('__1day') }}</option>
          <option disabled>{{ $t('__10days') }}</option>
          <option disabled>{{ $t('__30days') }}</option>
          <option disabled>{{ $t('__60days') }}</option>
          <option disabled>{{ $t('__90days') }}</option>
          <option selected>{{ $t('__180days') }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-4 my-2">
      <div class="col-3 my-2" data-translate>
        <span class="pt-2">{{ $t('__Deleteuserrelatedsettings') }}</span>
        <i
          class="fas fa-question-circle tips tooltip-test"
          data-toggle="tooltip"
          data-placement="top"
          :title="$t('__Clearallusagerecordsthesystemwillonly')"
        ></i>
      </div>
      <button class="btn btn-outline-danger px-4 ml-3" @click="deleteUserData()" data-translate>
        {{ $t('__Deleteuserrelatedsettings') }}
      </button>
    </div>
    <!-- TODO delete account -->
    <!-- content place -->
  </card>
</template>
<script>
import { apiFunction } from './Axios/functions'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from 'axios'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    const uploadText =
      "<div><i class='fa fa-cloud-upload-alt pr-2' style='font-size: 3.5rem;'></i><h4>" +
      this.$t('__Clickordragtoupload') +
      '</h4></div>'
    return {
      adminId: '',
      jwtToken: '',
      accountId: '',
      webhookUrl: '',
      accountType: '',
      appId: '',
      appSecret: '',
      appToken: '',
      loginChannelId: '',
      loginSecret: '',
      liffId: '',
      accountName: '',
      accountIcon: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 500,
        addRemoveLinks: true,
        uploadMultiple: false,
        maxFiles: 1,
        dictDefaultMessage: uploadText,
      },
      allScenario: [],
      fbProfile: {
        get_started: {
          scenarioId: '',
          payload: '',
        },
        greeting: {
          text: '',
        },
      },
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(',')
        this.adminId = decrypt[0]
        this.accountId = decrypt[1]
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }
      this.getAccountInfo()
      this.getAllScenario()
    },
    // Remove specified user data.
    // Impact table -
    // 1."chatbot_broadcast"
    // 2."chatbot_history"
    // 3."chatbot_userlist"
    // 4."chatbot_segment". For "chatbot_segment", only empty the "segmentusers" data.
    deleteUserData() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
      }

      apiFunction.deleteUserData(formData, this.jwtToken).then((data) => {
        if (data && data.status == 200) {
          this.$fire({
            title: this.$t('__successfullydeleted'),
            text: this.$t('__successfullydeleted'),
            type: 'success',
            timer: 1500,
          }).then((e) => {
            this.$router.push('/dashboard')
          })
        } else {
          this.$fire({
            title: this.$t('__failedtodelete'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
    // Get account info
    getAccountInfo() {
      let formData = {
        adminId: this.adminId,
      }
      apiFunction.getAccount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          let accounts = data.data.Result.accounts
          accounts.forEach((account) => {
            if (account.accountId == this.accountId) {
              this.webhookUrl = account.accountWebhookUrl
              this.accountType = account.accountType
              if ('appId' in account) {
                this.appId = account.appId
              }
              if ('liffId' in account) {
                this.liffId = account.liffId
              }
              this.appSecret = account.accountSecret
              this.appToken = account.accountToken
              this.accountName = account.accountName
              this.accountIcon = account.accountIcon
              this.loginChannelId = account.lineLoginChannelId
              this.loginSecret = account.lineLoginSecret
            }
          })
          if (this.accountType === 'fb' || this.accountType === 'ig') {
            this.getFbProfile()
          }
        }
      })
    },
    // Get all scenario
    getAllScenario() {
      // Get all scenario
      let formData = { adminId: this.adminId, accountId: this.accountId }
      apiFunction.getScenario(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          let storyArr = []
          data.data.Result.data.forEach((story) => {
            storyArr.push(story)
          })
          storyArr.sort((s1, s2) => {
            return s1.scenario.scenarioName.localeCompare(s2.scenario.scenarioName)
          })
          this.allScenario = storyArr
        }
      })
    },
    // Get FB messenger profile
    getFbProfile() {
      const params = new URLSearchParams()
      params.append('access_token', this.appToken)
      params.append('fields', 'get_started,greeting')
      this.axios({
        method: 'GET',
        url: 'https://graph.facebook.com/v15.0/me/messenger_profile',
        params: params,
      })
        .then((res) => {
          if (res.data.data) {
            res.data.data.forEach((item) => {
              if (item['get_started']) {
                let payload = item.get_started.payload
                this.fbProfile.get_started.payload = payload
                this.fbProfile.get_started.scenarioId = payload.split('&')[0].substr(11)
              }
              if (item['greeting']) {
                item['greeting'].forEach((greeting) => {
                  if (greeting['locale'] === 'default') {
                    this.fbProfile.greeting.text = greeting['text']
                  }
                })
              }
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getImgurAccessToken() {
      let client_id = '208d8a2829b83a4'
      let secret = '8acb5af7e2368314866e1c7300471509df22a712'

      let formData = new FormData()
      formData.append('refresh_token', 'ed342a5803077918e62e009d0964dd470fec010a') //required
      formData.append('client_id', client_id)
      formData.append('client_secret', secret)
      formData.append('grant_type', 'refresh_token')

      await this.axios({
        method: 'POST',
        url: 'https://api.imgur.com/oauth2/token',
        data: formData,
      })
        .then((res) => {
          localStorage.imgur = res.data.access_token
          // return (this.token = res.data.access_token);
        })
        .catch((e) => {
          console.log(e)
        })
    },
    // Imgur upload
    async handleFileUpload(file) {
      if (!localStorage.imgur) {
        await this.getImgurAccessToken()
      }
      let token = localStorage.imgur
      this.file = file[0]

      let formData = new FormData()
      formData.append('image', this.file) //required
      formData.append('title', 'test') //optional
      formData.append('description', 'test') //optional

      this.axios({
        method: 'POST',
        url: 'https://imgur-apiv3.p.rapidapi.com/3/image',
        data: formData,
        headers: {
          'x-rapidapi-host': 'imgur-apiv3.p.rapidapi.com',
          'x-rapidapi-key': 'b05d4afeb3msh2e3d558f3538b7cp153622jsn0a8456bae916',
          authorization: 'Bearer ' + token,
          'content-type': 'application/x-www-form-urlencoded',
        },
        mimeType: 'multipart/form-data',
      })
        .then((res) => {
          this.accountIcon = res.data.data.link
          this.updateAccount()
        })
        .catch((e) => {
          alert(this.$t('__Imagechangeerror'))
          localStorage.removeItem('imgur')
        })
    },
    showSuccess($event) {
      this.$refs.myVueDropzone.disable()
      this.handleFileUpload(this.$refs.myVueDropzone.getAcceptedFiles())
    },
    removeFile() {
      this.$refs.myVueDropzone.enable()
    },
    // edit places for wechat/line * 5
    editId() {
      $('#wechat-appid-title').hide()
      $('#wechat-appid-input').show()
      $('#wechat-appid-edit').hide()
    },
    editAccountName() {
      $('#accountName-title').hide()
      $('#accountName-input').show()
      $('#accountName-appsecret').hide()
    },
    confirmChangeId($event) {
      if ($event.which == 13) {
        $('#wechat-appid-title').show()
        $('#wechat-appid-input').hide()
        $('#wechat-appid-edit').show()
        this.updateAccount()
        return false
      }
    },
    editToken() {
      $('#wechat-apptoken-title').hide()
      $('#wechat-apptoken-input').show()
      $('#wechat-apptoken-edit').hide()
      $('#line-apptoken-title').hide()
      $('#line-apptoken-input').show()
      $('#line-apptoken-edit').hide()
      $('#facebook-apptoken-title').hide()
      $('#facebook-apptoken-input').show()
      $('#facebook-apptoken-edit').hide()
    },
    confirmChangeToken($event) {
      if ($event.which == 13) {
        $('#wechat-apptoken-title').show()
        $('#wechat-apptoken-input').hide()
        $('#wechat-apptoken-edit').show()
        $('#line-apptoken-title').show()
        $('#line-apptoken-input').hide()
        $('#line-apptoken-edit').show()
        $('#facebook-apptoken-title').show()
        $('#facebook-apptoken-input').hide()
        $('#facebook-apptoken-edit').show()
        $('#accountName-title').show()
        $('#accountName-input').hide()
        $('#accountName-appsecret').show()
        this.updateAccount()
        return false
      }
    },
    editSecret() {
      $('#wechat-appsecret-title').hide()
      $('#wechat-appsecret-input').show()
      $('#wechat-appsecret-edit').hide()
      $('#line-appsecret-title').hide()
      $('#line-appsecret-input').show()
      $('#line-appsecret-edit').hide()
      $('#facebook-appsecret-title').hide()
      $('#facebook-appsecret-input').show()
      $('#facebook-appsecret-edit').hide()
    },
    confirmChangeSecret($event) {
      if ($event.which == 13) {
        $('#wechat-appsecret-title').show()
        $('#wechat-appsecret-input').hide()
        $('#wechat-appsecret-edit').show()
        $('#line-appsecret-title').show()
        $('#line-appsecret-input').hide()
        $('#line-appsecret-edit').show()
        $('#facebook-appsecret-title').show()
        $('#facebook-appsecret-input').hide()
        $('#facebook-appsecret-edit').show()
        this.updateAccount()
        return false
      }
    },
    confirmChangeLoginChannelId($event) {
      if ($event.which == 13) {
        $('#line-loginchannelid-title').show()
        $('#line-loginchannelid-input').hide()
        $('#line-loginchannelid-edit').show()
        this.updateAccount()
        return false
      }
    },
    editLoginChannelId() {
      $('#line-loginchannelid-title').hide()
      $('#line-loginchannelid-input').show()
      $('#line-loginchannelid-edit').hide()
    },
    confirmChangeLoginSecret($event) {
      if ($event.which == 13) {
        $('#line-loginsecret-title').show()
        $('#line-loginsecret-input').hide()
        $('#line-loginsecret-edit').show()
        this.updateAccount()
        return false
      }
    },
    editLoginSecret() {
      $('#line-loginsecret-title').hide()
      $('#line-loginsecret-input').show()
      $('#line-loginsecret-edit').hide()
    },
    editFbGreetingMsg() {
      $('#facebook-greeting-title').hide()
      $('#facebook-greeting-input').show()
      $('#facebook-greeting-edit').hide()
    },
    confirmChangeFbGreetingMsg($event) {
      if ($event.which == 13) {
        $('#facebook-greeting-title').show()
        $('#facebook-greeting-input').hide()
        $('#facebook-greeting-edit').show()
        let formData = {
          greeting: [
            {
              locale: 'default',
              text: this.fbProfile.greeting.text,
            },
          ],
        }
        const params = new URLSearchParams()
        params.append('access_token', this.appToken)
        this.axios({
          method: 'POST',
          url: 'https://graph.facebook.com/v15.0/me/messenger_profile',
          params: params,
          data: formData,
        })
          .then((res) => {
            if (res.data['result'] === 'success') {
              this.$fire({
                title: this.$t('__updatecompleted'),
                text: this.$t('__updatecompleted'),
                type: 'success',
                timer: 1500,
              })
            }
          })
          .catch((err) => {
            console.error(err)
            this.$fire({
              title: this.$t('__Settingsnotyetsaved'),
              text: this.$t('__oopsSomethingwentwrong'),
              type: 'error',
            })
          })
        return false
      }
    },
    updateAccount() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        accountName: this.accountName,
        accountType: this.accountType,
        accountIcon: this.accountIcon,
        accountWebhookUrl: this.webhookUrl,
        accountToken: this.appToken,
        accountSecret: this.appSecret,
      }
      // If account type is wechat, add app id
      if (this.accountType == 'wechat') {
        formData.appId = this.appId
      } else if (this.accountType == 'line') {
        formData['lineLoginChannelId'] = this.loginChannelId
        formData['lineLoginSecret'] = this.loginSecret
      }
      apiFunction.updateAccount(formData, this.jwtToken).then((data) => {
        if (data && data.status == 200) {
          this.$fire({
            title: this.$t('__updatecompleted'),
            text: this.$t('__updatecompleted'),
            type: 'success',
            timer: 1500,
          })
          this.getAccountInfo()
        } else {
          this.$fire({
            title: this.$t('__Settingsnotyetsaved'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
    isSupportClipboardApi() {
      return navigator.clipboard != undefined
    },
    onClickCopyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$fire({
          text: this.$t('__CopiedToClipboard'),
          type: 'success',
        })
      })
    },
    onFbStartScenarioChanged() {
      // Prepare post data
      let scenarioIdValue = this.fbProfile.get_started.scenarioId
      let nextNodeIdValue = "''"
      let postData = {
        scenarioId: scenarioIdValue,
        nextNodeId: nextNodeIdValue,
      }
      let postDataString = jQuery.param(postData)
      let formData = {
        get_started: {
          payload: postDataString,
        },
      }
      const params = new URLSearchParams()
      params.append('access_token', this.appToken)
      this.axios({
        method: 'POST',
        url: 'https://graph.facebook.com/v15.0/me/messenger_profile',
        params: params,
        data: formData,
      })
        .then((res) => {
          if (res.data['result'] === 'success') {
            console.log(res.data['result'])
            this.$fire({
              title: this.$t('__updatecompleted'),
              text: this.$t('__updatecompleted'),
              type: 'success',
              timer: 1500,
            })
          }
        })
        .catch((err) => {
          console.error(err)
          this.$fire({
            title: this.$t('__Settingsnotyetsaved'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        })
    },
  },
}
</script>
<style></style>
