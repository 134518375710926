<template>
  <card class="main-content">
    <!-- search -->
    <div class="row">
      <div class="ml-auto py-2 pr-4">
        <input
          id="search-box-plan"
          type="text"
          class="search-box"
          name="q"
          v-model="search"
          v-on:keyup.enter="searchUser"
        />
        <label for="search-box-plan" v-on:click="searchUser">
          <span class="fas fa-search search-icon"></span>
        </label>
      </div>
    </div>
    <!-- search -->
    <div class="container">
      <div class="row row-cols-4">
        <!--filter UI-->
        <div class="col filter-div">
          <div>
            <div class="filter-button absoluteit" @click="sw_filter_ui()">
              {{ $t('__userlist_filter') }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chevron-compact-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
                />
              </svg>
            </div>
            <div
              class="filter-container"
              style="width: 900px"
              v-bind:class="[{ dnone: filter.sw }]"
            >
              <div class="card card-border" style="height: 400px">
                <div class="card-body" style="overflow: auto">
                  <button
                    type="button"
                    @click="filter.sw = !filter.sw"
                    data-dismiss="modal"
                    aria-label="Close"
                    class="close closeposition closeposition_filter"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div class="form-group">
                    <md-radio
                      type="radio"
                      id="filter-match-all"
                      value="all"
                      v-model="filter.conditionType"
                      v-on:change=""
                    >
                      <label for="filter-match-all"></label>
                      <span>{{ $t('__userlist_matchAll') }}</span>
                    </md-radio>
                    <md-radio
                      type="radio"
                      id="filter-match-one"
                      value="any"
                      v-model="filter.conditionType"
                      v-on:change=""
                    >
                      <label for="filter-match-one"></label>
                      <span>{{ $t('__userlist_matchAny') }}</span>
                    </md-radio>
                  </div>
                  <div class="form-group">
                    <button type="button" class="btn btn-primary" @click="addFilter()">
                      {{ $t('__userlist_addNewFilter') }}
                    </button>
                  </div>
                  <div>
                    <div v-for="(condition, index) in filter.conditions" :key="condition.id">
                      <div v-if="condition.type == ''" class="border p-2 mb-2">
                        <select class="custom-select" v-model="condition.type">
                          <option value="">{{ $t('__userlist_filterType') }}</option>
                          <option value="user_tag">{{ $t('__userlist_userTag') }}</option>
                          <option value="user_reply">{{ $t('__userlist_userReply') }}</option>
                          <option value="user_first">{{ $t('__userlist_userFirst') }}</option>
                          <option value="user_last">{{ $t('__userlist_userLast') }}</option>
                          <option value="user_segment">{{ $t('__userlist_userSegment') }}</option>
                          <option value="user_block">{{ $t('__userlist_userBlock') }}</option>
                        </select>
                      </div>
                      <template v-else-if="condition.type === 'user_tag'">
                        <FilterUserTag
                          :condition="condition"
                          @deleteCondition="deleteCondition"
                          @updateCondition="updateCondition"
                        ></FilterUserTag>
                      </template>
                      <template v-else-if="condition.type === 'user_reply'">
                        <FilterUserReply
                          :condition="condition"
                          @deleteCondition="deleteCondition"
                        ></FilterUserReply>
                      </template>
                      <template v-else-if="condition.type === 'user_first'">
                        <FilterUserFirstInteract
                          :condition="condition"
                          @deleteCondition="deleteCondition"
                        ></FilterUserFirstInteract>
                      </template>
                      <template v-else-if="condition.type === 'user_last'">
                        <FilterUserLastInteract
                          :condition="condition"
                          @deleteCondition="deleteCondition"
                        ></FilterUserLastInteract>
                      </template>
                      <template v-else-if="condition.type === 'user_segment'">
                        <FilterUserSegment
                          :condition="condition"
                          @deleteCondition="deleteCondition"
                        ></FilterUserSegment>
                      </template>
                      <template v-else-if="condition.type === 'user_block'">
                        <FilterUserBlock
                          :condition="condition"
                          @deleteCondition="deleteCondition"
                        ></FilterUserBlock>
                      </template>
                    </div>
                  </div>
                  <div class="p-2 mb-2">
                    <button
                      type="button"
                      v-show="filter.conditions.length >= 1"
                      class="btn btn-primary m-2"
                      @click="runFilter"
                    >
                      {{ $t('__userlist_query') }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="resetFilter">
                      {{ $t('__userlist_reset') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- add tag -->
        <div>
          <div class="filter-button absoluteit" style="width: 120px" @click="sw_tagappend()">
            {{ $t('__userlist_tagAdd') }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-compact-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
              />
            </svg>
          </div>
          <div class="filter-container" v-bind:class="[{ dnone: tagAppend.sw }]">
            <div class="card card-border">
              <div class="card-body">
                <button
                  type="button"
                  @click="tagAppend.sw = !tagAppend.sw"
                  data-dismiss="modal"
                  aria-label="Close"
                  class="close closeposition closeposition_filter"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div
                  class="row border mb-3 py-2 px-3"
                  style="overflow-y: scroll; max-height: 15rem"
                >
                  <div class="input-group mb-1" v-for="(tag, index) in tagAppend.tags" :key="index">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="select-user"
                          :value="tag"
                          :id="'tag' + index"
                          v-model="tag.isChecked"
                        />
                      </div>
                    </div>
                    <div class="py-2 border rounded col" style="display: flex">
                      <a href="javascript:void(0)" class="tag tagwidth" :class="tag.color">{{
                        tag.name
                      }}</a>
                      <select
                        class="custom-select"
                        name=""
                        id=""
                        v-model="tag.tagvalue"
                        v-show="tag.values != null && tag.values.length >= 1"
                      >
                        <option value="">{{ $t('__userlist_noValue') }}</option>
                        <option :value="value" v-for="(value, index) in tag.values">
                          {{ value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <p class="m-auto py-4" v-if="tagAppend.tags.length == 0">
                    {{ $t('__Nolabelsavailable') }}
                  </p>
                </div>
                <button type="button" class="btn btn-primary" @click="runAddTag">
                  {{ $t('__userlist_addTag') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- remove tag -->
        <div>
          <div class="filter-button absoluteit" style="width: 120px" @click="sw_tagremove()">
            {{ $t('__userlist_tagRemove') }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-compact-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67"
              />
            </svg>
          </div>
          <div class="filter-container" v-bind:class="[{ dnone: tagRemove.sw }]">
            <div class="card card-border">
              <div class="card-body">
                <button
                  type="button"
                  @click="tagRemove.sw = !tagRemove.sw"
                  data-dismiss="modal"
                  aria-label="Close"
                  class="close closeposition closeposition_filter"
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div
                  class="row border mb-3 py-2 px-3"
                  style="overflow-y: scroll; max-height: 15rem"
                >
                  <div class="input-group mb-1" v-for="(tag, index) in tagRemove.tags" :key="index">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="select-user"
                          :value="tag"
                          :id="'tag' + index"
                          v-model="tag.isChecked"
                        />
                      </div>
                    </div>
                    <div class="py-2 border rounded col">
                      <a href="javascript:void(0)" class="tag tagwidth" :class="tag.color">{{
                        tag.name
                      }}</a>
                      <input
                        type="text"
                        :value="tag.tagvalue"
                        v-show="tag.tagvalue != ''"
                        disabled
                        width="2"
                      />
                    </div>
                  </div>
                  <p class="m-auto py-4" v-if="tagRemove.tags.length == 0">
                    {{ $t('__Nolabelsavailable') }}
                  </p>
                </div>
                <button type="button" class="btn btn-primary" @click="runRemoveTag">
                  {{ $t('__userlist_removeTag') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--filter-->

    <!-- content start -->
    <div class="row mt-4 mb-1">
      <div class="col-6">
        <span> {{ $t('__Searchusersnow') }}</span>
        <span>: {{ allUsers.length }}</span>
      </div>
      <div class="ml-auto mr-4">
        <a href="javascript:void(0)" @click="$router.push('/segment')">
          {{ $t('__Userclassification') }}</a
        >
        <span class="px-2">/</span>
        <a
          href="javascript:void(0)"
          @click="$router.push('/segment/userList')"
          style="color: #009688"
        >
          {{ $t('__Userlist') }}</a
        >
      </div>
    </div>
    <!-- Loading spinner -->
    <div>
      <userList-table
        type="hover"
        :accountType="getAccountType()"
        :adminId="adminId"
        :accountId="accountId"
        :jwtToken="jwtToken"
        :data="table.data"
        :isAll="isAll"
        :columns="table.columns"
        @swCheckAll="swCheckAll"
      ></userList-table>
    </div>

    <!-- content end -->
    <div class="infinite-wrapper">
      <infinite-loading
        :forceUseInfiniteWrapper="true"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
      >
        <span slot="no-more"> No More Data </span>
        <span slot="no-results"></span>
      </infinite-loading>
    </div>
    <div class="row">
      <div class="ml-auto mr-4" v-if="false">
        <a href="javascript:void(0)" @click="getFollowers()"> {{ $t('__getFollowers') }}</a>
      </div>
    </div>
  </card>
</template>
<script>
import {
  UserListTable,
  FilterUserTag,
  FilterUserReply,
  FilterUserFirstInteract,
  FilterUserLastInteract,
  FilterUserSegment,
  FilterUserBlock,
} from './Components'
import { apiFunction } from './Axios/functions'
import { apiFunction as rootApiFunction } from '@/Axios/functions'
import InfiniteLoading from 'vue-infinite-loading'
import axios from 'axios'
export default {
  components: {
    'userList-table': UserListTable,
    InfiniteLoading,
    FilterUserTag,
    FilterUserReply,
    FilterUserFirstInteract,
    FilterUserLastInteract,
    FilterUserSegment,
    FilterUserBlock,
  },
  data() {
    const tableData = []
    return {
      search: '',
      adminId: '',
      jwtToken: '',
      accountId: '',
      table: {
        data: [...tableData],
      },
      allUsers: [],
      usersChecked: [], // current user been checked
      allTag: [], // tags
      allVariable: [], // account variable
      allSegment: [], // segment list
      filteredData: [],
      userLastEvaluatedKey: null,
      infiniteId: +new Date(),
      isAll: false,
      filter: {
        sw: true, // switch on/off filter ui
        conditionType: 'all', // match all or match one
        selectOption: 'user_tag', // select option
        tags: [], // user tags
        conditions: [],
        types: [],
      },
      tagAppend: {
        sw: true,
        tags: [],
      },
      tagRemove: {
        sw: true,
        tags: [],
      },
    }
  },
  watch: {},
  created() {
    this.loadData()
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(',')
        this.adminId = decrypt[0]
        this.accountId = decrypt[1]
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }

      // data initial
      await this.getTagList()

      // initical values
      for (let i = 0; i < this.allTag.length; i++) {
        this.allTag[i].tagvalue = ''
      }

      this.tagAppend.tags = JSON.parse(JSON.stringify(this.allTag))
      this.tagRemove.tags = JSON.parse(JSON.stringify(this.allTag))

      await this.getVariable()
      await this.getUserSegment()
    },
    async getTagList() {
      let formData = { adminId: this.adminId, accountId: this.accountId }

      await rootApiFunction.getTags(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          if (data.data.Result != undefined) {
            this.allTag = data.data.Result.data
            this.allTag.forEach((tag) => {
              if (tag.values.length <= 0) {
                tag.values = null
                tag.isChecked = false
              }
            })
          }
        }
      })
    },
    async getVariable() {
      let formData = { adminId: this.adminId, accountId: this.accountId }

      await rootApiFunction.getVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          if (data.data.Result != undefined) {
            data.data.Result.data.forEach((variable) => {
              this.allVariable.push({
                name: variable,
                isChecked: false,
              })
            })
          }
        }
      })
    },
    async getUserSegment() {
      let formData = { adminId: this.adminId, accountId: this.accountId }
      // if (lastEvaluatedKey) {
      //   formData["lastEvaluatedKey"] = lastEvaluatedKey;
      // }
      return axios({
        method: 'POST',
        url: process.env.VUE_APP_API_ENDPOINT + 'getUserSegment',
        data: formData,
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.Result != undefined) {
              this.allSegment = res.data.Result.data
            }
          }

          return res
        })
        .catch((e) => {
          console.log(e)
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
          return null
        })
    },
    // Search users
    async searchUser() {
      this.table.data.splice(0)
      this.allUsers.splice(0)
      this.userLastEvaluatedKey = null
      // Update infiniteId to trigger infinite-loading.
      this.infiniteId += 1
    },
    //add filter condition
    addFilter() {
      let id = 0

      if (this.filter.conditions.length > 0) {
        id = this.filter.conditions[0].id + 1
      }
      this.filter.conditions.unshift({
        id: id,
        type: '',
        tags: JSON.parse(JSON.stringify(this.allTag)),
        variables: JSON.parse(JSON.stringify(this.allVariable)),
        segments: JSON.parse(JSON.stringify(this.allSegment)),
        matchType: 'match_any',
        filters: {},
      })
    },
    runFilter() {
      this.table.data.splice(0)
      this.allUsers.splice(0)

      // if(this.filter.conditions.length <= 0){
      //     alert('尚未輸入條件');
      //     return;
      // }

      this.fetchUserList()
    },
    runAddTag() {
      let isAll = false
      if (this.isAll != undefined && this.isAll != null) {
        isAll = this.isAll
      }
      let checkedUsers = this.allUsers.filter((u) => u.isChecked)
      let tags = this.tagAppend.tags.filter((t) => t.isChecked)

      if (tags.length <= 0) {
        this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__userList_noTagsSelected'),
          type: 'info',
          timer: 1500,
        }).then((e) => {
          this.getTagList()
          this.sw_filer_pop('tagAppend', false)
        })
        return
      }
      if (checkedUsers.length <= 0 && isAll == false) {
        this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__userList_noUserOrAllSelected'),
          type: 'info',
          timer: 1500,
        }).then((e) => {
          this.getTagList()
          this.sw_filer_pop('tagAppend', false)
        })
        return
      }

      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        tag: {
          name: 'eee',
          color: 'bg-info',
          values: [],
        },
        tags: tags,
        users: checkedUsers,
        isAll: isAll,
        conditionType: this.filter.conditionType,
        conditions: this.filter.conditions,
      }
      rootApiFunction.saveTagUser(formData, this.jwtToken).then((data) => {
        if (data && !data.error) {
          this.$fire({
            title: this.$t('__addedsuccessfully'),
            text: this.$t('__newTagCreated'),
            type: 'success',
            timer: 1500,
          }).then((e) => {
            this.getTagList()
            this.sw_filer_pop('tagAppend', false)
          })
        } else {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
    runRemoveTag() {
      let isAll = false
      let checkedUsers = this.allUsers.filter((u) => u.isChecked)
      let tags = this.tagRemove.tags.filter((t) => t.isChecked)

      if (tags.length <= 0) {
        this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__userList_noTagsSelected'),
          type: 'info',
          timer: 1500,
        }).then((e) => {
          this.getTagList()
          this.sw_filer_pop('tagAppend', false)
        })
        return
      }
      if (checkedUsers.length <= 0 && isAll == false) {
        this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__userList_noUserOrAllSelected'),
          type: 'info',
          timer: 1500,
        }).then((e) => {
          this.getTagList()
          this.sw_filer_pop('tagAppend', false)
        })
      }
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        tags: tags,
        users: checkedUsers,
        isAll: isAll,
        conditionType: this.filter.conditionType,
        conditions: this.filter.conditions,
      }
      rootApiFunction.deleteTagUser(formData, this.jwtToken).then((data) => {
        if (data && !data.error) {
          this.$fire({
            title: '操作成功',
            text: '標籤已移除',
            type: 'success',
            timer: 1500,
          }).then((e) => {
            this.getTagList()
            this.sw_filer_pop('tagRemove', false)
          })
        } else {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
    resetFilter() {
      this.filter.conditions = []
      this.isAll = 'false'
      this.runFilter()
    },
    // get accountType
    getAccountType() {
      return localStorage.accountType
    },
    // Remove duplicate by user Id
    removeDupUser(arr) {
      const filteredArr = arr.reduce((acc, current) => {
        const x = acc.find((item) => item.userId === current.userId)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
      return filteredArr
    },
    deleteCondition(condition) {
      this.filter.conditions = this.filter.conditions.filter((item) => item.id != condition.id)
    },
    updateCondition(condition) {
      return
      console.log(condition)
      let findcondition = this.filter.conditions.find((item) => item.id == condition.id)
      findcondition = condition
    },
    sw_filer_pop(f, sw) {
      sw = !sw
      switch (f.toLowerCase()) {
        case 'filter':
          this.filter.sw = sw
          break
        case 'tagappend':
          this.tagAppend.sw = sw
          break
        case 'tagremove':
          this.tagRemove.sw = sw
          break
      }
    },
    // switch filter ui
    sw_filter_ui() {
      this.sw_filer_pop('tagAppend', false)
      this.sw_filer_pop('tagRemove', false)
      this.filter.sw = !this.filter.sw
    },
    sw_tagappend() {
      this.sw_filer_pop('filter', false)
      this.sw_filer_pop('tagRemove', false)
      this.tagAppend.sw = !this.tagAppend.sw
    },
    sw_tagremove() {
      this.sw_filer_pop('tagAppend', false)
      this.sw_filer_pop('filter', false)
      this.tagRemove.sw = !this.tagRemove.sw
    },
    // AccountStatus 1 record to the front
    sortUser(array) {
      array.sort(function (a, b) {
        return a.accountStatus < b.accountStatus ? 1 : -1
      })
      return array
    },
    async fetchUserList(lastEvaluatedKey = null) {
      let formData = { adminId: this.adminId, accountId: this.accountId }
      if (this.search !== '') {
        formData['keywords'] = this.search
      }
      if (lastEvaluatedKey) {
        formData['lastEvaluatedKey'] = lastEvaluatedKey
      }
      // filter
      if (this.filter.conditions.length > 0) {
        formData['conditions'] = this.filter.conditions
        formData['conditionType'] = this.filter.conditionType
      }

      return axios({
        method: 'POST',
        url: process.env.VUE_APP_API_ENDPOINT + 'getUsersList',
        data: formData,
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      })
        .then((res) => {
          this.allUsers.push(...res.data.Result.data)
          this.userLastEvaluatedKey = res.data.Result.lastEvaluatedKey
          this.buildTableData()

          this.sw_filer_pop('filter', false)

          // if isAll checked, marked all loaded users check
          if (this.isAll == true) {
            this.allUsers.map((u) => {
              u.isChecked = true
            })
          }
          return res
        })
        .catch((e) => {
          this.userLastEvaluatedKey = null
          console.log(e)
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
          return null
        })
    },
    buildTableData() {
      // this.table.data = this.sortByUpdateTime(this.allUsers);
      this.allUsers.map((u) => {
        u.isChecked = false
        u.isShowInfo = true
        u.tags = [] // tags
        u.variables = [] // variables
      })
      this.table.data = this.allUsers
    },
    // Sort table row by update time
    sortByUpdateTime(array) {
      array.sort(function (a, b) {
        var dateA = new Date(a.followTime),
          dateB = new Date(b.followTime)
        return dateA < dateB ? 1 : -1
      })
      return array
    },
    getFollowers() {
      this.$fire({
        title: this.$t('__getFollowers'),
        text: this.$t('__getFollowersConfirmMessage'),
        showCancelButton: true,
        confirmButtonText: this.$t('__getFollowersConfirm'),
        cancelButtonText: this.$t('__getFollowersCancel'),
      }).then((res) => {
        if (res.value) {
          const formData = { adminId: this.adminId, accountId: this.accountId }
          apiFunction
            .getFollowers(formData, this.jwtToken)
            .then((data) => {
              if (data && data.status == 200) {
                this.$fire({
                  title: this.$t('__getFollowers'),
                  text: this.$t('__updateFollowers'),
                  type: 'success',
                  timer: 2500,
                })
              } else {
                this.$fire({
                  title: this.$t('__getFollowers'),
                  text: this.$t('__oopsSomethingwentwrong'),
                  type: 'error',
                })
              }
            })
            .catch(() => {
              this.$fire({
                title: this.$t('__getFollowers'),
                text: this.$t('__oopsSomethingwentwrong'),
                type: 'error',
              })
            })
        }
      })
    },
    async infiniteHandler($state) {
      await this.fetchUserList(this.userLastEvaluatedKey)
      if (this.userLastEvaluatedKey) {
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    swCheckAll(isCheckAll) {
      this.isAll = isCheckAll
    },
  },
}
</script>
<style>
.filter-container {
  width: 38rem;
  position: absolute;
  margin-top: 10px;
}
.dnone {
  display: none;
}
.card-border {
  border: 1px solid #ccc !important;
}
.filter-button {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 8px;
  font-size: 1.3em;
  color: var(--gray-dark);
  width: 80px;
  margin-right: 10px;
}
.select-user {
  height: 18px;
  width: 18px;
}
.userlist-delete-icon-a {
  top: -26px;
  position: relative;
  right: 0px;
  width: 20px;
}
.userlist-delete-icon {
  width: 20px;
  height: 20px;
}
.closeposition_filter {
  margin-top: -3px;
  margin-right: -4px;
}
.filter-div {
  display: flex;
}
</style>
