<template>
  <div class="border p-2 mb-2">
    <label for=""
      ><b>{{ this.$t('__userlist_userFirst') }}</b></label
    >
    <div>
      <a
        href="javascript:void(0)"
        @click="deleteCondition"
        class="text-danger float-right userlist-delete-icon-a"
      >
        <svg
          class="svg-inline--fa fa-trash text-danger userlist-delete-icon"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="trash"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
          ></path></svg
        ><!-- <i class="fas fa-trash text-danger"></i> Font Awesome fontawesome.com -->
      </a>
    </div>
    <form>
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <label for="exampleFormControlInput1">{{ this.$t('__userlist_timeStart') }}</label>

            <datetime
              v-model="timeStart"
              type="datetime"
              :minute-step="5"
              input-class="form-control col-12"
              input-style="width: 16rem;"
            ></datetime>
          </div>
          <div class="col-sm">
            <label for="exampleFormControlInput1">{{ this.$t('__userList_timeEnd') }}</label>
            <datetime
              v-model="timeEnd"
              type="datetime"
              :minute-step="5"
              input-class="form-control col-12"
              input-style="width: 16rem;"
            ></datetime>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
export default {
  components: { Datetime },
  name: 'FilterUserFirstInteract',
  props: {
    condition: Object,
  },

  data() {
    return {
      matchType: 'match_any',
      currentTime: '',
      timeStart: '',
      timeEnd: '',
    }
  },
  mounted() {
    this.$watch(
      'data',
      (data) => {
        this.loadData()
      },
      { immediate: true },
    )
  },
  watch: {
    timeStart: function (val, oldVal) {
      if (this.condition.filters.timeStart == undefined) {
        this.condition.filters.timeStart = ''
      }
      val = val.replace('T', ' ')
      val = val.replace('Z', '')
      this.condition.filters.timeStart = val
    },
    timeEnd: function (val, oldVal) {
      if (this.condition.filters.timeEnd == undefined) {
        this.condition.filters.timeEnd = ''
      }
      val = val.replace('T', ' ')
      val = val.replace('Z', '')
      this.condition.filters.timeEnd = val
    },
  },
  methods: {
    loadData() {
      this.currentTime = this.$dateTime.getCurrentTime()
    },
    select(tag, index) {
      if (this.condition.filters.tags == undefined) {
        this.condition.filters.tags = []
      }

      let findTag = this.condition.filters.tags.find(
        (t) => t.name == tag.name && t.color == t.color,
      )
      if (findTag == null) {
        if (tag.isChecked == true) {
          this.condition.filters.tags.push(tag)
        }
      } else {
        if (tag.isChecked == false) {
          // if checkbox is unchecked, and findTag is not null, remove it from condition
          this.condition.filters.tags = this.condition.filters.tags.filter(
            (t) => t.name != tag.name && t.color != tag.color,
          )
        }
      }
      //this.$emit('updateCondition', this.condition);
    },
    deleteCondition() {
      this.$emit('deleteCondition', this.condition)
    },
  },
}
</script>
<style></style>
