<template>
  <!-- Create New User Icon Modal Start -->
  <div
    class="modal fade bd-example-modal"
    id="create-user-icon-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold">
            {{ editMode ? $t('__editUserIcon') : $t('__AddUserIcon') }}
          </p>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="pt-3 mb-2">
              <span class="text-danger">*</span> {{ $t('__requiredItem') }}
            </div>
            <div class="row">
              <div class="col-12">
                <div class="border">
                  <vue-dropzone
                    ref="userIconVueDropzone"
                    id="myDropzone"
                    :options="dropzoneOptions"
                    v-on:vdropzone-file-added="imgHandling"
                  ></vue-dropzone>
                </div>
              </div>
            </div>
            <label class="pt-3 mb-2" data-translate="_selectColor.edit">
              <span class="text-danger">*</span> {{ $t('__IconName') }}
            </label>
            <div class="row">
              <input
                type="text"
                class="form-control px-4 mx-3"
                :placeholder="$t('__IconName')"
                v-model="saveUserIconData.name"
              />
            </div>
            <label class="pt-3 mb-2" data-translate="_selectColor.edit">
              <span class="text-danger">*</span> {{ $t('__ImageUrl') }}
            </label>
            <div class="row">
              <input
                type="text"
                class="form-control px-4 mx-3"
                :placeholder="$t('__ImageUrl')"
                v-model="saveUserIconData.url"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <button
              v-if="editMode"
              type="button"
              id="delete-user-icon-btn"
              class="btn btn-danger ml-2"
              @click="deleteUserIcon()"
            >
              {{ $t('__deleteUserIcon') }}
            </button>
            <button
              type="button"
              id="create-user-icon-btn"
              class="btn btn-secondary ml-2"
              @click="createUserIcon()"
            >
              {{ editMode ? $t('__saveUserIcon') : $t('__CreateNewUserIcon') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Create New User Icon Modal End -->
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'create-user-icon-modal',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    saveUserIconData: Object,
  },
  data() {
    const uploadText =
      "<div><i class='fa fa-cloud-upload-alt pr-2' style='font-size: 3.5rem;'></i><h4>" +
      this.$t('__Clickordragtoupload') +
      '</h4></div>'
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        maxFilesize: 500,
        addRemoveLinks: true,
        uploadMultiple: false,
        maxFiles: 1,
        dictDefaultMessage: uploadText,
      },
      editMode: false,
    }
  },
  watch: {
    saveUserIconData: {
      handler: function (newVal) {
        if (newVal.url !== '') {
          let fileUrl = newVal.url
          var dropzone = this.$refs.userIconVueDropzone
          if (dropzone.dropzone.files.length > 0) {
            return
          }
          var xhr = new XMLHttpRequest()
          xhr.open('HEAD', fileUrl, true)
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              if (xhr.status == 200) {
                var fileSize = xhr.getResponseHeader('Content-Length')
                var fileType = xhr.getResponseHeader('Content-Type')
                var tmpArr = fileUrl.split('/')
                var fileName = tmpArr[tmpArr.length - 1]
                var imgFile = { size: fileSize, name: fileName, type: fileType }
                dropzone.manuallyAddFile(imgFile, fileUrl)
              }
            }
          }
          xhr.send()
        }
      },
      deep: true,
    },
  },
  methods: {
    // Get imgur access token
    async getImgurAccessToken() {
      let client_id = '208d8a2829b83a4'
      let secret = '8acb5af7e2368314866e1c7300471509df22a712'

      let formData = new FormData()
      formData.append('refresh_token', 'ed342a5803077918e62e009d0964dd470fec010a') //required
      formData.append('client_id', client_id)
      formData.append('client_secret', secret)
      formData.append('grant_type', 'refresh_token')

      await this.axios({
        method: 'POST',
        url: 'https://api.imgur.com/oauth2/token',
        data: formData,
      })
        .then((res) => {
          localStorage.imgur = res.data.access_token
          // return (this.token = res.data.access_token);
        })
        .catch((e) => {
          console.log(e)
        })
    },
    imgHandling(file) {
      if (file.manuallyAdded) {
        return
      }
      this.uploadImgFileToImgur(file)
        .then((url) => {
          this.saveUserIconData.url = url
        })
        .catch((err) => {
          console.error(err)
          alert(this.$t('__Imagechangeerror'))
        })
    },
    async uploadImgFileToImgur(file) {
      if (!localStorage.imgur) {
        await this.getImgurAccessToken()
      }
      let token = localStorage.imgur
      let formData = new FormData()
      formData.append('image', file)
      return this.axios({
        method: 'POST',
        url: 'https://imgur-apiv3.p.rapidapi.com/3/image',
        data: formData,
        headers: {
          'x-rapidapi-host': 'imgur-apiv3.p.rapidapi.com',
          'x-rapidapi-key': 'b05d4afeb3msh2e3d558f3538b7cp153622jsn0a8456bae916',
          authorization: 'Bearer ' + token,
          'content-type': 'application/x-www-form-urlencoded',
        },
        mimeType: 'multipart/form-data',
      })
        .then((res) => {
          return Promise.resolve(res.data.data.link)
        })
        .catch((err) => {
          localStorage.removeItem('imgur')
          console.error(err)
          return Promise.reject()
        })
    },
    // Add new user icon
    createUserIcon() {
      if (this.saveUserIconData.name !== '' && this.saveUserIconData.url !== '') {
        $('#create-user-icon-modal').modal('hide')
        var dropzone = this.$refs.userIconVueDropzone
        dropzone.removeAllFiles(true)
        this.$emit('saveUserIcon')
      } else {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__PleaseEnterAllRequiredField'),
          type: 'error',
        })
      }
    },
    deleteUserIcon() {
      this.$fire({
        title: this.$t('__Areyousureyouwanttodeleteit'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          $('#create-user-icon-modal').modal('hide')
          var dropzone = this.$refs.userIconVueDropzone
          dropzone.removeAllFiles(true)
          this.$emit('deleteUserIcon')
        }
      })
    },
    closeModal() {
      var dropzone = this.$refs.userIconVueDropzone
      dropzone.removeAllFiles(true)
    },
  },
}
</script>
