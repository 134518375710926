<template>
  <!-- VarForm modal start -->
  <div class="modal fade" id="var-form-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold">{{ $t('__Form') }}</p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <label> 名稱</label>
            <input type="text" class="form-control" id="var-form-modal-name" v-model="formName" />
            <div>
              <!-- search -->
              <div class="row">
                <div class="ml-auto py-2 pr-0">
                  <input type="text" class="search-box" v-model="searchVariable" />
                  <label>
                    <span class="fas fa-search search-icon"></span>
                  </label>
                </div>
              </div>
              <!-- search -->
              <div class="row border mb-3 py-2 px-3" style="overflow-y: scroll; max-height: 15rem">
                <div class="input-group mb-1" v-for="(variable, index) in variables" :key="index">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input
                        type="checkbox"
                        :value="variable"
                        :id="'variable' + index"
                        v-model="checkedVariables"
                      />
                    </div>
                  </div>
                  <div class="py-2 border rounded col">
                    <span>{{ variable }}</span>
                  </div>
                </div>
                <p class="m-auto py-4" v-if="variables.length == 0">
                  {{ $t('__Novariablesavailable') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            style="width: 100%"
            data-dismiss="modal"
            @click="saveForm()"
          >
            {{ $t('__determine') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- VarForm Modal End -->
</template>

<script>
export default {
  name: 'var-form-modal',
  props: {
    allVariables: Array,
    formObject: Object,
  },
  data() {
    return {
      variables: [],
      checkedVariables: [],
      searchVariable: [],
      formId: '',
      formName: '',
    }
  },
  watch: {
    searchVariable: {
      handler: function (val, oldval) {
        let reg = new RegExp(val)
        let searchResult = []

        $.each(this.allVariables, function (i, variable) {
          if (reg.test(variable) == true) {
            searchResult.push(variable)
          }
        })
        this.variables = searchResult
      },
    },
    allVariables: {
      handler: function () {
        this.variables = this.allVariables
      },
    },
    formObject: {
      handler(val) {
        this.checkedVariables.splice(0)
        if (val) {
          this.formId = val.id
          this.formName = val.name
          val.fields.forEach((variable) => {
            this.checkedVariables.push(variable.var_name)
          })
        } else {
          this.formId = ''
          this.formName = ''
        }
      },
    },
  },
  methods: {
    saveForm() {
      this.$emit('saveForm', this.formId, this.formName, this.checkedVariables)
      this.checkedVariables.splice(0)
      this.formId = ''
      this.formName = ''
    },
  },
}
</script>
