var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bubble-editor" }, [
    _c("h4", [
      _vm._v(_vm._s(_vm.$t("__bubble")) + " " + _vm._s(_vm.index + 1))
    ]),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("__image")))]),
      _c(
        "div",
        { staticClass: "form-check" },
        [
          _c("vue-dropzone", {
            ref: "bubbleImageDropzone",
            attrs: { id: "bubbleImageDropzone", options: _vm.dropzoneOptions },
            on: { "vdropzone-file-added": _vm.handleUploadImage }
          })
        ],
        1
      ),
      _vm.bubble.image.url
        ? _c("div", { staticClass: "mt-2" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("__imageUrl")))]),
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "text", readonly: "", disabled: "" },
              domProps: { value: _vm.bubble.image.url }
            }),
            _c("label", [_vm._v(_vm._s(_vm.$t("__imageLinkUri")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.bubble.image.linkUri,
                  expression: "bubble.image.linkUri"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", maxlength: "2000", required: "" },
              domProps: { value: _vm.bubble.image.linkUri },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.bubble.image, "linkUri", $event.target.value)
                  },
                  _vm.updateBubble
                ]
              }
            })
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "border my-3" }),
    _c(
      "button",
      {
        staticClass: "btn btn-danger mt-3",
        on: {
          click: function($event) {
            return _vm.$emit("deleteBubble")
          }
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("__deleteBubble")) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }