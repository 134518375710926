<template>
  <div>
    <!-- Loading and Error States -->
    <div v-if="loading">Loading...</div>
    <div v-if="error" class="error">{{ error }}</div>

    <!-- Settings Form -->
    <form v-if="!loading && !error" @submit.prevent="updateAccount">
      <!-- Ccid -->
      <h4>ccid</h4>
      <hr />
      <div class="form-group row">
        <label for="icon_url" class="col-sm-2 col-form-label">{{ this.$t('ccid') }}</label>
        <div class="col-sm-6">
          <input type="text" id="ccid" v-model="form.ccid" class="form-control" />
        </div>
      </div>
      <!-- Icon URL -->
      <h4>Icon</h4>
      <hr />
      <div class="form-group row">
        <label for="icon_url" class="col-sm-2 col-form-label">{{ this.$t('__iconUrl') }}</label>
        <div class="col-sm-6">
          <input type="text" id="icon_url" v-model="form.icon_url" class="form-control" />
          <br />
          <input type="file" @change="onImageUpload($event, 'icon_url')" />
        </div>
      </div>
      <!-- Icon Preview -->
      <div class="form-group row" v-if="form.icon_url">
        <div class="col-sm-2 col-form-label">
          {{ $t('__iconPreview') }}
        </div>
        <div class="col-sm-6">
          <img
            :src="form.icon_url"
            alt="icon preview"
            style="max-width: 100px; border: 1px solid #ccc"
          />
        </div>
      </div>

      <!-- Header Background Color -->
      <h4>Header</h4>
      <hr />

      <div class="form-group row">
        <label for="header_background_color" class="col-sm-2 col-form-label">
          {{ this.$t('__headerBackgroundColor') }}
        </label>

        <div class="col-sm-6">
          <ColorInput v-model="form.header_background_color" />
        </div>
      </div>

      <!-- Header Title -->
      <div class="form-group row">
        <label for="header_title" class="col-sm-2 col-form-label">{{
          this.$t('__headerTitle')
        }}</label>
        <div class="col-sm-6">
          <input
            type="text"
            id="header_title"
            v-model="form.header_title"
            class="form-control"
            :maxlength="titleTextLimit"
          />
          <p>{{ form.header_title.length }}/{{ titleTextLimit }}</p>
        </div>
      </div>

      <div class="form-group row">
        <label for="header_title__font_size" class="col-sm-2 col-form-label">
          {{ this.$t('__headerTitleFontSize') }}
        </label>
        <div class="col-sm-6">
          <select
            id="header_title_font_size"
            v-model="form.header_title_font_size"
            class="form-control"
          >
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </select>
        </div>
      </div>

      <!-- Header title color -->
      <div class="form-group row">
        <label for="header_title_color" class="col-sm-2 col-form-label">
          {{ this.$t('__headerTitleColor') }}
        </label>
        <div class="col-sm-6">
          <ColorInput v-model="form.header_title_color" />
        </div>
      </div>

      <!-- Bubble -->
      <h4>Bubble</h4>
      <hr />

      <div class="form-group row">
        <label for="bot_bubble_text_color" class="col-sm-2 col-form-label">
          {{ this.$t('__botBubbleTextColor') }}
        </label>
        <div class="col-sm-6">
          <ColorInput v-model="form.bot_bubble_text_color" />
        </div>
      </div>

      <div class="form-group row">
        <label for="user_bubble_text_color" class="col-sm-2 col-form-label">
          {{ this.$t('__userBubbleTextColor') }}
        </label>
        <div class="col-sm-6">
          <ColorInput v-model="form.user_bubble_text_color" />
        </div>
      </div>

      <div class="form-group row">
        <label for="bot_bubble_background_color" class="col-sm-2 col-form-label">
          {{ this.$t('__botBubbleBackgroundColor') }}
        </label>
        <div class="col-sm-6">
          <ColorInput v-model="form.bot_bubble_background_color" />
        </div>
      </div>

      <div class="form-group row">
        <label for="user_bubble_background_color" class="col-sm-2 col-form-label">
          {{ this.$t('__userBubbleBackgroundColor') }}
        </label>
        <div class="col-sm-6">
          <ColorInput v-model="form.user_bubble_background_color" />
        </div>
      </div>

      <div class="form-group row">
        <label for="timestamp_text_color" class="col-sm-2 col-form-label">
          {{ this.$t('__timestampTextColor') }}
        </label>
        <div class="col-sm-6">
          <ColorInput v-model="form.timestamp_text_color" />
        </div>
      </div>

      <!-- Textarea Placeholder -->
      <h4>Textarea</h4>
      <hr />
      <div class="form-group row">
        <label for="textarea_placeholder_text" class="col-sm-2 col-form-label">
          {{ this.$t('__textareaPlaceholder') }}
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            id="textarea_placeholder_text"
            v-model="form.textarea_placeholder_text"
            class="form-control"
          />
        </div>
      </div>

      <!-- Choose Background Type -->
      <h4>Chat Area</h4>
      <hr />
      <div class="form-group row">
        <label for="background_type" class="col-sm-2 col-form-label">
          {{ this.$t('__backgroundType') }}
        </label>
        <div class="col-sm-6">
          <select id="background_type" v-model="backgroundType" class="form-control">
            <option value="color">{{ this.$t('__useBackgroundColor') }}</option>
            <option value="url">{{ this.$t('__useBackgroundImage') }}</option>
          </select>
        </div>
      </div>

      <!-- Chat Area Background Color -->
      <div class="form-group row" v-if="backgroundType === 'color'">
        <label for="chat_area_background_color" class="col-sm-2 col-form-label">
          {{ this.$t('__chatAreaBackgroundColor') }}
        </label>
        <div class="col-sm-6">
          <ColorInput v-model="form.chat_area_background_color" />
        </div>
      </div>

      <!-- Chat Area Background Image URL -->
      <div class="form-group row" v-if="backgroundType === 'url'">
        <label for="chat_area_background_image_url" class="col-sm-2 col-form-label">
          {{ this.$t('__chatAreaSpBackgroundImageUrl') }}
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            id="chat_area_background_image_url"
            v-model="form.chat_area_background_image_url"
            class="form-control"
          />
          <br />
          <input type="file" @change="onImageUpload($event, 'chat_area_background_image_url')" />
        </div>
      </div>

      <!-- Background Image Preview -->
      <div
        class="form-group row"
        v-show="form.chat_area_background_image_url && backgroundType === 'url'"
      >
        <div class="col-sm-2 col-form-label">
          {{ $t('__backgroundImagePreview') }}
        </div>
        <div class="col-sm-6">
          <img
            :src="form.chat_area_background_image_url"
            alt="icon preview"
            style="max-width: 100px; border: 1px solid #ccc"
          />
        </div>
      </div>
      <!-- Chat Area PC Background Image URL -->
      <div class="form-group row" v-if="backgroundType === 'url'">
        <label for="chat_area_pc_background_image_url" class="col-sm-2 col-form-label">
          {{ this.$t('__chatAreaPcBackgroundImageUrl') }}
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            id="chat_area_pc_background_image_url"
            v-model="form.chat_area_pc_background_image_url"
            class="form-control"
          />
          <br />
          <input type="file" @change="onImageUpload($event, 'chat_area_pc_background_image_url')" />
        </div>
      </div>

      <!-- Background Image Preview -->
      <div
        class="form-group row"
        v-show="form.chat_area_pc_background_image_url && backgroundType === 'url'"
      >
        <div class="col-sm-2 col-form-label">
          {{ $t('__backgroundImagePreview') }}
        </div>
        <div class="col-sm-6">
          <img
            :src="form.chat_area_pc_background_image_url"
            alt="icon preview"
            style="max-width: 100px; border: 1px solid #ccc"
          />
        </div>
      </div>

      <!-- Default Font Size -->
      <h4>Font Size</h4>
      <hr />
      <div class="form-group row">
        <label for="default_font_size" class="col-sm-2 col-form-label">
          {{ this.$t('__defaultFontSize') }}
        </label>
        <div class="col-sm-6">
          <select id="default_font_size" v-model="form.default_font_size" class="form-control">
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="rich_menu__font_size" class="col-sm-2 col-form-label">
          {{ this.$t('__richMenuFontSize') }}
        </label>
        <div class="col-sm-6">
          <select id="rich_menu_font_size" v-model="form.rich_menu_font_size" class="form-control">
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </select>
        </div>
      </div>

      <!-- Toggler Section for Rich Menu and Message Bar -->
      <h4>Toggle Components</h4>
      <hr />
      <div class="form-group row">
        <label for="toggle_rich_menu" class="col-sm-2 col-form-label">
          {{ this.$t('__richMenu') }}
        </label>
        <div class="col-sm-6">
          <label class="switch">
            <input type="checkbox" id="toggle_rich_menu" v-model="form.rich_menu" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div class="form-group row">
        <label for="toggle_message_bar" class="col-sm-2 col-form-label">
          {{ this.$t('__messageBar') }}
        </label>
        <div class="col-sm-6">
          <label class="switch">
            <input type="checkbox" id="toggle_message_bar" v-model="form.message_bar" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <!-- PC position and SP position -->
      <h4>Position</h4>
      <hr />
      <div class="form-group row">
        <label for="default_font_size" class="col-sm-2 col-form-label">
          {{ this.$t('__pcPosition') }}
        </label>
        <div class="col-sm-6">
          <select id="default_font_size" v-model="form.pc_position" class="form-control">
            <option value="center">center</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="default_font_size" class="col-sm-2 col-form-label">
          {{ this.$t('__spPosition') }}
        </label>
        <div class="col-sm-6">
          <select id="default_font_size" v-model="form.sp_position" class="form-control">
            <option value="full">full</option>
            <option value="90%">90%</option>
          </select>
        </div>
      </div>

      <!-- EFO mode -->
      <h4>EFO mode</h4>
      <hr />
      <div class="form-group row">
        <label for="toggle_rich_menu" class="col-sm-2 col-form-label">
          {{ this.$t('__isEfo') }}
        </label>
        <div class="col-sm-6">
          <label class="switch">
            <input type="checkbox" id="toggle_rich_menu" v-model="form.is_efo" />
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="form-group row">
        <label for="icon_url" class="col-sm-2 col-form-label">{{ this.$t('__iconUrl') }}</label>
        <div class="col-sm-6">
          <input type="text" id="icon_url" v-model="form.efo_icon_url" class="form-control" />
          <br />
          <input type="file" @change="onImageUpload($event, 'efo_icon_url')" />
        </div>
      </div>
      <!-- Icon Preview -->
      <div class="form-group row" v-if="form.efo_icon_url">
        <div class="col-sm-2 col-form-label">
          {{ $t('__iconPreview') }}
        </div>
        <div class="col-sm-6">
          <img
            :src="form.efo_icon_url"
            alt="icon preview"
            style="max-width: 100px; border: 1px solid #ccc"
          />
        </div>
      </div>

      <!-- Powered by chichat -->
      <h4>Powered by chichat</h4>
      <hr />

      <div class="form-group row">
        <label for="powered_by_chichat_link_uri" class="col-sm-2 col-form-label">{{
          this.$t('__linkUri')
        }}</label>
        <div class="col-sm-6">
          <input
            type="text"
            id="powered_by_chichat_link_uri"
            v-model="form.powered_by_chichat_link_uri"
            class="form-control"
          />
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-sm-6 offset-sm-2">
          <button type="submit" class="btn btn-primary">{{ this.$t('__updateSetting') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import ColorInput from '../../components/Inputs/ColorInput.vue'
import { Utility } from '../../utility'
import { apiFunction } from './Axios/functions'
export default {
  data() {
    return {
      form: null,
      loading: true,
      error: null,
      accountType: 'webbot',
      backgroundType: 'color',
    }
  },
  components: {
    ColorInput,
  },
  computed: {
    titleTextLimit() {
      if (!this.form) return 0
      const size = this.form.header_title_font_size

      if (size === 'sm') return 14
      else if (size === 'md') return 12
      else return 10
    },
  },
  watch: {
    titleTextLimit(newValue, oldValue) {
      if (!this.form) return
      if (newValue < oldValue) {
        this.form.header_title = this.form.header_title.slice(0, newValue)
      }
    },
  },
  methods: {
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(',')
        this.adminId = decrypt[0]
        this.accountId = decrypt[1]
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }
      this.getAccountInfo()
    },
    onImageUpload(event, targetField) {
      const file = event.target.files[0]
      if (!file) return
      Utility.uploadImage(this.adminId, this.accountId, this.jwtToken, file)
        .then((res) => {
          this.form[targetField] = res.url
        })
        .catch((err) => {
          console.error('Image upload failed:', err)
        })
    },
    // Get account info
    getAccountInfo() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
      }

      apiFunction.getAccountInfo(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          const accountInfo = data.data.Result.data.webbotAccountInfo || {}
          const accountType = data.data.Result.data.accountType
          this.backgroundType = accountInfo.chat_area_background_image_url ? 'url' : 'color'

          this.form = {
            ccid: accountInfo.ccid || '',
            icon_url: accountInfo.icon_url || '',
            header_background_color: accountInfo.header_background_color || '',
            header_title: accountInfo.header_title || '',
            header_title_font_size: accountInfo.header_title_font_size || 'md',
            header_title_color: accountInfo.header_title_color || '',
            bot_bubble_text_color: accountInfo.bot_bubble_text_color || '',
            user_bubble_text_color: accountInfo.user_bubble_text_color || '',
            bot_bubble_background_color: accountInfo.bot_bubble_background_color || '',
            user_bubble_background_color: accountInfo.user_bubble_background_color || '',
            timestamp_text_color: accountInfo.timestamp_text_color || '',
            textarea_placeholder_text: accountInfo.textarea_placeholder_text || '',
            chat_area_background_color: accountInfo.chat_area_background_color || '',
            chat_area_background_image_url: accountInfo.chat_area_background_image_url || '',
            chat_area_pc_background_image_url: accountInfo.chat_area_pc_background_image_url || '',
            default_font_size: accountInfo.default_font_size || 'md',
            rich_menu_font_size: accountInfo.rich_menu_font_size || 'md',
            rich_menu: accountInfo.rich_menu || false,
            message_bar: accountInfo.message_bar || false,
            pc_position: accountInfo.pc_position || 'center',
            sp_position: accountInfo.sp_position || 'full',
            is_efo: accountInfo.is_efo || false,
            efo_icon_url: accountInfo.efo_icon_url || '',
            powered_by_chichat_link_uri: accountInfo.powered_by_chichat_link_uri || '',
          }
          this.accountType = accountType
          this.loading = false
        }
      })
    },

    uploadScript() {
      let payload = {
        ccid: this.form.ccid,
        pc_position: this.form.pc_position,
        sp_position: this.form.sp_position,
        is_parrot_upload: true,
        is_efo: this.form.is_efo,
        parrot_id: this.accountId,
        efo_icon_url: this.form.efo_icon_url,
        is_dev: process.env.NODE_ENV === 'development',
      }
      return apiFunction.uploadScript(payload)
    },
    // Update account settings
    // Get account info
    updateAccount() {
      // If background type is color, clear the image URL
      if (this.backgroundType === 'color') {
        this.form.chat_area_background_image_url = ''
      }
      let formData = {
        webbotAccountInfo: this.form,
        adminId: this.adminId,
        accountId: this.accountId,
        accountType: this.accountType,
      }

      // Use Promise.all to run both API calls concurrently
      Promise.all([this.uploadScript(), apiFunction.updateAccount(formData, this.jwtToken)])
        .then(([uploadResponse, accountResponse]) => {
          console.log(uploadResponse)
          // Check if both responses are successful
          if (
            uploadResponse &&
            uploadResponse.status === 200 &&
            accountResponse &&
            accountResponse.status === 200
          ) {
            this.$fire({
              title: this.$t('__updatecompleted'),
              text: this.$t('__updatecompleted'),
              type: 'success',
              timer: 1500,
            })
            this.getAccountInfo()
          } else {
            this.$fire({
              title: this.$t('__Settingsnotyetsaved'),
              text: this.$t('__oopsSomethingwentwrong'),
              type: 'error',
            })
          }
        })
        .catch(() => {
          this.$fire({
            title: this.$t('__Settingsnotyetsaved'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        })
    },
  },

  mounted() {
    this.loadData() // Fetch settings when the component is mounted
  },
}
</script>

<style scoped>
.error {
  color: red;
}
.form-group {
  margin-bottom: 15px;
}

/* The switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

/* Hide the default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

/* The slider knob */
.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Toggle switch when checked */
input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
