<template>
  <div>
    <!-- <ul class="nav nav-tabs"> -->
    <draggable
      tag="ul"
      class="nav nav-tabs"
      id="nav-fb-multi-temp-tab"
      v-model="fbGenericElements"
      draggable=".fb-generic-elements"
    >
      <li
        class="nav-item fb-generic-elements"
        v-for="(element, fbElemIndex) in fbGenericElements"
        :key="'nav-template-' + fbElemIndex + '-tab'"
      >
        <button
          v-if="fbElemIndex == 0"
          class="nav-link active"
          :id="'nav-template-' + fbElemIndex + '-tab'"
          data-toggle="tab"
          v-bind:data-target="'#nav-template-' + fbElemIndex"
          role="tab"
          v-bind:aria-controls="'nav-template-' + fbElemIndex"
          aria-selected="true"
          aria-current="page"
          @contextmenu="handleRightClick(fbGenericElements[fbElemIndex], $event)"
        >
          {{ $t('__template') }} {{ fbElemIndex + 1 }}
        </button>
        <button
          v-if="fbElemIndex > 0"
          class="nav-link"
          :id="'nav-template-' + fbElemIndex + '-tab'"
          data-toggle="tab"
          :href="'#nav-template-' + fbElemIndex"
          role="tab"
          v-bind:aria-controls="'nav-template-' + fbElemIndex"
          aria-selected="false"
          @contextmenu="handleRightClick(fbGenericElements[fbElemIndex], $event)"
        >
          {{ $t('__template') }} {{ fbElemIndex + 1 }}
        </button>
      </li>
      <li class="nav-item" v-if="fbGenericElements.length < 10">
        <a
          class="nav-link text-danger"
          id="nav-add-template-tab"
          href="#"
          role="tab"
          @click="addNewFbTemplate()"
          >＋</a
        >
      </li>
    </draggable>
    <!-- </ul> -->
    <div class="tab-content" id="nav-fb-multi-temp-tabContent">
      <div
        class="tab-pane fade"
        v-bind:class="{
          'show active': fbElemIndex === 0,
        }"
        :id="'nav-template-' + fbElemIndex"
        role="tabpanel"
        v-bind:aria-labelledby="'nav-template-' + fbElemIndex + '-tab'"
        v-for="(element, fbElemIndex) in fbGenericElements"
        :key="'nav-template-' + fbElemIndex + '-tabContent'"
      >
        <div class="card">
          <div class="float-right mt-2 mr-2">
            <a
              href="javascript:void(0)"
              class="text-danger float-right"
              @click="deleteFbTemplate(fbElemIndex)"
            >
              <i class="fas fa-trash text-danger"></i>
            </a>
          </div>
          <div class="card-body pb-3">
            <h5 class="card-title">{{ $t('__image') }}</h5>
            <div class="row">
              <div class="col-4">
                <!-- Upload image input-->
                <div class="form-check my-1">
                  <div class="border">
                    <vue-dropzone
                      :ref="'fb-multi-temp-dropzone-' + fbElemIndex"
                      :id="'fb-multi-temp-dropzone-' + fbElemIndex"
                      :options="dropzoneOptions"
                      v-on:vdropzone-file-added="fbGenerateTempsImgHandling($event, fbElemIndex)"
                    ></vue-dropzone>
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="form-check">
                  <label>{{ $t('__urltitle') }}</label>
                  <textarea
                    class="form-control"
                    rows="2"
                    id="text-input"
                    v-model="element.title"
                  ></textarea>
                </div>
                <div class="form-check pt-3">
                  <label>{{ $t('__Textcontent') }}</label>
                  <textarea
                    class="form-control"
                    rows="3"
                    id="text-input"
                    v-model="element.subtitle"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-check pt-3">
                  <label> {{ $t('__ImageUrl') }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="url-pic-upload"
                    :placeholder="$t('__urlPlaceHolder')"
                    v-model="element.imgUrl"
                  />
                </div>
                <div class="form-check pt-3">
                  <label> {{ $t('__urllinkoptional') }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="url-pic-transition"
                    :placeholder="$t('__urlPlaceHolder')"
                    v-model="element.webUrl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t('__Button') }}</h5>
            <div
              class="border p-2 mb-2"
              v-for="(item, btnIndex) in element.buttons"
              :key="btnIndex"
            >
              <div class="float-right">
                <a
                  href="javascript:void(0)"
                  class="text-danger float-right"
                  @click="deleteButton(fbElemIndex, btnIndex)"
                >
                  <i class="fas fa-trash text-danger"></i>
                </a>
              </div>
              <br />
              <!-- Button style -->
              <div class="form-row px-4">
                <!-- Button style / Label -->
                <div class="form-group col-4">
                  <label><span class="text-danger">*</span> {{ $t('__Textcontent') }}</label>
                  <input type="text" class="form-control" v-model="item.action.label" />
                </div>
              </div>
              <!-- Button Tag -->
              <div class="px-4 mt-2 mb-3">
                <label>{{ $t('__label') }}</label>
                <div class="form-row">
                  <!-- Button Tag / tag -->
                  <select
                    class="col-3 form-control ml-2"
                    id="btntag"
                    v-model="item.tag"
                    @focus="buttonTagOnchanged(fbElemIndex, btnIndex)"
                  >
                    <option
                      :value="{
                        color: '',
                        name: '',
                      }"
                      disabled
                      selected
                    >
                      {{ $t('__TagSelectOptional') }}
                    </option>
                    <option v-for="(tag, btnIndex) in tagsInput" :value="tag" :key="100 + btnIndex">
                      {{ tag.name }}
                    </option>
                  </select>
                  <!-- Button Tags / tag value -->
                  <select
                    class="col-3 form-control ml-2"
                    :aria-placeholder="$t('__fontcolor')"
                    v-model="item.tagValue"
                  >
                    <option value disabled selected>
                      {{ $t('__TagValueSelectOptional') }}
                    </option>
                    <option
                      v-for="(value, btnIndex) in valuesOfTag(item.tag.color, item.tag.name)"
                      :value="value"
                      :key="100 + btnIndex"
                    >
                      {{ value }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Button Vars -->
              <div class="px-4 mt-2 mb-3">
                <label>{{ $t('__variable') }}</label>
                <div class="ml-1" v-if="item.userVars.length == 0">
                  <a
                    href="javascript:void(0)"
                    class="text-info m-1"
                    @click="addButtonVar(fbElemIndex, btnIndex)"
                  >
                    <i class="fas fa-plus text-info"></i>
                  </a>
                </div>
                <div v-for="(varItem, varIndex) in item.userVars" :key="varIndex">
                  <div class="form-row mt-2">
                    <select class="col-3 form-control ml-2" v-model="varItem.variable">
                      <option value disabled selected>
                        {{ $t('__UserVariableSelectOptional') }}
                      </option>
                      <option
                        v-for="(value, btnIndex) in allVariables"
                        :value="value"
                        :key="100 + btnIndex"
                      >
                        {{ value }}
                      </option>
                    </select>
                    <input class="col-3 form-control ml-2" type="text" v-model="varItem.value" />
                    <div class="ml-1">
                      <a
                        href="javascript:void(0)"
                        class="text-danger m-1"
                        @click="deleteButtonVar(fbElemIndex, btnIndex, varIndex)"
                        v-if="item.userVars.length > 0"
                      >
                        <i class="fas fa-minus text-danger"></i>
                      </a>
                    </div>
                    <div class="ml-1">
                      <a
                        href="javascript:void(0)"
                        class="text-info m-1"
                        @click="addButtonVar(fbElemIndex, btnIndex)"
                        v-if="varIndex == item.userVars.length - 1"
                      >
                        <i class="fas fa-plus text-info"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Button Action -->
              <div class="form-row px-4 mt-3">
                <div class="form-group col-12 mb-0">
                  <label><span class="text-danger">*</span> {{ $t('__buttonTappedAction') }}</label>
                  <div class="form-group col-auto mb-0">
                    <!-- Button Action / URL -->
                    <div class="form-group col-auto">
                      <label>{{ $t('__jumpToSpecificUrl') }}</label>
                      <div class="form-row align-items-center">
                        <md-radio
                          type="radio"
                          class="ml-3"
                          value="uri"
                          v-model="item.action.nodetype"
                        />
                        <input
                          type="text"
                          class="form-control col-10 ml-0"
                          :placeholder="$t('__urlPlaceHolder')"
                          v-model="item.action.uri"
                          :id="'uri-' + btnIndex"
                          @focus="buttonActionOnchanged('uri', fbElemIndex, btnIndex)"
                        />
                      </div>
                    </div>
                    <div class="form-row col-auto">
                      <!-- Button Action / Next Scenario -->
                      <div class="form-group col-6">
                        <label>{{ $t('__jumpToSpecificScenario') }}</label>
                        <div class="form-row align-items-center">
                          <md-radio
                            type="radio"
                            class="ml-3"
                            value="snode"
                            v-model="item.action.nodetype"
                          />
                          <select
                            class="form-control col-9"
                            id="snode"
                            v-model="item.action.data"
                            @focus="buttonActionOnchanged('snode', fbElemIndex, btnIndex)"
                          >
                            <option value disabled selected>
                              {{ $t('__NextScenariooptional') }}
                            </option>
                            <option
                              v-for="(scenario, btnIndex) in allScenario"
                              :value="scenario.scenario.scenarioId"
                              :key="100 + btnIndex"
                            >
                              {{ scenario.scenario.scenarioName }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Button Action / Next Node -->
                      <div class="form-group col-6">
                        <label>{{ $t('__jumpToSpecificNode') }}</label>
                        <div class="form-row align-items-center">
                          <md-radio
                            type="radio"
                            class="ml-3"
                            value="nnode"
                            v-model="item.action.nodetype"
                          />

                          <select
                            class="form-control col-9"
                            id="nnode"
                            v-model="item.action.data"
                            @focus="buttonActionOnchanged('nnode', fbElemIndex, btnIndex)"
                          >
                            <option value disabled selected>
                              {{ $t('__NextNodeoptional') }}
                            </option>
                            <option :value="node.nodeId" v-for="(node, idx) in allNodes" :key="idx">
                              {{ node.title }} {{ node.subtitle }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row pt-4 add-btn-input cursor-pointer"
              @click="addNewButton(fbElemIndex)"
              v-if="element.buttons.length < 3"
            >
              <a href="javascript:void(0)" class="mx-auto my-3">
                <i class="fas fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import draggable from 'vuedraggable'

export default {
  name: 'fb-generic-template-setting',
  components: {
    vueDropzone: vue2Dropzone,
    draggable,
  },
  props: {
    elementsInput: Array,
    tagsInput: Array,
    allTags: Array,
    allNodes: Array,
    allScenario: Array,
    allVariables: Array,
  },
  data() {
    const uploadText =
      "<div><i class='fa fa-cloud-upload-alt pr-2' style='font-size: 3.5rem;'></i><h4>" +
      this.$t('__Clickordragtoupload') +
      '</h4></div>'
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 500,
        addRemoveLinks: true,
        uploadMultiple: true,
        maxFiles: 1,
        dictDefaultMessage: uploadText,
      },
      fbGenericElements: this.elementsInput,
    }
  },
  watch: {
    fbGenericElements: {
      deep: true,
      handler(val) {
        this.$emit('update', 'fbGenericElements', val)
      },
    },
  },
  methods: {
    handleRightClick(fbGenericElement, event) {
      event.preventDefault()
      this.copyThisTemplate(fbGenericElement)
    },
    copyThisTemplate(fbGenericElement) {
      this.fbGenericElements.push({
        title: fbGenericElement['title'],
        subtitle: fbGenericElement['subtitle'],
        imgUrl: fbGenericElement['imgUrl'],
        webUrl: fbGenericElement['webUrl'],
        buttons: fbGenericElement['buttons'],
      })
    },
    addNewFbTemplate() {
      this.fbGenericElements.push({
        title: '',
        subtitle: '',
        imgUrl: '',
        webUrl: '',
        buttons: [],
      })
    },
    deleteFbTemplate(fbElemIndex) {
      this.fbGenericElements.splice(fbElemIndex, 1)
    },
    addNewButton(fbElemIndex) {
      let buttonForm = {
        action: {
          data: '',
          label: '',
          type: 'uri',
          uri: '',
          nodetype: 'uri',
        },
        tag: {
          color: '',
          name: '',
        },
        tagValue: '',
        userVars: [],
      }
      this.fbGenericElements[fbElemIndex].buttons.push(buttonForm)
    },
    fbGenerateTempsImgHandling(file, fbElemIndex) {
      this.uploadImgFileToImgur(file)
        .then((url) => {
          this.fbGenericElements[fbElemIndex].imgUrl = url
        })
        .catch((err) => {
          console.error(err)
          alert(this.$t('__Imagechangeerror'))
        })
    },
    async uploadImgFileToImgur(file) {
      if (!localStorage.imgur) {
        await this.getImgurAccessToken()
      }
      let token = localStorage.imgur
      let formData = new FormData()
      formData.append('image', file)
      return this.axios({
        method: 'POST',
        url: 'https://imgur-apiv3.p.rapidapi.com/3/image',
        data: formData,
        headers: {
          'x-rapidapi-host': 'imgur-apiv3.p.rapidapi.com',
          'x-rapidapi-key': 'b05d4afeb3msh2e3d558f3538b7cp153622jsn0a8456bae916',
          authorization: 'Bearer ' + token,
          'content-type': 'application/x-www-form-urlencoded',
        },
        mimeType: 'multipart/form-data',
      })
        .then((res) => {
          return Promise.resolve(res.data.data.link)
        })
        .catch((err) => {
          localStorage.removeItem('imgur')
          console.error(err)
          return Promise.reject()
        })
    },
    // Get imgur access token
    async getImgurAccessToken() {
      let client_id = '208d8a2829b83a4'
      let secret = '8acb5af7e2368314866e1c7300471509df22a712'

      let formData = new FormData()
      formData.append('refresh_token', 'ed342a5803077918e62e009d0964dd470fec010a') //required
      formData.append('client_id', client_id)
      formData.append('client_secret', secret)
      formData.append('grant_type', 'refresh_token')

      await this.axios({
        method: 'POST',
        url: 'https://api.imgur.com/oauth2/token',
        data: formData,
      })
        .then((res) => {
          localStorage.imgur = res.data.access_token
          // return (this.token = res.data.access_token);
        })
        .catch((e) => {
          console.log(e)
        })
    },
    // Get value array from all tags
    valuesOfTag(color, name) {
      var values = []
      let tag = this.allTags.find((tag) => tag.color === color && tag.name === name)
      if (tag) {
        values = tag.values
      }
      return values
    },
    addButtonVar(fbElemIndex, btnIndex) {
      this.fbGenericElements[fbElemIndex].buttons[btnIndex].userVars.push({
        variable: '',
        value: '',
      })
    },
    deleteButton(fbElemIndex, Index) {
      this.fbGenericElements[fbElemIndex].buttons.splice(Index, 1)
    },
    deleteButtonVar(fbElemIndex, btnIndex, varIndex) {
      this.fbGenericElements[fbElemIndex].buttons[btnIndex].userVars.splice(varIndex, 1)
    },
    buttonTagOnchanged(fbElemIndex, btnIndex) {
      // Reset tag value
      this.fbGenericElements[fbElemIndex].buttons[btnIndex].tagValue = ''
    },
    buttonActionOnchanged(type, fbElemIndex, btnIndex) {
      this.fbGenericElements[fbElemIndex].buttons[btnIndex].action.nodetype = type
      if (type == 'nnode' || type == 'snode') {
        this.fbGenericElements[fbElemIndex].buttons[btnIndex].action.type = 'postback'
      } else if (type == 'uri') {
        this.fbGenericElements[fbElemIndex].buttons[btnIndex].action.type = 'uri'
      }
    },
  },
}
</script>
