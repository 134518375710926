export default {
  // Login
  __userName: "User Name",
  __password: "Password",
  __loginButton: "Log In",
  __loginFailed: "Login failed.",

  //account
  __dataMissing: "data missing",
  __CopiedToClipboard: "Copied to Clipboard.",
  __line: "LINE",
  __wechat: "WeChat",
  __facebook: "Facebook",
  __instagram: "Instagram",

  __requiredItem: "必須輸入",
  __home: "首頁",
  __dialogsetup: "對話設定",
  __richMenu: "圖形選單",
  __segment: "用戶分類",
  __haveDefaultMenu: "已有預設的richMenu",
  __richAllUser: "所有用戶(預設)",
  __pushMessage: "發送對話",
  __exportData: "匯出資料",
  __externalApiConfig: "聯動信息設定",
  __generalSetup: "一般設定",
  __metaUtils: "導流工具",
  __tagList: "標籤清單",
  __iconSetting: "Icon設定",
  __statisc: "統計分析",
  __node: "節點",
  __Signout: "登出",
  __EmptyAccount: "查無帳號",
  __AccountName: "帳號名稱",
  __Switchaccount: "切換帳號",
  __Createnewlink: "建立新的連結",
  __name: "名稱",
  __username: "帳戶名稱",
  __accountId: "帳戶ID",
  __ConnectionType: "連接類型",
  __changeAccountImage: "修改照片",
  __changeAccountName: "修改帳號名稱",
  __channelSecret_MsgAPI: "Channel Secret (Messaging API)",
  __channelAccessToken_MsgAPI: "Channel Access Token (Messaging API)",
  __channelId_LineLogin: "Channel ID (LINE Login)",
  __channelSecret_LineLogin: "Channel Secret (LINE Login)",
  __Accounticon: "Profile image",
  __establishconnection: "建立連接",
  __accountClone: "複製帳號",
  __addedsuccessfully: "新增成功",
  __FbStartMessage: "開始訊息",
  __FbGreetingMessage: "問候文字",
  __Anewaccounthasbeensuccessfullycreated: "已成功建立一個新的帳號",
  __Addfailed: "新增失敗",
  __PlzPutKeyWord: "請輸入關鍵字",
  __KeyWordExist: "已有此關鍵字",
  __oopsSomethingwentwrong: "有地方出錯了",
  __somethingwentwrongindeletemenu: "刪除RichMenu失敗",
  __Numberofexistingnodes: "現有節點數",
  __Followers: "追隨者",
  __Triggerers: "觸發用戶",
  __Trackusersnow: "現在追蹤用戶",
  __Triggerednow: "觸發用戶",
  __Searchusersnow: "搜尋到的追蹤用戶",
  __Startingdate: "起始日",
  __Enddate: "結束日",
  __Export: "匯出",
  __Outputfailed: "輸出失敗",
  __Pleasefillinallfields: "請填入所有的欄位",
  __ImageSizeIncorrect: "圖片尺寸錯誤",
  __ImageSizeToobig: "圖片大小超過1MB",
  __TabFieldseIncorrect: "Tab選單欄位輸入錯誤",
  __CreateCSVfile: "建立CSV檔",
  __TheCSVfilewassuccessfullyaddedanddownloaded: "成功新增CSV檔並下載",
  __Outputfailed: "輸出失敗",
  __edit: "編輯",
  __Anewconversationhasbeensuccessfullyestablished: "已成功建立一個新的對話",
  __Theappointmentschedulehasbeensuccessfullycreated: "已成功建立預約排程",
  __setup: "建立",
  __Editsuccessfullyaccessed: "已成功存取編輯",
  __Areyousureyouwanttodeleteit: "您確定要刪除嗎",
  __Thesystemwillnotbeabletoretrieveyourdata: "系統將無法取回您的資料",
  __YesIwanttodelete: "是的我要刪除",
  __cancel: "取消",
  __successfullydeleted: "刪除成功",
  __failedtodelete: "刪除失敗",
  __Appointmentscheduling: "預約排程",
  __Yourdatahasbeensuccessfullydeleted: "您的資料已經成功地刪除了",
  __Appointment: "預約時間:",
  __Pleaseselecttheconversationyouwanttosend: "請選擇欲發送的對話:",
  __Conversationname: "對話名稱",
  __MultiDelete: "批量刪除",
  __Nextstep: "下一步",
  __sendingmethod: "發送方式:",
  __Userclassification: "用戶分類",
  __personal: "個人",
  __Pleasechoosetosendto: "請選擇發送對象:",
  __Previous: "上一步",
  __determine: "確定",
  __Pleaseselectaconversation: "請選擇一則對話",
  __Pleaseenterthetime: "請輸入時間",
  __Thenumberofsendingtargetscannotbe0: "發送目標人數不可為0",
  __Sendrecord: "發送紀錄",
  __SendID: "發送ID",
  __Deliverydate: "發送日時",
  __Establishmenttime: "建立時間",
  __MultiCondition: "多重條件",
  __Customgroup: "自訂群組",
  __Selectcategoryconditions: "選擇分類條件:",
  __Selectuser: "選擇用戶:",
  __Searchusers: "搜尋用戶:",
  __Selectall: "選擇全部",
  __DefaultUserSegment: "預設用戶分類",
  __webhookUrl: "Webhook URL",
  __Keepsessionrecords: "保留會話記錄",
  __Sessionrecord: "會話紀錄保留期限系統將自動刪除舊的紀錄",
  __Deleteuserrelatedsettings: "刪除用戶相關設定",
  __Imagechangeerror: "很抱歉圖片上傳發生錯誤,請再次選擇圖片",
  __Clearallusagerecordsthesystemwillonly:
    "清空所有的使用紀錄系統將只保留對話設定的內容",
  __successfullydeleted: "刪除成功",
  __Systemuserinformationhasbeencleared: "已經清空系統用戶資料",
  __failedtodelete: "刪除失敗",
  __Manualsending: "手動發送",
  __Userjoin: "用戶加入",
  __Keyword: "關鍵字",
  __Errormessage: "錯誤信息",
  __Editsuccessfullyaccessed: "已成功存取編輯",
  __Theerrormessageoruserjoinmessagealreadyexists:
    "錯誤訊息或用戶加入訊息已經存在",
  __Theerrormessagealreadyexists: "錯誤訊息已經存在",
  __Userjoinmessagealreadyexists: "用戶加入訊息已經存在",
  __Copycurrenttarget: "複製當前目標",
  __Createacopyofthisconversationwiththenode: "將此對話與節點建立一個副本",
  __Add: "新增",
  __cancel: "取消",
  __Anewcopyhasbeencreated: "已建立一個新的副本",
  __Areyousureyouwanttodeleteit: "您確定要刪除嗎",
  __Thesystemwillnotbeabletoretrieveyourdata: "系統將無法取回您的資料",
  __SendID: "發送ID",
  __Pushtarget: "推播對象",
  __sendstatus: "發送狀態",
  __Scheduleddeliverydate: "預定發送日時",
  __1day: "1天",
  __10days: "10天",
  __30days: "30天",
  __60days: "60天",
  __90days: "90天",
  __180days: "180天",
  __Theappointmentschedulehasbeensuccessfullycreated: "已成功建立預約排程",
  __Anewcategoryhasbeensuccessfullycreated: "已成功建立一個新的分類",
  __Editsuccessfullyaccessed: "已成功存取編輯",
  __blockade: "封鎖",
  __Active: "啟用中",
  __Deliverytype: "發送類型",
  __Pleaseselectthedestination: "請選擇發送目標",
  __Cancelall: "取消全部",
  __Customgroup: "自訂群組",
  __FollowAccountTime: "加入好友的日期",
  __Nolabelsavailable: "沒有可使用的標籤",
  __Novariablesavailable: "沒有可用的使用者變數",
  __Selectuser: "選擇用戶",
  __SelectInterval: "選擇區間",
  __SelectCvParam: "選擇CV參數",
  __CvParam: "CV參數",
  __ServerPushTime: "伺服器推播",
  __Nousersfound: "查無用戶",
  __createSegmentInputError: "Input error",
  __createSegmentNoSelectTags: "No tags selected.",
  __createSegmentNoSelectInterval: "No interval selected.",
  __createSegmentNoCvValue: "No CV value.",
  __Userlist: "用戶清單",
  __segmentcondition: "有值",
  __segmentnocondition: "沒有值",
  __segmentnoexist: "不存在",
  __CategoryName: "分類名稱",
  __Classificationrules: "分類規則",
  __Numberofpeopleincategory: "分類中的人數",
  __segmentedUsers: "分類中的人",
  __source: "來源",
  __value: "值",
  __Sendconversation: "發送對話",
  __Addlabel: "新增標籤",
  __AddUserIcon: "新增Icon",
  __editLabel: "編輯標籤",
  __AddVariable: "新增變數",
  __AddRichMenu: "新增選單",
  __EditRichMenu: "編輯選單",
  __NextScenariooptional: "NextScenario選填",
  __TagValueSelectOptional: "Tag Value選填",
  __UserVariableSelectOptional: "Variable選填",
  __Titlebold: "標題粗體字",
  __flexMsgTitle: "FlexMessage標題",
  __richMsgTitle: "RichMessage標題",
  __image: "圖片",
  __Subtitlebold: "副標題粗體字",
  __Textcontent: "文字內容",
  __Texture: "貼圖",
  __Button: "按鈕",
  __Jump: "繼續發送",
  __Sendbyappointment: "預約發送",
  __RemoveVariable: "變數消除",
  __Externalapisetting: "外部api連接設定",
  __Titlebold: "標題粗體字",
  __ImageVideoMessage: "圖片/影片訊息",
  __ImageUrl: "圖片url",
  __VideoUrl: "影片url",
  __urltitle: "圖片標題",
  __Textcontent: "文字內容",
  __colour: "顏色",
  __Buttondirection: "按鈕方向",
  __Horizontal: "橫向",
  __Vertical: "縱向",
  __Buttoncolor: "按鈕顏色 #000000",
  __fontcolor: "字體顏色",
  __Fontwhite: "字體白色",
  __Fontblack: "字體黑色",
  __urllinkoptional: "點擊此圖片前往以下url",
  __FlexReplaceTxtHint: "替代文字",
  __userstatuse: "用戶狀態",
  __Followtime: "追隨時間",
  __Labelname: "標籤名稱",
  __IconName: "Icon名稱",
  __Variablename: "變數名稱",
  __RichMenuname: "選單名稱",
  __RichMenuText: "選單文字",
  __Chooselabelcolor: "選擇標籤顏色",
  __Thenewlabelmustbereusedafterthestoragenode:
    "新的標籤必須在儲存節點後才可重複使用",
  __Createnewlabel: "建立新的標籤",
  __saveEditTag: "儲存標籤",
  __CreateNewUserIcon: "建立新的icon",
  __saveUserIcon: "儲存Icon",
  __editUserIcon: "編輯Icon",
  __deleteUserIcon: "刪除Icon",
  __Createnewvariable: "建立新的變數",
  __CreatenewRichMenu: "建立新的圖形選單",
  __saveRichMenu: "儲存圖形選單",
  __Pleaseenteranameandchooseacolor: "請輸入名稱和顏色",
  __PleaseEnterAllRequiredField: "請輸入所有必填欄位",
  __Settingsnotyetsaved: "尚未儲存設定",
  __Thesystemwillnotbeabletokeeptheunsaveddataareyousureyouwanttoleavethecurrentpage:
    "系統將無法保留未儲存的資料確定要離開當前頁面嗎",
  __updatecompleted: "更新成功",
  __Abouttojumpbacktothedialogsettingpage: "即將跳轉回對話設定頁面",
  __Lv1startingpointistheonlyitemthatmustbeset:
    "Lv1 起始點是唯一且為必須設定的項目",
  __cannotSaveScenarioWithoutLv1Node: "Can't save scenario without Lv1's node.",
  __Custom: "自訂",
  __packageID: "Package ID",
  __stickerID: "Sticker ID",
  __Linktonextnode: "連結下個節點",
  __WhenusingFlexMessageyoucanuse: "使用Flex Message時, 妳可以透過",
  __Viewtheeditedcontentinthewindowinstantly: "在視窗中即時觀看編輯內容",
  __Sentconversation: "發送的對話",
  __Rear: "後",
  __Clickordragtoupload: "點擊或拖曳上傳",
  __characterinput: "请输入空格以外的文字。",
  __Areyousureyouwanttodeletethelabel: "您確定要刪除標籤嗎",
  __AreyousureyouwanttodeletetheVariable: "您確定要刪除使用者變數嗎",
  __Thesystemwillnotbeabletoretrieveyourdata: "系統將無法取回您的資料",
  __Triggerpoint: "觸發點",
  __Numberofnodes: "現有節點數",
  __Editdateandtime: "編輯時間",
  __createNewNode: "建立新的節點",
  __label: "標籤",
  __variable: "使用者變數",
  __systemReply: "自動回覆",
  __UnabletodeleteLevellabel: "無法刪除 Level 標籤",
  __days: "曰",
  __hours: "時",
  __minutes: "分",
  __StickerList: "可用貼圖清單",
  __cancelupload: "確定要取消上傳嗎？",
  __getFollowers: "更新用戸",
  __getFollowersConfirmMessage:
    'When LINE offical account type is "Unverified", follower update feature cannot execute. Do you execute this?',
  __getFollowersConfirm: "Execute",
  __getFollowersCancel: "Cancel",
  __updateFollowers:
    "正在從SNS獲得追蹤用戶。此過程可能需要幾分鐘。該過程在後台運行，因此請重新打開頁面。",
  __authExpired: "登入逾時",
  __pleaseLogin: "請重新登入",
  __TagName: "Tag名稱",
  __TagColor: "Tag顏色",
  __nodeNameConditionUrl: "條件（URL）",
  __nodeNameConditionSegment: "條件（用戶分類）",

  // UserIcon
  __newIconCreated: "Icon已建立",

  // TagList
  __newTagCreated: "標籤已建立",

  // PushMessage
  __statusPending: "Pending",
  __statusDone: "Done",
  __statusIssue: "Failed",

  // ExportData
  __invalidPeriodInputed: "輸入無效期限",
  __noData: "no data",
  __csvHeaderUserId: "User ID",
  __csvHeaderUserName: "User Name",
  __csvHeaderUserPicture: "User Picture",
  __csvHeaderAccountType: "Account Type",
  __csvHeaderAccountStatus: "Account Status",
  __csvHeaderNodeId: "Node Id",
  __csvHeaderTags: "Tags",
  __csvHeaderFollowTime: "Followed Time",
  __csvHeaderAccessTime: "Accessed Time",
  __csvHeaderCreateTime: "Created Time",
  __csvBodyAccountTypeLine: "LINE",
  __csvBodyAccountTypeWeChat: "WeChat",
  __csvBodyAccountStatusEnabled: "Following",
  __csvBodyAccountStatusUnfollow: "Unfollowed",

  // Story
  __keywords: "Keywords",

  //CreateEditStory（ストーリー編集ダイアログ）
  __keywordInput: "輸入新增關鍵字",

  // CreateEditNode（ノード編集ダイアログ）
  __flexmessageSize: "訊息尺寸",
  __flexmessageSizeChange: "Flex Message尺寸設定",
  __setSize: "選擇大小",
  __modalTitleNodeId: "Node ID",
  __Condition: "條件",
  __Message: "訊息",
  __Titlebold: "標題粗體字",
  __image: "圖片",
  __Subtitlebold: "副標題粗體字",
  __Textcontent: "文字內容",
  __Texture: "貼圖",
  __MenuName: "Menu名稱",
  __MenuId: "MenuId",
  __Button: "按鈕",
  __Jump: "繼續發送",
  __Sendbyappointment: "預約發送",
  __urlPlaceHolder: "http://... or https://...",
  __colour: "顏色",
  __Buttontext: "按鈕文字內容",
  __Buttondirection: "按鈕方向",
  __Horizontal: "橫向",
  __Vertical: "縱向",
  __Buttoncolor: "按鈕顏色",
  __buttonColorGreen: "Green",
  __buttonColorBlue: "Blue",
  __buttonColorRed: "Red",
  __buttonColorYellow: "Yellow",
  __buttonColorGray: "Gray",
  __buttonColorBlack: "Black",
  __setHexColorCode: "設置十六進制Color Code",
  __fontcolor: "字體顏色",
  __Fontwhite: "字體白色",
  __Fontblack: "字體黑色",
  __labelSettings: "標籤設定",
  __TagSelectOptional: "Tag選填",
  __UserIconSelectOptional: "Icon選填",
  __CrosOptional: "CROS項目選填",
  __CrosTypeOptional: "CROS類型選填",
  __TagValueSelectOptional: "Tag Value選填",
  __buttonTappedAction: "點擊按鈕時",
  __jumpToSpecificUrl: "前往指定的URL",
  __jumpToSpecificScenario: "前往指定的Scenario",
  __jumpToSpecificNode: "前往指定的Node",
  __NextNodeoptional: "NextNode選填",
  __NextScenariooptional: "NextScenario選填",
  __conditionUrl: "條件URL",
  __branchSettings: "分支設置",
  __whenMatching: "與此相同時",
  __jumpTo: "前往指定的",
  __invalidScheduleTimeError: "輸入預約時間無效",
  __flexMessage: "Flex Message",
  __template: "範本",
  __templateUnion: "範本組合",
  __genericTemplate: "一般型範本",
  __stickerRefUrl:
    "https://developers.line.biz/en/docs/messaging-api/sticker-list/",
  __ecsiteBox: "連接Penglue",
  __chichatcatBox: "連接ChiChatCat",
  __encryption: "加密",

  //RichMenu
  __background: "背景",
  __selectImage: "選擇圖片",
  __richMsgLayout: "版型",
  __workTime: "運行期間",
  __richMsgAliasLayout: "使用選單切換",
  __action: "動作",
  __menuId: "Menu ID",
  __menuName: "Menu名稱",
  __switchMenuName: "關聯menu名稱",
  __xAxis: "X軸",
  __yAxis: "Y軸",
  __tabWidth: "寬度",
  __tabHeight: "高度",
  __open_menu: "選單預設開啟",

  // CarouselMessage

  __subtitle: "副標題",
  __title: "標題",
  __textColor: "文字顏色",
  __buttonColor: "按鈕顏色",
  __fontColor: "字體顏色",
  __buttonText: "按鈕文字",
  __uri: "URI",
  __actionType: "動作",
  __titleColor: "標題色",
  __subtitleColor: "副標題色",
  __addButton: "新增按鈕",
  __deleteButton: "刪除按鈕",
  __addBubble: "新增泡泡",
  __deleteBubble: "刪除泡泡",
  __buttonLabel: "按鈕文字",
  __button: "按鈕",
  __enterUri: "輸入URI",
  __enterText: "輸入關鍵字",
  __bubble: "泡泡",
  __imageUrl: "圖片URL",
  __imageAspectRatio: "圖片比例",
  __imageLinkUri: "圖片遷移連結",

  // User Reply
  __Userreply: "使用者回覆",
  __UserreplyVariableType: "變數類別",
  __UserreplyVariableType_Eng: "英文",
  __UserreplyVariableType_Num: "數字",
  __UserreplyVariableType_Len: "字數",
  __UserreplyVariableType_RegExp: "正規表達式",
  __UserreplyVariableType_Phone: "電話號碼",
  __UserreplyVariableType_Post: "郵遞區號",
  __UserreplyVariableType_City: "縣市名稱",
  __VariableFormatValidateSuccess: "驗證成功",
  __VariableFormatValidateFailed: "驗證失敗",

  // ExternalApiConfig
  __EndPoint: "API URL",
  __ClientId: "Client ID",
  __ApiLink: "連携",
  __ExternalapiEnable: "外部api連接啟用",
  __ExternalapiName: "連接的API",
  __ApiResponseSuccess: "API連接成功",
  __ApiResponseFailed: "API連接失敗",
  __OrderIdReplaceEnable: "OrderId文字取代啟用",
  __SelectErrorCode: "選擇錯誤代碼",

  // Pagination
  __paginationText:
    "顯示 {from} 到 {to}筆 共 {count} 筆記錄|{count} 筆記錄|1 筆記錄",

  // Text Message
  __Emoji: "繪文字",
  __EmojiProdId: "Product ID",
  __EmojiId: "繪文字 ID",
  __QuickReply: "快速回覆",
  __QuickReplyAction: "動作",
  __QuickReplyPostback: "回傳操作",
  __QuickReplyMessage: "訊息",
  __QuickReplyUri: "URI 動作",
  __QuickReplyTimepicker: "日期和時間",

  //datetimepicker
  __timepickerText: "日期與時間",
  __timepickerVariable: "時間變數",
  __timepickerInitialTime: "初始時間",
  __timepickerMinTime: "最小時間",
  __timepickerMaxTime: "最大時間",
  __timepickerStartTime: "開始時間",
  __timepickerEndTime: "結束時間",
  __FbtagNormal: "一般訊息",
  __FbtagAccountUpdate: "帳號更新",
  __FbtagConfirmEvenUpdate: "活動更新提醒",
  __FbtagPurchaseUpdate: "購買行為更新",
  __DataRange: "日期區間",
  __DatatimeRange: "日期時間區間",
  __NumberRange: "數值範圍",
  __LessThanNumber: "小於數值",
  __GreaterThanNumber: "大於數值",
  __ExactlyMatch: "完全一致",
  __FuzzyMatch: "部分一致",
  __Start: "開始",
  __End: "結束",

  //varForm
  __Form: "表單",

  // datetime_trigger
  __DateTimeTrigger: "觸發區間",
  __DateTimeTriggerEnable: "使用觸發區間",
  __DateTimeTriggerStart: "開始時間",
  __DateTimeTriggerEnd: "結束時間",

  // 用戶清單
  __userlist_filter: "篩選",
  __userlist_matchAll: "符合所有",
  __userlist_matchAny: "符合任一",
  __userlist_notMatchAny: "不符任一",
  __userlist_notMatchAll: "不符所有",
  __userlist_addNewFilter: "+新增條件",
  __userlist_filterType: "篩選類型",
  __userlist_userTag: "使用者標籤",
  __userlist_userReply: "使用者回覆",
  __userlist_userFirst: "初次互動時間",
  __userlist_userLast: "最後互動時間",
  __userlist_userSegment: "用戶分類",
  __userlist_userBlock: "黑名單",
  __userlist_userBlockWhite: "白名單",
  __userlist_query: "查詢",
  __userlist_reset: "重設",
  __userlist_tagAdd: "標籤新增",
  __userlist_noValue: "無value",
  __userlist_addTag: "新增標籤",
  __userlist_tagRemove: "標籤移除",
  __userlist_removeTag: "移除標籤",
  __userlist_unblock: "解除黑名單",
  __userlist_matchType: "比對方式",
  __userlist_delete: "刪除",
  __userlist_hasValue: "有值",
  __userlist_notExist: "不存在",
  __userlist_segmentName: "用戶分類名稱",
  __userlist_lastUpdateTime: "最後更新時間",
  __userlist_timeStart: "開始時間",
  __userList_timeEnd: "結束時間",
  __userList_noTagsSelected: "尚未選擇任何標籤",
  __userList_noUserOrAllSelected: "尚未選擇任何用戶",

  //導流工具條件
  __autoReplyComments: "好友留言",
  __shortLink: "好友開啟網址",
  __autoReplyLiveMention: "好友動態",
  __autoReplyMedia: "好友連續短片",
  __utilsName: "導流工具名稱",
  __utilsId: "導流工具Id",
  __utilsType: "類型",
  __utilsStatus: "狀態",
  __workTime: "運行期間",
  __instagramPost: "Instagram貼文",
  __neetSelectPost: "請選擇貼文",
  __needTypeName: "請輸入工具類型",
  __startScrnario: "開啟對話",
  __defaultReply: "預設回覆",
  __createDefaultReply: "新增預設回覆",
  __conditionReply: "條件回覆",
  __generateShortLink: "短網址生成",
  __generateQRCode: "QRCode生成",
  __createConditionReply: "新增條件回覆",
  __useAutoReply: "啟用公開及私訊組合回覆",
  __addConditionField: "新增觸發條件",
  __setCondition: "條件設定",
  __messageContent: "留言內容",
  __friendsCount: "標註朋友個數",
  __conditionContent: "條件內容設定",
  __publicMessage: "公開訊息內容",
  __secretMessage: "私訊訊息選擇",
  __selectedScenario: "選擇發送的Scenario",
  __submit: "確定",
  __exactlyMatch: "含所有關鍵字",
  __fuzzyMatch: "含任一關鍵字",
  __shortVideo: "短片",
  __pleaseEnterName: "請輸入工具名稱",
  __pleaseEnterMediaId: "請選擇貼文",
  __pleaseEnterScenario: "請選擇對話",
  __pleaseEnterDefaultField: "請填寫預設回覆",
  __pleaseEnterConditionFields: "請填寫條件回覆",
  __pleaseEnterConditionTrigger: "請填寫條件回覆中的觸發條件",
  __Anewuitlhasbeensuccessfullycreated: "已成功建立一個新的工具",
};
