<template>
  <card class>
    <!-- content start -->
    <div class="row">
      <div class="form-group col-6">
        <span class="text-danger">*</span> {{ $t('__requiredItem') }}
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
        <label><span class="text-danger">*</span> {{ $t('__Startingdate') }}</label>
        <datetime
          v-model="startTime"
          type="date"
          input-class="form-control col-12"
          input-style="width: 16rem;"
          :max-datetime="currentTime"
        ></datetime>
      </div>
      <div class="form-group col-6">
        <label><span class="text-danger">*</span> {{ $t('__Enddate') }}</label>
        <datetime
          v-model="endTime"
          type="date"
          input-class="form-control col-12"
          input-style="width: 16rem;"
          :min-datetime="startTime"
          :max-datetime="currentTime"
        ></datetime>
      </div>
      <div class="col-2">
        <button class="btn btn-secondary mt-4" @click="downloadCsv()">{{ $t('__Export') }}</button>
      </div>
    </div>
    <!-- content end -->
  </card>
</template>
<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { apiFunction } from './Axios/functions'
import { DateTime } from 'luxon'

const editCsvRawData = (rawData, vueInstance) => {
  let editedData = ''
  const rawCsvLines = rawData.split('\n')

  // header（1行目）の処理
  editedData += convertCsvHeaderString(rawCsvLines[0], vueInstance) + '\n'
  // body（2行目以降）の処理
  for (let i = 1; i < rawCsvLines.length; i++) {
    editedData += convertCsvBodyString(rawCsvLines[i], vueInstance) + '\n'
  }

  return editedData
}

const convertCsvHeaderString = (rawData, vueInstance) => {
  const rawDataArray = rawData.split(',')
  const convertedDataArray = []
  for (let item of rawDataArray) {
    switch (item) {
      case 'userid':
        convertedDataArray.push(vueInstance.$t('__csvHeaderUserId'))
        break
      case 'username':
        convertedDataArray.push(vueInstance.$t('__csvHeaderUserName'))
        break
      case 'userpicture':
        convertedDataArray.push(vueInstance.$t('__csvHeaderUserPicture'))
        break
      case 'accounttype':
        convertedDataArray.push(vueInstance.$t('__csvHeaderAccountType'))
        break
      case 'accountstatus':
        convertedDataArray.push(vueInstance.$t('__csvHeaderAccountStatus'))
        break
      case 'nodeid':
        convertedDataArray.push(vueInstance.$t('__csvHeaderNodeId'))
        break
      case 'tags':
        convertedDataArray.push(vueInstance.$t('__csvHeaderTags'))
        break
      case 'followtime':
        convertedDataArray.push(vueInstance.$t('__csvHeaderFollowTime'))
        break
      case 'accesstime':
        convertedDataArray.push(vueInstance.$t('__csvHeaderAccessTime'))
        break
      case 'createtime':
        convertedDataArray.push(vueInstance.$t('__csvHeaderCreateTime'))
        break
      default:
        convertedDataArray.push(item)
    }
  }
  return convertedDataArray.join(',')
}

const convertCsvBodyString = (rawData, vueInstance) => {
  const rawDataArray = rawData.split(',')
  const convertedDataArray = []
  for (let i = 0; i < rawDataArray.length; i++) {
    if (i == 3) {
      // アカウントタイプ
      if (rawDataArray[i] == 'line') {
        convertedDataArray[i] = vueInstance.$t('__csvBodyAccountTypeLine')
      }
      if (rawDataArray[i] == 'wechat') {
        convertedDataArray[i] = vueInstance.$t('__csvBodyAccountTypeWeChat')
      }
    } else if (i == 4) {
      // アカウント状態
      convertedDataArray[i] =
        rawDataArray[i] == '1'
          ? vueInstance.$t('__csvBodyAccountStatusEnabled')
          : vueInstance.$t('__csvBodyAccountStatusUnfollow')
    } else {
      convertedDataArray[i] = rawDataArray[i]
    }
  }
  return convertedDataArray.join(',')
}

export default {
  components: { Datetime },
  data() {
    return {
      adminId: '',
      jwtToken: '',
      accountId: '',
      startTime: '',
      endTime: '',
      data: null,
      currentTime: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    // Prepare page content
    async loadData() {
      this.currentTime = this.$dateTime.getCurrentTime()
      // Getting required Id in token
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(',')
        this.adminId = decrypt[0]
        this.accountId = decrypt[1]
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }
    },
    // Start time Y/M/D 00:00:00
    setStartTime(datetime) {
      var start = new Date(datetime)
      start.setHours(0, 0, 0, 0)
      return start.toUTCString()
    },
    // End time Y/M/D 59:59:59
    setEndTime(datetime) {
      var end = new Date(datetime)
      end.setHours(23, 59, 59, 999)
      return end.toUTCString()
    },
    // Download chatHistory Csv
    downloadCsv() {
      // Form validate
      if (!this.startTime || !this.endTime) {
        // 開始日と終了日のいずれかが入力されていない
        return this.$fire({
          title: this.$t('__Outputfailed'),
          text: this.$t('__Pleasefillinallfields'),
          type: 'error',
        })
      }
      if (DateTime.fromISO(this.startTime) > DateTime.fromISO(this.endTime)) {
        // 開始日が終了日よりも未来
        return this.$fire({
          title: this.$t('__Outputfailed'),
          text: this.$t('__invalidPeriodInputed'),
          type: 'error',
        })
      }

      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        startTime: this.$dateTime.toBackendTimeFormat(this.setStartTime(this.startTime)),
        endTime: this.$dateTime.toBackendTimeFormat(this.setEndTime(this.endTime)),
      }

      apiFunction.getChatHistoryCsv(formData, this.jwtToken).then((data) => {
        if (data && data.status == 200) {
          let blob = new Blob([editCsvRawData(data.data, this)], { type: 'text/csv' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = this.startTime + '_' + this.endTime + '.csv'
          link.click()
          this.createExportLoading = false

          return this.$fire({
            title: this.$t('__CreateCSVfile'),
            text: this.$t('__TheCSVfilewassuccessfullyaddedanddownloaded'),
            type: 'success',
          })
        } else if (data && data.status == 204) {
          this.$fire({
            title: this.$t('__Outputfailed'),
            text: this.$t('__noData'),
            type: 'error',
          })
        } else {
          this.$fire({
            title: this.$t('__Outputfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
  },
}
</script>
<style></style>
