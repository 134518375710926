<template>
  <div
    style="z-index=9999"
    class="modal fade bd-example-modal-lg"
    id="push"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold" id="pushMsg-create-title">
            {{ input.broadcastName }}
          </p>
          <span id="pushMsg-create-edit" @click="editName()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control element-hide"
            id="pushMsg-create-input"
            v-model="input.broadcastName"
            @keypress="confirmChangeName($event)"
          />
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row mb-3">
              <span class="text-danger">*</span> {{ $t('__requiredItem') }}
            </div>
            <!-- modal page 1 start  -->
            <div id="push-msg-1">
              <div class="row mb-1">
                <label class="col-3 pl-0 pt-2" data-translate="_pushType.pushMsg">
                  <span class="text-danger">*</span> {{ $t('__Deliverytype') }}
                </label>
                <div class="col-4 px-0">
                  <select class="form-control" id="schedule">
                    <!-- TODO 立即推播 -->
                    <option value="schedule" data-translate="_scheduleRecord.pushMsg">
                      {{ $t('__Appointmentscheduling') }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row my-3" id="schedule-block" style>
                <label class="col-3 pl-0 pt-2" data-translate="_schedule.pushMsg">
                  <span class="text-danger">*</span> {{ $t('__Appointment') }}
                </label>
                <datetime
                  v-model="input.broadcastTime"
                  type="datetime"
                  :minute-step="5"
                  :min-datetime="currentTime"
                  input-class="form-control col-12"
                  input-style="width: 16rem;"
                ></datetime>
              </div>
              <div class="row mt-3 mb-1">
                <h6 data-translate="_pushContent.pushMsg">
                  <span class="text-danger">*</span>
                  {{ $t('__Pleaseselecttheconversationyouwanttosend') }}
                </h6>
                <hr class="mb-2" />
              </div>
              <div class="row border mb-3 py-2 px-3" style="max-height: 30rem; overflow: auto">
                <div class="ml-auto py-2">
                  <input
                    id="search-box-story"
                    type="text"
                    class="search-box"
                    name="q"
                    v-model="search"
                  />
                  <label for="search-box-story">
                    <span class="fas fa-search search-icon"></span>
                  </label>
                </div>
                <table class="table table-bordered" id width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th></th>
                      <th data-translate="_storyName.pushMsg">
                        {{ $t('__Conversationname') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in storyData" :key="index">
                      <td>
                        <div
                          class="swappy-radios"
                          role="radiogroup"
                          aria-labelledby="swappy-radios-label"
                        >
                          <label>
                            <input
                              type="radio"
                              name="options"
                              :value="{
                                scenarioId: item.scenario.scenarioId,
                                scenarioName: item.scenario.scenarioName,
                              }"
                              v-model="input.selectedScenario"
                            />
                            <span class="radio"></span>
                          </label>
                        </div>
                      </td>
                      <td>{{ item.scenario.scenarioName }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- pagination -->
                <pagination
                  v-model="scenarioPage"
                  :per-page="scenarioRowPerPage"
                  :records="allScenario.length"
                  :options="{
                    texts: {
                      count: this.$t('__paginationText'),
                    },
                  }"
                  @paginate="scenarioPageOnChange"
                ></pagination>
                <!-- pagination -->
              </div>
              <hr />
              <div class="row mb-3">
                <button
                  class="btn btn btn-secondary mr-auto px-3 ml-5"
                  data-translate="_cancel.pushMsg"
                  @click="cancelPush()"
                >
                  {{ $t('__cancel') }}
                </button>
                <button
                  id="page-next-1"
                  class="btn btn btn-secondary ml-auto px-3 mr-5"
                  data-translate="_nextStep.pushMsg"
                  @click="toSecondPage()"
                >
                  {{ $t('__Nextstep') }}>
                </button>
              </div>
            </div>
            <!-- modal page 1 end -->
            <!-- modal page 2 start-->
            <div id="push-msg-2" class="element-hide">
              <div class="row">
                <label class="col-3 pt-2 pl-0" data-translate="_pushMethod.pushMsg">
                  <span class="text-danger">*</span> {{ $t('__sendingmethod') }}
                </label>
                <select
                  class="custom-select mb-1 col-4"
                  id="target-account"
                  @change="selectPushType($event)"
                  v-model="pushTypeValue"
                >
                  <option data-translate="_inSegment.pushMsg" value="segment">
                    {{ $t('__Userclassification') }}
                  </option>
                </select>
              </div>
              <div class="row mt-4 mb-1">
                <h6 data-translate="_chooseTarget.pushMsg">
                  <span class="text-danger">*</span>
                  {{ $t('__Pleasechoosetosendto') }}
                </h6>
                <hr class="mb-2" />
                <label
                  ><input
                    type="checkbox"
                    v-model="allSelected"
                    @change="selectAllChanged($event)"
                  />
                  All</label
                >
              </div>
              <!-- inSegment -->
              <div
                class="row border my-1 py-4 px-3"
                style="overflow-y: scroll; max-height: 30rem"
                v-show="pushType.inSegment"
              >
                <div class="row col-12">
                  <!-- segmentListSearch -->
                  <div class="ml-auto py-2">
                    <input
                      id="search-box-segmentList"
                      type="text"
                      class="search-box"
                      name="q"
                      v-model="segmentListSearch"
                    />
                    <label for="search-box-segmentList">
                      <span class="fas fa-search search-icon"></span>
                    </label>
                  </div>
                </div>
                <!-- segmentList Content -->
                <div class="row col-12">
                  <div
                    class="col-4 input-group mb-1"
                    v-for="(segment, index) in segmentList"
                    :key="index"
                  >
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="segmentCheckbox"
                          :value="{
                            name: segment.name,
                            users: segment.users,
                          }"
                          v-model="input.selectedSegment"
                        />
                      </div>
                    </div>
                    <div class="py-2 border rounded col">
                      <span>{{ segment.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- inSegment -->
              <hr />
              <div class="row mb-3 mt-4">
                <button
                  id="page-prev-2"
                  class="btn btn btn-secondary mr-auto px-3 ml-5"
                  data-translate="_prevStep.pushMsg"
                  @click="toFirstPage()"
                >
                  {{ $t('__Previous') }}
                </button>
                <button
                  id="page-next-2"
                  class="btn btn btn-secondary ml-auto px-3 mr-5"
                  data-translate="_send.pushMsg"
                  @click="submitPush()"
                >
                  {{ $t('__determine') }}>
                </button>
              </div>
            </div>
            <!-- modal page 2 end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { apiFunction } from '../Axios/functions'
import { Utility } from '../../../utility'

export default {
  components: { Datetime },
  name: 'create-push-modal',
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    modalData: Object,
  },
  data() {
    return {
      search: '',
      segmentListSearch: '',
      scenarioPage: 1,
      scenarioRowPerPage: 20,
      pushTypeValue: 'segment',
      pushType: {
        inSegment: true,
        inPerson: false,
      },
      input: {
        broadcastTime: '',
        broadcastName: '',
        selectedScenario: null,
        selectAll: false,
        selectedSegment: [],
        selectedUser: [],
        broadcastUsers: [],
        segmentName: [],
      },
      storyData: [],
      allScenario: [],
      allSegment: [],
      segmentList: [],
      currentTime: '',
      allSelected: false,
    }
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        let reg = new RegExp(val)
        let searchResult = []

        $.each(this.allScenario, function (i, story) {
          if (reg.test(story.scenario.scenarioName) == true) {
            searchResult.push(story)
          }
        })
        this.storyData = searchResult
      },
    },
    segmentListSearch: {
      handler: function (val, oldval) {
        let reg = new RegExp(val)
        let searchResult = []

        $.each(this.allSegment, function (i, segment) {
          if (reg.test(segment.name) == true) {
            searchResult.push(segment)
          }
        })
        this.segmentList = searchResult
      },
    },
  },
  mounted() {
    this.$watch(
      'data',
      (data) => {
        this.loadData()
      },
      { immediate: true },
    )
  },
  methods: {
    // Prepare page content
    loadData() {
      let formData = { adminId: this.adminId, accountId: this.accountId }
      this.currentTime = this.$dateTime.getCurrentTime()
      // Get scenario
      apiFunction.getScenario(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          // Only trigger type empty or keyword can be broadcast
          let storyArr = []
          data.data.Result.data.forEach((story) => {
            if (story.scenario.trigger == '' || story.scenario.trigger == 'keyword') {
              storyArr.push(story)
            }
          })
          this.storyData = storyArr
          this.allScenario = storyArr
          // Init pagination
          this.scenarioPageOnChange(this.scenarioPage)
        }
      })
      // Get segment
      apiFunction.getUserSegment(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.segmentList = data.data.Result.data
          this.allSegment = data.data.Result.data
        }
      })

      this.input.broadcastName = this.modalData.broadcastName
      this.input.broadcastTime = ''
      this.input.selectedSegment = []
      this.pushTypeValue = 'segment'
      this.pushType.inSegment = true
      this.pushType.inPerson = false

      $('#push').modal('show')
    },
    // Scenario Pagination
    scenarioPageOnChange(pageNum) {
      let arr = this.allScenario.slice(
        this.scenarioRowPerPage * (pageNum - 1),
        this.scenarioRowPerPage * pageNum,
      )
      this.storyData = arr
    },
    // Type: person, segment
    selectPushType($event) {
      let selected = $event.target.value
      this.input.selectedSegment.splice(0)
      this.input.selectedUser.splice(0)
      this.allSelected = false

      if (selected !== 'person') {
        this.pushType.inSegment = true
        this.pushType.inPerson = false
      } else {
        this.pushType.inSegment = false
        this.pushType.inPerson = true
      }
    },

    // PushMsg page push message title edit
    editName() {
      $('#pushMsg-create-title').hide()
      $('#pushMsg-create-input').show()
      $('#pushMsg-create-edit').hide()
    },

    // PushMsg page push message title submit
    confirmChangeName($event) {
      if ($event.which == 13) {
        $('#pushMsg-create-title').show()
        $('#pushMsg-create-input').hide()
        $('#pushMsg-create-edit').show()
        return false
      }
    },
    // page-move
    toSecondPage() {
      if (this.input.broadcastTime == '') {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Pleaseenterthetime'),
          type: 'error',
        })
      }

      if (this.input.selectedScenario == null) {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Pleaseselectaconversation'),
          type: 'error',
        })
      }

      $('#push-msg-1').hide()
      $('#push-msg-2').show()
      $('.bd-example-modal-lg').scrollTop(0)
      return
    },
    toFirstPage() {
      $('#push-msg-2').hide()
      $('#push-msg-1').show()
      $('.bd-example-modal-lg').scrollTop(0)
    },
    cancelPush() {
      $('#push').modal('hide')
    },
    selectAllChanged() {
      if (this.pushTypeValue === 'segment') {
        this.input.selectedSegment.splice(0)
        if (this.allSelected) {
          this.segmentList.forEach((segment) => {
            this.input.selectedSegment.push({
              name: segment.name,
              users: segment.users,
            })
          })
        }
      }
    },

    // Create / edit push message
    submitPush() {
      let selectedSegment = this.input.selectedSegment
      let selectedUser = this.input.selectedUser
      let broadcastUsers = []
      let segmentName = []

      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        broadcastName: this.input.broadcastName,
        broadcastScenarioName: this.input.selectedScenario.scenarioName,
        broadcastScenarioId: this.input.selectedScenario.scenarioId,
        broadcastStatus: 'pending',
        broadcastTime: this.$dateTime.toBackendTimeFormat(this.input.broadcastTime),
        segmentNames: [],
      }

      if (selectedSegment.length > 0 || selectedUser.length > 0) {
        if (selectedSegment.length > 0) {
          selectedSegment.forEach((segment) => {
            segment.users.forEach((user) => {
              if (!broadcastUsers.find((targetUser) => targetUser.userId === user.userId)) {
                broadcastUsers.push(user)
              }
            })
            segmentName.push(segment.name)
          })
          formData.segmentNames = segmentName
        } else {
          selectedUser.forEach((user) => {
            broadcastUsers.push(user)
          })
        }

        if (broadcastUsers.length == 0) {
          return this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__Thenumberofsendingtargetscannotbe0'),
            type: 'error',
          })
        }

        formData.broadcastUsers = broadcastUsers
        if (this.editId !== '') {
          formData.broadcastId = this.editId
        }

        apiFunction.scheduleBroadcast(formData, this.jwtToken).then((data) => {
          if (data.status == 200) {
            return this.$fire({
              title: this.$t('__addedsuccessfully'),
              text: this.modalData.modalSuccessMsg,
              type: 'success',
              timer: 1500,
            }).then((e) => {
              this.$router.go()
            })
          } else {
            return this.$fire({
              title: this.$t('__Addfailed'),
              text: this.$t('__oopsSomethingwentwrong'),
              type: 'error',
            })
          }
        })
      } else {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Pleaseselectthedestination'),
          type: 'error',
        })
      }
    },
  },
}
</script>
