<template>
  <div>
    <!-- ノード領域 -->
    <div id="node-board" class="card">
      <div class="card-body">
        <nodes
          :adminId="adminId"
          :accountId="accountId"
          :scenarioId="id"
          v-on:saveformData="getNodesData"
          v-on:newTag="saveNewTag"
        ></nodes>
      </div>
    </div>
    <!-- 保存ボタン -->
    <div class="row">
      <button class="btn btn-secondary ml-auto mr-3" @click="saveNode()">
        {{ $t('__determine') }}
      </button>
    </div>
  </div>
</template>
<script>
import { Nodes } from './Components/index'
import { apiFunction } from './Axios/functions'

export default {
  components: {
    Nodes,
  },
  data() {
    return {
      adminId: '',
      jwtToken: '',
      accountId: '',
      isEditing: true,
      // Refreshing data each time page create
      savingData: {},
      newTag: [],
    }
  },
  created() {
    this.id = this.$route.params.id
    this.loadData()
  },
  // Prevent user leave page without saving
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav)
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      return this.$fire({
        title: this.$t('__Settingsnotyetsaved'),
        text: this.$t(
          '__Thesystemwillnotbeabletokeeptheunsaveddataareyousureyouwanttoleavethecurrentpage',
        ),
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__determine'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          next()
        } else {
          return
        }
      })
    }
    next()
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(',')
        this.adminId = decrypt[0]
        this.accountId = decrypt[1]
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }
    },
    // Prevent user leave page without saving
    preventNav(event) {
      if (!this.isEditing) return
      event.preventDefault()
      event.returnValue = ''
    },
    // Get saving data from child component
    getNodesData(value) {
      this.savingData = value
    },
    // Append new tag to api form if user create a new tag
    saveNewTag(tag) {
      this.newTag.push(tag)
    },
    // Submit saving node fata
    saveNode() {
      this.isEditing = false
      let formData = this.savingData
      // If need to create new tag, insert data into api data
      if (this.newTag.length > 0) {
        formData.tags = this.newTag
      }
      // Count exist number of nodes in Lv1
      let countLv1 = 0
      formData.nodes.forEach((node) => {
        if (node.level === '1') {
          countLv1 = countLv1 + 1
        }
      })
      // Lv1 node is required and must be only one
      if (countLv1 != 1) {
        const errorMessage =
          countLv1 == 0
            ? this.$t('__cannotSaveScenarioWithoutLv1Node')
            : this.$t('__Lv1startingpointistheonlyitemthatmustbeset')

        return this.$fire({
          title: this.$t('__Addfailed'),
          text: errorMessage,
          type: 'error',
        })
      }
      apiFunction.saveScenario(formData, this.jwtToken).then((data) => {
        if (data && data.status == 200) {
          this.$fire({
            title: this.$t('__updatecompleted'),
            text: this.$t('__Abouttojumpbacktothedialogsettingpage'),
            type: 'success',
            timer: 1500,
          }).then((e) => {
            this.$router.push({ name: 'story' })
          })
        } else {
          this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.card-body {
  overflow: scroll;
  height: 65vh;
}
</style>
