<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>#</th>
      <th style="cursor: pointer" @click="onClickBroadcastNameColumnHeader">
        {{ this.$t('__SendID') }} {{ getSortOrderLabel(broadcastNameColumnSortState) }}
      </th>
      <th>{{ this.$t('__Conversationname') }}</th>
      <th>{{ this.$t('__Pushtarget') }}</th>
      <th>{{ this.$t('__sendstatus') }}</th>
      <th>{{ this.$t('__Deliverydate') }}</th>
      <th>{{ this.$t('__Establishmenttime') }}</th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ item.broadcastName }}</td>
        <td>{{ item.broadcastScenarioName }}</td>
        <td>{{ item.broadcastUsersLength }}</td>
        <td
          v-html="broadcastStatus(item.broadcastStatus, item.success, item.failed, item.unmatched)"
        ></td>
        <td>{{ toFrontendTimeFormat(item.broadcastTime) }}</td>
        <td>{{ toFrontendTimeFormat(item.updateTime) }}</td>
      </tr>
    </tbody>
    <create-push-modal
      v-bind:modalData="modalData"
      :editId="editId"
      :key="componentKey"
      v-if="componentKey"
    ></create-push-modal>
  </table>
</template>
<script>
import { CreatePushModal } from '../Modals/index'
import { apiFunction } from '../Axios/functions'

export default {
  name: 'history-table',
  components: { CreatePushModal },
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: 'striped',
    },
  },
  computed: {
    tableClass() {
      return `table-${this.type}`
    },
  },
  data() {
    return {
      broadcastId: '',
      modalData: {
        broadcastName: '',
        modalBtn: this.$t('__edit'),
        modalSuccessMsg: this.$t('__Anewconversationhasbeensuccessfullyestablished'),
      },
      editId: '',
      componentKey: 0,
      // テーブルのソート状態（none, asc, desc）
      broadcastNameColumnSortState: 'none',
    }
  },
  methods: {
    // Render popup modal per click
    forceRerender() {
      this.componentKey += 1
    },
    // Status type: done, issue, pending
    broadcastStatus(status, success, failed, unmatched) {
      if (status == 'done') {
        return `<span class="text-success">${this.$t('__statusDone')}(${this.$t(
          '__statusSent',
        )}:${success}/${this.$t('__statusUnmatched')}:${unmatched})</span>`
      }
      if (status == 'issue') {
        if (!success && !failed) {
          return `<span class="text-warning">${this.$t('__statusIssue')}</span>`
        } else {
          return `<span class="text-warning">${this.$t('__statusIssue')}(${this.$t(
            '__statusSent',
          )}:${success}/${this.$t('__statusIssue')}:${failed}/${this.$t(
            '__statusUnmatched',
          )}:${unmatched})</span>`
        }
      }
      if (status == 'pending') {
        return `<span class="text-danger">${this.$t('__statusPending')}</span>`
      }
      return ''
    },
    // Example: 2020-06-30 13:24
    toFrontendTimeFormat(dateTime) {
      return this.$dateTime.toFrontEndTimeFormat(dateTime)
    },
    // Create broadcast plan
    createBroadcastPlan(broadcast) {
      this.forceRerender()
      this.modalData.broadcastName = broadcast.broadcastName
      this.modalData.broadcastScenarioId = broadcast.broadcastScenarioId
      this.modalData.broadcastScenarioName = broadcast.broadcastScenarioName
      this.modalData.broadcastTime = broadcast.broadcastTime
      this.modalData.broadcastUsers = broadcast.broadcastUsers
      this.modalData.segmentNames = broadcast.segmentNames
      this.modalData.modalBtn = this.$t('__edit')
      this.modalData.modalSuccessMsg = this.$t('__Theappointmentschedulehasbeensuccessfullycreated')
    },
    getSortOrderLabel(order) {
      if (order == 'none') return ''
      return order == 'asc' ? '▲' : '▼'
    },
    onClickBroadcastNameColumnHeader() {
      this.broadcastNameColumnSortState =
        this.broadcastNameColumnSortState == 'asc' ? 'desc' : 'asc'
      this.sortTableByBroadcastNameColumn()
    },
    sortTableByBroadcastNameColumn() {
      if (this.broadcastNameColumnSortState == 'none') return
      const ascFunc = (a, b) => {
        if (a.broadcastName < b.broadcastName) return -1
        if (a.broadcastName > b.broadcastName) return 1
        return 0
      }
      const descFunc = (a, b) => {
        if (a.broadcastName > b.broadcastName) return -1
        if (a.broadcastName < b.broadcastName) return 1
        return 0
      }
      this.data.sort(this.broadcastNameColumnSortState == 'asc' ? ascFunc : descFunc)
    },
  },
}
</script>
<style></style>
