<template>
  <div class="button-editor">
    <h5>{{ $t("__button") }} {{ buttonNumber }}</h5>

    <!-- Button Label -->
    <div class="form-group">
      <label>{{ $t("__buttonLabel") }}</label>
      <input
        type="text"
        v-model="button.label"
        maxlength="20"
        class="form-control"
        required
      />
    </div>
    <!-- Button Action Type -->
    <div class="form-group">
      <label>{{ $t("__actionType") }}</label>
      <select v-model="button.actionType" class="form-control">
        <option value="uri">URI</option>
        <option value="text">Text</option>
      </select>
    </div>
    <!-- Button Action Value -->
    <div class="form-group" v-if="button.actionType === 'uri'">
      <label>{{ $t("__enterUri") }}</label>
      <input type="url" v-model="button.value" class="form-control" />
    </div>
    <div class="form-group" v-if="button.actionType === 'text'">
      <label>{{ $t("__enterText") }}</label>
      <input
        type="text"
        v-model="button.value"
        maxlength="80"
        class="form-control"
      />
    </div>

    <!-- Button Colors -->
    <div class="form-group">
      <label>{{ $t("__buttonColor") }}</label>
      <input type="color" v-model="button.color" class="form-control" />
    </div>
    <div class="form-group">
      <label>{{ $t("__fontColor") }}</label>
      <input type="color" v-model="button.fontColor" class="form-control" />
    </div>
    <button class="btn btn-danger" @click="$emit('deleteButton')">
      {{ $t("__deleteButton") }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["button", "buttonNumber"],
};
</script>
