<template>
  <div
    style="z-index=9999"
    class="modal fade bd-example-modal-lg"
    id="push"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold" id="pushMsg-create-title">
            {{ input.broadcastName }}
          </p>
          <span id="pushMsg-create-edit" @click="editName()">
            <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
          </span>
          <input
            type="text"
            class="form-control element-hide"
            id="pushMsg-create-input"
            v-model="input.broadcastName"
            @keypress="confirmChangeName($event)"
          />
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div v-if="!isSubmitLoading" class="row mb-3">
              <span class="text-danger">*</span> {{ $t('__requiredItem') }}
            </div>
            <!-- modal page 1 start  -->
            <div id="push-msg-1">
              <div class="row mb-1">
                <label class="col-3 pl-0 pt-2" data-translate="_pushType.pushMsg">
                  <span class="text-danger">*</span> {{ $t('__Deliverytype') }}
                </label>
                <div class="col-4 px-0">
                  <select class="form-control" id="schedule">
                    <!-- TODO 立即推播 -->
                    <option value="schedule" data-translate="_scheduleRecord.pushMsg">
                      {{ $t('__Appointmentscheduling') }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row my-3" id="schedule-block" style>
                <label class="col-3 pl-0 pt-2" data-translate="_schedule.pushMsg">
                  <span class="text-danger">*</span> {{ $t('__Appointment') }}
                </label>
                <datetime
                  v-model="input.broadcastTime"
                  type="datetime"
                  :minute-step="5"
                  :min-datetime="currentTime"
                  input-class="form-control col-12"
                  input-style="width: 16rem;"
                ></datetime>
              </div>
              <div class="row mt-3 mb-1">
                <h6 data-translate="_pushContent.pushMsg">
                  <span class="text-danger">*</span>
                  {{ $t('__Pleaseselecttheconversationyouwanttosend') }}
                </h6>
                <hr class="mb-2" />
              </div>
              <div class="row border mb-3 py-2 px-3" style="max-height: 30rem; overflow: auto">
                <div class="ml-auto py-2">
                  <input
                    id="search-box-story"
                    type="text"
                    class="search-box"
                    name="q"
                    v-model="search"
                  />
                  <label for="search-box-story">
                    <span class="fas fa-search search-icon"></span>
                  </label>
                </div>
                <table class="table table-bordered" id width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th></th>
                      <th data-translate="_storyName.pushMsg">
                        {{ $t('__Conversationname') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in storyData" :key="index">
                      <td>
                        <div
                          class="swappy-radios"
                          role="radiogroup"
                          aria-labelledby="swappy-radios-label"
                        >
                          <label>
                            <input
                              type="radio"
                              name="options"
                              :value="{
                                scenarioId: item.scenario.scenarioId,
                                scenarioName: item.scenario.scenarioName,
                              }"
                              v-model="input.selectedScenario"
                            />
                            <span class="radio"></span>
                          </label>
                        </div>
                      </td>
                      <td>{{ item.scenario.scenarioName }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- pagination -->
                <pagination
                  v-model="scenarioPage"
                  :per-page="scenarioRowPerPage"
                  :records="allScenario.length"
                  :options="{
                    texts: {
                      count: this.$t('__paginationText'),
                    },
                  }"
                  @paginate="scenarioPageOnChange"
                ></pagination>
                <!-- pagination -->
              </div>
              <hr />
              <div class="row mb-3">
                <button
                  class="btn btn btn-secondary mr-auto px-3 ml-5"
                  data-translate="_cancel.pushMsg"
                  @click="cancelPush()"
                >
                  {{ $t('__cancel') }}
                </button>
                <button
                  id="page-next-1"
                  class="btn btn btn-secondary ml-auto px-3 mr-5"
                  data-translate="_nextStep.pushMsg"
                  @click="toSecondPage()"
                >
                  {{ $t('__Nextstep') }}>
                </button>
              </div>
            </div>
            <!-- modal page 1 end -->
            <!-- modal page 2 start-->
            <div id="push-msg-2" class="element-hide">
              <!-- Loading spinner -->
              <div v-if="isSubmitLoading" class="text-center m-3">
                <span class="spinner-border spinner-border-sm"></span> submit...
              </div>
              <div v-if="!isSubmitLoading">
                <div class="row">
                  <label class="col-3 pt-2 pl-0" data-translate="_pushMethod.pushMsg">
                    <span class="text-danger">*</span>
                    {{ $t('__sendingmethod') }}
                  </label>
                  <select
                    class="custom-select mb-1 col-4"
                    id="target-account"
                    @change="selectPushType($event)"
                    v-model="pushTypeValue"
                  >
                    <option data-translate="_inSegment.pushMsg" value="segment">
                      {{ $t('__Userclassification') }}
                    </option>
                    <option data-translate="_inPerson.pushMsg" value="person">
                      {{ $t('__personal') }}
                    </option>
                  </select>
                </div>
                <div class="row mt-4 mb-1">
                  <h6 data-translate="_chooseTarget.pushMsg">
                    <span class="text-danger">*</span>
                    {{ $t('__Pleasechoosetosendto') }}
                  </h6>
                  <hr class="mb-2" />
                  <div v-show="allSelectedShow">
                    <label
                      ><input
                        type="checkbox"
                        v-model="allSelected"
                        @change="selectAllChanged($event)"
                      />
                      All</label
                    >
                  </div>
                </div>
                <!-- inPerson -->
                <div
                  class="border mb-3 py-2 px-3 mx-2"
                  style="overflow-y: scroll; max-height: 30rem"
                  v-show="pushType.inPerson"
                >
                  <div class="row">
                    <!-- userListSearch -->
                    <div class="ml-auto py-2">
                      <input
                        id="search-box-userList"
                        type="text"
                        class="search-box"
                        name="q"
                        v-model="userListSearch"
                        v-on:keyup.enter="searchUser"
                      />
                      <label for="search-box-userList" v-on:click="searchUser">
                        <span class="fas fa-search search-icon"></span>
                      </label>
                    </div>
                  </div>
                  <!-- userList Content -->
                  <!-- Loading spinner -->
                  <div v-if="isUserListLoading" class="text-center m-3">
                    <div class="spinner-border">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div v-if="!isUserListLoading" class="border container py-2 mb-2">
                    <div class="row">
                      <div
                        class="col-4 input-group mb-1"
                        v-for="(user, index) in userList"
                        :key="index"
                      >
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <input
                              type="checkbox"
                              class="userCheckbox"
                              :value="{
                                userId: user.userId,
                                userName: user.userName,
                                accountType: user.accountType,
                              }"
                              v-model="input.selectedUser"
                            />
                          </div>
                        </div>
                        <div class="py-2 border rounded col">
                          <img :src="user.userPicture" width="30" />
                          <span class="px-2">{{ user.userName }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- pagination -->
                  <pagination
                    v-model="userPage"
                    :per-page="userRowPerPage"
                    :records="currentFollowers"
                    :options="{
                      texts: {
                        count: this.$t('__paginationText'),
                      },
                    }"
                    @paginate="userPageOnChange"
                  ></pagination>
                  <!-- pagination -->
                </div>
                <!-- inPerson -->

                <!-- inSegment -->
                <div
                  class="row border my-1 py-4 px-3"
                  style="overflow-y: scroll; max-height: 30rem"
                  v-show="pushType.inSegment"
                >
                  <div class="row col-12">
                    <!-- segmentListSearch -->
                    <div class="ml-auto py-2">
                      <input
                        id="search-box-segmentList"
                        type="text"
                        class="search-box"
                        name="q"
                        v-model="segmentListSearch"
                      />
                      <label for="search-box-segmentList">
                        <span class="fas fa-search search-icon"></span>
                      </label>
                    </div>
                  </div>
                  <!-- segmentList Content -->
                  <div class="row col-12">
                    <div
                      class="col-4 input-group mb-1"
                      v-for="(segment, index) in segmentList"
                      :key="index"
                    >
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input
                            type="checkbox"
                            class="segmentCheckbox"
                            :value="{
                              name: segment.name,
                              users: segment.users,
                              id: segment.id,
                            }"
                            v-model="input.selectedSegment"
                          />
                        </div>
                      </div>
                      <div class="py-2 border rounded col">
                        <span>{{ segment.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- inSegment -->
                <hr />
                <div class="row mb-3 mt-4">
                  <button
                    id="page-prev-2"
                    class="btn btn btn-secondary mr-auto px-3 ml-5"
                    data-translate="_prevStep.pushMsg"
                    @click="toFirstPage()"
                  >
                    {{ $t('__Previous') }}
                  </button>
                  <button
                    id="page-next-2"
                    class="btn btn btn-secondary ml-auto px-3 mr-5"
                    data-translate="_send.pushMsg"
                    @click="submitPush()"
                  >
                    {{ $t('__determine') }}>
                  </button>
                </div>
              </div>
            </div>
            <!-- modal page 2 end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { apiFunction } from '../Axios/functions'
import { Utility } from '../../../utility'
import axios from 'axios'

export default {
  components: { Datetime },
  name: 'create-push-modal',
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    modalData: Object,
    editId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isUserListLoading: false,
      isSubmitLoading: false,
      search: '',
      segmentListSearch: '',
      userListSearch: '',
      scenarioPage: 1,
      scenarioRowPerPage: 20,
      userPage: 1,
      userRowPerPage: 100,
      pushTypeValue: 'segment',
      allSelectedShow: true,
      pushType: {
        inSegment: true,
        inPerson: false,
      },
      input: {
        broadcastTime: '',
        broadcastName: '',
        selectedScenario: null,
        selectAll: false,
        selectedSegment: [],
        selectedUser: [],
        broadcastUsers: [],
        segmentNames: [],
      },
      currentTime: '',
      storyData: [],
      allScenario: [],
      allSegment: [],
      segmentList: [],
      allUsers: [],
      userList: [],
      allSelected: false,
      userLastEvaluatedKey: null,
      currentFollowers: 0,
    }
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        let reg = new RegExp(val)
        let searchResult = []

        $.each(this.allScenario, function (i, story) {
          if (reg.test(story.scenario.scenarioName) == true) {
            searchResult.push(story)
          }
        })
        this.storyData = searchResult
      },
    },
    segmentListSearch: {
      handler: function (val, oldval) {
        let reg = new RegExp(val)
        let searchResult = []

        $.each(this.allSegment, function (i, segment) {
          if (reg.test(segment.name) == true) {
            searchResult.push(segment)
          }
        })
        this.segmentList = searchResult
      },
    },
  },
  mounted() {
    this.$watch(
      'modalData',
      (modalData) => {
        this.loadData()
      },
      { immediate: true },
    )
  },
  methods: {
    async loadData() {
      let formData = { adminId: this.adminId, accountId: this.accountId }
      this.currentTime = this.$dateTime.getCurrentTime()
      if (this.editId !== '') {
        formData = {
          adminId: this.adminId,
          accountId: this.accountId,
          broadcastPlanId: this.editId,
        }
        await apiFunction.getBroadcastPlan(formData, this.jwtToken).then((data) => {
          if (data.error) {
            this.$fire({
              title: this.$t('__authExpired'),
              text: this.$t('__pleaseLogin'),
              type: 'error',
            }).then(() => {
              this.$Amplify.Auth.signOut()
              localStorage.clear()
              this.$router.push('/login')
            })
          } else {
            let currentBroadcast = data.data.Result.data.find(
              (plan) => plan.broadcastId === this.editId,
            )
            if (currentBroadcast && currentBroadcast.broadcastUsers) {
              this.modalData.broadcastUsers = currentBroadcast.broadcastUsers
            }
          }
        })
      }
      formData = { adminId: this.adminId, accountId: this.accountId }
      // Get scenario
      apiFunction.getScenario(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          // Only trigger type empty or keyword can be broadcast
          let storyArr = []
          data.data.Result.data.forEach((story) => {
            if (story.scenario.trigger == '' || story.scenario.trigger == 'keyword') {
              storyArr.push(story)
            }
          })
          this.storyData = storyArr
          this.allScenario = storyArr
          // Init pagination
          this.scenarioPageOnChange(this.scenarioPage)
        }
      })
      // Get segment
      apiFunction.getUserSegment(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.segmentList = data.data.Result.data
          this.allSegment = data.data.Result.data
        }
      })
      // Get user count
      apiFunction.getUserCount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.currentFollowers = 0
        } else {
          this.currentFollowers = data.data.Result.data
        }
      })
      // Get user
      this.fetchUserList()

      this.input.broadcastName = this.modalData.broadcastName

      if (this.modalData.selectedScenario !== null) {
        this.input.selectedScenario = {
          scenarioId: this.modalData.broadcastScenarioId,
          scenarioName: this.modalData.broadcastScenarioName,
        }
      }

      if (this.modalData.broadcastTime !== null) {
        this.input.broadcastTime = this.$dateTime.toIsoFormat(this.modalData.broadcastTime)
      }
      this.input.selectedSegment = []
      this.input.selectedUser = []

      if (this.modalData.segmentNames.length > 0) {
        this.pushTypeValue = 'person'
        this.pushType.inSegment = false
        this.pushType.inPerson = true
        this.input.selectedUser = Utility.deepCopy(this.modalData.broadcastUsers)
      } else {
        this.pushTypeValue = 'person'
        this.pushType.inSegment = false
        this.pushType.inPerson = true
        this.input.selectedUser = Utility.deepCopy(this.modalData.broadcastUsers)
      }
      $('#push').modal('show')
    },
    // Search users
    searchUser() {
      this.allUsers.splice(0)
      this.userLastEvaluatedKey = null
      this.pageNum = 1
      this.fetchUserList()
    },
    // Scenario Pagination
    scenarioPageOnChange(pageNum) {
      let arr = this.allScenario.slice(
        this.scenarioRowPerPage * (pageNum - 1),
        this.scenarioRowPerPage * pageNum,
      )
      this.storyData = arr
    },
    // User Pagination
    userPageOnChange(pageNum) {
      this.userList = this.allUsers.slice(
        this.userRowPerPage * (pageNum - 1),
        this.userRowPerPage * pageNum,
      )
      if (this.allSelected) {
        this.selectAllChanged()
      }
      if (this.userLastEvaluatedKey && this.allUsers.length < this.userPage * this.userRowPerPage) {
        this.fetchUserList()
      }
    },
    // Choosinh pushing target
    selectPushType($event) {
      let selected = $event.target.value
      this.input.selectedSegment.splice(0)
      this.input.selectedUser.splice(0)
      this.allSelected = false

      if (selected !== 'person') {
        this.pushType.inSegment = true
        this.pushType.inPerson = false
        this.allSelectedShow = false
      } else {
        this.pushType.inSegment = false
        this.pushType.inPerson = true
        this.allSelectedShow = true
      }
    },
    // pushMsg page push message title edit
    editName() {
      $('#pushMsg-create-title').hide()
      $('#pushMsg-create-input').show()
      $('#pushMsg-create-edit').hide()
    },
    // pushMsg page push message title submit
    confirmChangeName($event) {
      if ($event.which == 13) {
        $('#pushMsg-create-title').show()
        $('#pushMsg-create-input').hide()
        $('#pushMsg-create-edit').show()
        return false
      }
    },
    // page-move
    toSecondPage() {
      if (this.input.broadcastTime == '') {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Pleaseenterthetime'),
          type: 'error',
        })
      }

      if (this.input.selectedScenario.scenarioId == '') {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Pleaseselectaconversation'),
          type: 'error',
        })
      }

      $('#push-msg-1').hide()
      $('#push-msg-2').show()
      $('.bd-example-modal-lg').scrollTop(0)
      return
    },
    toFirstPage() {
      $('#push-msg-2').hide()
      $('#push-msg-1').show()
      $('.bd-example-modal-lg').scrollTop(0)
    },
    cancelPush() {
      $('#push').modal('hide')
    },
    selectAllChanged() {
      if (this.pushTypeValue === 'segment') {
        this.input.selectedSegment.splice(0)
        if (this.allSelected) {
          this.segmentList.forEach((segment) => {
            this.input.selectedSegment.push({
              name: segment.name,
              users: segment.users,
            })
          })
        }
      } else if (this.pushTypeValue === 'person') {
        this.input.selectedUser.splice(0)
        if (this.allSelected) {
          this.allUsers.forEach((user) => {
            this.input.selectedUser.push({
              userId: user.userId,
              userName: user.userName,
              accountType: user.accountType,
            })
          })
        }
      }
    },
    // create & edit push message
    submitPush() {
      let selectedSegment = this.input.selectedSegment
      let selectedUser = this.input.selectedUser
      let broadcastUsers = []
      let segmentNames = []
      let segmentIds = []
      let userInPayload = true

      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        broadcastName: this.input.broadcastName,
        broadcastScenarioName: this.input.selectedScenario.scenarioName,
        broadcastScenarioId: this.input.selectedScenario.scenarioId,
        broadcastStatus: 'pending',
        broadcastTime: this.$dateTime.toBackendTimeFormat(this.input.broadcastTime),
        isSelectAll: this.allSelected,
        segmentNames: [],
        broadcastUsers: [],
      }
      // If it's new broadcast and already selected all users, no need to put users into payload.
      if (this.editId == '' && this.allSelected) {
        userInPayload = false
      }

      if (selectedSegment.length > 0 || selectedUser.length > 0) {
        if (selectedSegment.length > 0) {
          selectedSegment.forEach((segment) => {
            if (userInPayload) {
              segment.users.forEach((user) => {
                if (!broadcastUsers.find((targetUser) => targetUser.userId === user.userId)) {
                  broadcastUsers.push({
                    userId: user.userId,
                    userName: user.userName,
                    accountType: user.accountType,
                  })
                }
              })
            }
            segmentNames.push(segment.name)
            segmentIds.push(segment.id)
          })
          formData.segmentNames = segmentNames
          formData.segmentIds = segmentIds
        } else {
          if (userInPayload) {
            selectedUser.forEach((user) => {
              broadcastUsers.push({
                userId: user.userId,
                userName: user.userName,
                accountType: user.accountType,
              })
            })
          }
        }

        if (broadcastUsers.length == 0 && userInPayload) {
          return this.$fire({
            title: this.$t('__Addfailed'),
            text: this.$t('__Thenumberofsendingtargetscannotbe0'),
            type: 'error',
          })
        }

        formData.broadcastUsers = broadcastUsers
        formData.pushTypeValue = this.pushTypeValue
        // If editing
        if (this.editId !== '') {
          formData.broadcastId = this.editId
        }
        this.isSubmitLoading = true
        apiFunction.scheduleBroadcast(formData, this.jwtToken).then((data) => {
          this.isSubmitLoading = false
          if (data && data.status == 200) {
            this.$fire({
              title: this.$t('__addedsuccessfully'),
              text: this.modalData.modalSuccessMsg,
              type: 'success',
              timer: 1500,
            }).then((e) => {
              this.$router.go()
            })
          } else {
            return this.$fire({
              title: this.$t('__Addfailed'),
              text: this.$t('__oopsSomethingwentwrong'),
              type: 'error',
            })
          }
        })
      } else {
        return this.$fire({
          title: this.$t('__Addfailed'),
          text: this.$t('__Pleaseselectthedestination'),
          type: 'error',
        })
      }
    },
    async fetchUserList() {
      let formData = { adminId: this.adminId, accountId: this.accountId }
      if (this.userListSearch !== '') {
        formData['keywords'] = this.userListSearch
      }
      if (this.userLastEvaluatedKey) {
        formData['lastEvaluatedKey'] = this.userLastEvaluatedKey
      }
      this.isUserListLoading = true
      return axios({
        method: 'POST',
        url: process.env.VUE_APP_API_ENDPOINT + 'getUsersList',
        data: formData,
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      })
        .then((res) => {
          this.isUserListLoading = false
          this.allUsers.push(...res.data.Result.data)
          this.userLastEvaluatedKey = res.data.Result.lastEvaluatedKey
          this.userPageOnChange(this.userPage)
          return res
        })
        .catch((e) => {
          this.isUserListLoading = false
          this.userLastEvaluatedKey = null
          console.log(e)
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
          return null
        })
    },
  },
}
</script>
