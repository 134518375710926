<template>
  <card>
    <!-- search -->
    <div class="row">
      <div class="ml-auto py-2 pr-4">
        <input id="search-box-plan" type="text" class="search-box" name="q" v-model="search" />
        <label for="search-box-plan">
          <span class="fas fa-search search-icon"></span>
        </label>
      </div>
    </div>
    <!-- search -->
    <!-- content start -->
    <div class="row mt-4">
      <label class="ml-4" data-translate="_pushRecord.history">
        {{ $t('__Sendrecord') }}: {{ allHistory.length }}
      </label>
      <div class="ml-auto mr-4">
        <a href="javascript:void(0)" @click="$router.push('/pushMessage')">{{
          $t('__Appointmentscheduling')
        }}</a>
        <span class="px-2">/</span>
        <a
          href="javascript:void(0)"
          style="color: #009688"
          @click="$router.push('/pushMessage/history')"
          >{{ $t('__Sendrecord') }}</a
        >
      </div>
    </div>
    <!-- Loading spinner -->
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!isLoading" class="table-responsive">
      <history-table
        type="hover table-bordered"
        :adminId="adminId"
        :jwtToken="jwtToken"
        :accountId="accountId"
        :data="table.data"
        :columns="table.columns"
      ></history-table>
      <!-- content end -->
      <pagination
        v-model="page"
        :per-page="rowPerPage"
        :records="filteredData.length"
        :options="{
          texts: {
            count: this.$t('__paginationText'),
          },
        }"
        @paginate="callback"
      ></pagination>
    </div>
  </card>
</template>
<script>
import { HistoryTable } from './Component/index'
import { apiFunction } from './Axios/functions'

const tableColumns = []
const tableData = []

export default {
  components: {
    HistoryTable,
  },
  data() {
    return {
      isLoading: false,
      search: '',
      page: 1,
      rowPerPage: 20,
      allHistory: [],
      filteredData: [],
      adminId: '',
      jwtToken: '',
      accountId: '',
      table: {
        columns: [...tableColumns],
        data: [...tableData],
      },
    }
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        let reg = new RegExp(val)
        let searchResult = []

        $.each(this.allHistory, function (i, broadcastPlan) {
          if (
            reg.test(broadcastPlan.broadcastScenarioName) == true ||
            reg.test(broadcastPlan.broadcastName) == true
          ) {
            searchResult.push(broadcastPlan)
          }
        })
        this.filteredData = searchResult
        this.callback(1)
      },
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id in token
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(',')
        this.adminId = decrypt[0]
        this.accountId = decrypt[1]
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }

      let formData = { adminId: this.adminId, accountId: this.accountId }
      this.isLoading = true
      apiFunction.getBroadcastHistory(formData, this.jwtToken).then((data) => {
        this.isLoading = false
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          this.allHistory = data.data.Result.data
          this.filteredData = this.allHistory
          // Init pagination
          this.callback(1)
        }
      })
    },
    // Pagination
    callback(pageNum) {
      this.page = pageNum
      let arr = this.filteredData.slice(this.rowPerPage * (pageNum - 1), this.rowPerPage * pageNum)
      this.table.data = arr
    },
  },
}
</script>
<style></style>
