<template>
  <!--Variables Choose modal start -->
  <div class="modal fade" id="variable" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p class="modal-title text-bold">
            {{ $t('__variable') }}
          </p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div>
              <!-- search -->
              <div class="row">
                <div class="ml-auto py-2 pr-0">
                  <input type="text" class="search-box" v-model="searchVariable" />
                  <label>
                    <span class="fas fa-search search-icon"></span>
                  </label>
                  <button
                    class="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#create-variable-modal"
                  >
                    {{ $t('__AddVariable') }}
                  </button>
                </div>
              </div>
              <!-- search -->
              <div class="row border mb-3 py-2 px-3" style="overflow-y: scroll; max-height: 15rem">
                <div class="input-group mb-1" v-for="(variable, index) in variables" :key="index">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input
                        type="checkbox"
                        :value="variable"
                        :id="'variable' + index"
                        v-model="checkedVariables"
                        @change="selectedOnChanged"
                      />
                    </div>
                  </div>
                  <div class="py-2 border rounded col">
                    <span>{{ variable }}</span>
                    <a
                      class="pull-right"
                      href="javascript:void(0)"
                      @click="removeVariable(variable)"
                    >
                      <i class="fas fa-trash text-danger mx-1"></i>
                    </a>
                  </div>
                </div>
                <p class="m-auto py-4" v-if="variables.length == 0">
                  {{ $t('__Novariablesavailable') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            style="width: 100%"
            data-dismiss="modal"
            @click="addExistVariables()"
          >
            {{ $t('__determine') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Variables Choose Modal End -->
</template>

<script>
export default {
  name: 'exist-variable-modal',
  props: {
    allVariables: Array,
  },
  data() {
    return {
      variables: [],
      checkedVariables: [],
      searchVariable: [],
    }
  },
  watch: {
    searchVariable: {
      handler: function (val, oldval) {
        let reg = new RegExp(val)
        let searchResult = []

        $.each(this.allVariables, function (i, variable) {
          if (reg.test(variable) == true) {
            searchResult.push(variable)
          }
        })
        this.variables = searchResult
      },
    },
    allVariables: {
      handler: function () {
        this.variables = this.allVariables
      },
    },
  },
  methods: {
    addExistVariables() {
      this.$emit('addVariable', this.checkedVariables)
      this.checkedVariables.splice(0)
    },
    selectedOnChanged(e) {
      // Support multi select now, so mark bellow code.
      // this.checkedVariables.splice(0);
      // if (e.target.checked) {
      //   this.checkedVariables.push(e.target.value);
      // }
    },
    removeVariable(variable) {
      this.$fire({
        title: this.$t('__AreyousureyouwanttodeletetheVariable'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          this.$emit('removeVariable', variable)
          for (var i = 0; i < this.allVariables.length; i++) {
            if (this.allVariables[i] === variable) {
              this.allVariables.splice(i, 1)
              break
            }
          }
        }
      })
    },
  },
}
</script>
