import { render, staticRenderFns } from "./SegmentTable.vue?vue&type=template&id=7d7af238&"
import script from "./SegmentTable.vue?vue&type=script&lang=js&"
export * from "./SegmentTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2715650749/src/github.com/Hitobito-Inc-Taiwan-branch/chatbot-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d7af238')) {
      api.createRecord('7d7af238', component.options)
    } else {
      api.reload('7d7af238', component.options)
    }
    module.hot.accept("./SegmentTable.vue?vue&type=template&id=7d7af238&", function () {
      api.rerender('7d7af238', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Segment/Components/SegmentTable.vue"
export default component.exports