<template>
  <div class="web-bot-color-edit" v-click-outside="closeDisplayInput">
    <div class="curr-color">
      <span :style="{ backgroundColor: hexInput }" @click="toggleDisplayInput"></span>
      <div class="color-picker" v-if="displayInput">
        <!-- SV Panel -->
        <div
          class="sv-panel"
          ref="sv"
          @mousedown="startSVPick"
          @mousemove="moveSVPick"
          @mouseup="stopPick"
          @mouseleave="stopPick"
          :style="{ backgroundColor: hueBackground }"
        >
          <div class="sv-cursor" :style="svCursorStyle"></div>
        </div>

        <!-- Hue Slider -->
        <div
          class="hue-slider"
          ref="hue"
          @mousedown="startHuePick"
          @mousemove="moveHuePick"
          @mouseup="stopPick"
          @mouseleave="stopPick"
        >
          <div class="hue-cursor" :style="hueCursorStyle"></div>
        </div>

        <div class="color-picker__ctrl">
          <div class="preview" :style="{ backgroundColor: hexInput }"></div>
          <input class="hex-input" type="text" v-model="hexInput" @input="onHexChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function hsvToRgb(h, s, v) {
  let r, g, b
  let i = Math.floor(h * 6)
  let f = h * 6 - i
  let p = v * (1 - s)
  let q = v * (1 - f * s)
  let t = v * (1 - (1 - f) * s)
  switch (i % 6) {
    case 0:
      r = v
      g = t
      b = p
      break
    case 1:
      r = q
      g = v
      b = p
      break
    case 2:
      r = p
      g = v
      b = t
      break
    case 3:
      r = p
      g = q
      b = v
      break
    case 4:
      r = t
      g = p
      b = v
      break
    case 5:
      r = v
      g = p
      b = q
      break
  }
  return [r * 255, g * 255, b * 255]
}

function rgbToHsv(r, g, b) {
  r /= 255
  g /= 255
  b /= 255
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b)
  let h,
    s,
    v = max
  const d = max - min
  s = max === 0 ? 0 : d / max
  if (max === min) h = 0
  else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }
  return [h, s, v]
}

function rgbToHex(r, g, b) {
  return (
    '#' +
    [r, g, b]
      .map((x) => Math.round(x).toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase()
  )
}

function hexToRgb(hex) {
  const match = hex.replace('#', '').match(/^([0-9a-f]{6})$/i)
  if (!match) return null
  const int = parseInt(match[1], 16)
  return [(int >> 16) & 255, (int >> 8) & 255, int & 255]
}

export default {
  name: 'MiniColorPicker',
  props: {
    value: {
      type: String,
      default: '#FF0000',
    },
  },
  data() {
    return {
      hexInput: this.value,
      hue: 0,
      saturation: 1,
      valueBrightness: 1,
      picking: false,
      pickingHue: false,
      displayInput: false,
    }
  },
  computed: {
    hueBackground() {
      return `hsl(${this.hue * 360}, 100%, 50%)`
    },
    svCursorStyle() {
      return {
        left: `${this.saturation * 100}%`,
        top: `${(1 - this.valueBrightness) * 100}%`,
        backgroundColor: this.hexInput,
      }
    },
    hueCursorStyle() {
      return {
        left: `${this.hue * 100}%`,
        backgroundColor: `hsl(${this.hue * 360}, 100%, 50%)`,
      }
    },
  },
  watch: {
    value(newVal) {
      if (newVal !== this.hexInput) {
        this.hexInput = newVal
        this.onHexChange() // Sync HSV
      }
    },
    hue() {
      this.updateHexFromHSV()
    },
    saturation() {
      this.updateHexFromHSV()
    },
    valueBrightness() {
      this.updateHexFromHSV()
    },
  },
  methods: {
    closeDisplayInput() {
      this.displayInput = false
    },
    toggleDisplayInput() {
      this.displayInput = !this.displayInput
    },
    updateHexFromHSV() {
      const [r, g, b] = hsvToRgb(this.hue, this.saturation, this.valueBrightness)
      const newHex = rgbToHex(r, g, b)
      this.hexInput = newHex
      this.$emit('input', newHex)
    },
    onHexChange() {
      const rgb = hexToRgb(this.hexInput)
      if (rgb) {
        const [h, s, v] = rgbToHsv(...rgb)
        this.hue = h
        this.saturation = s
        this.valueBrightness = v
        this.$emit('input', this.hexInput)
      }
    },
    startSVPick(e) {
      this.picking = true
      this.updateSV(e)
    },
    moveSVPick(e) {
      if (this.picking) this.updateSV(e)
    },
    startHuePick(e) {
      this.pickingHue = true
      this.updateHue(e)
    },
    moveHuePick(e) {
      if (this.pickingHue) this.updateHue(e)
    },
    stopPick() {
      this.picking = false
      this.pickingHue = false
    },
    updateSV(e) {
      const rect = this.$refs.sv.getBoundingClientRect()
      this.saturation = Math.min(1, Math.max(0, (e.clientX - rect.left) / rect.width))
      this.valueBrightness = 1 - Math.min(1, Math.max(0, (e.clientY - rect.top) / rect.height))
    },
    updateHue(e) {
      const rect = this.$refs.hue.getBoundingClientRect()
      this.hue = Math.min(1, Math.max(0, (e.clientX - rect.left) / rect.width))
    },
  },
  mounted() {
    this.hexInput = this.value
    this.onHexChange()
  },
}
</script>

<style scoped>
.web-bot-color-edit {
  width: 100%;
}

.curr-color {
  padding: 12px;
  background-color: rgb(255, 252, 245);
  border-radius: 4px;
  position: relative;
  border: 1px solid black;
  width: 100%;
}

.curr-color span {
  width: 100%;
  height: 12px;
  border: none;
  display: block;
  cursor: pointer;
}

.color-picker {
  width: 200px;
  user-select: none;
  font-family: sans-serif;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(36px);
  z-index: 99999;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}
.sv-panel {
  position: relative;
  width: 100%;
  height: 100px;
  background: linear-gradient(to right, #fff, transparent),
    linear-gradient(to top, #000, transparent);
  cursor: crosshair;
  border-radius: 8px;
  border: 1px solid silver;
}
.sv-cursor {
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid #fff;
  border-radius: 50%;
  transform: translate(-6px, -6px);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}
.hue-slider {
  position: relative;
  margin-top: 10px;
  height: 12px;
  border-radius: 8px;
  background: linear-gradient(to right, red, yellow, lime, cyan, blue, magenta, red);
  cursor: pointer;
}
.hue-cursor {
  position: absolute;
  top: -1px;
  width: 14px;
  height: 14px;
  transform: translateX(-7px);
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}
.hex-input {
  padding: 6px 12px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  text-align: center;
}

.preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
  border: 1px solid silver;
}

.color-picker__ctrl {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
