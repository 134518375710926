import axios from 'axios'

export const apiFunction = {
  // Show current account in account page
  // ***required fields
  // **
  // "adminid": "string"
  // **
  // ***
  getAccount: function (formData, jwtToken) {
    return axios({
      method: 'POST',
      url: process.env.VUE_APP_API_ENDPOINT + 'getAccount',
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        return res
      })
      .catch((e) => {
        console.log(e.response)
        return { error: e.response ? e.response : 'null' }
      })
  },

  // Create new account
  // ***required fields
  // **
  // "adminid": "string",
  // "accountid": "string",
  // "accountName": "string",
  // "accountType": "string",
  // "accountIcon": "string",
  // "AccountWebhookUrl": "string",
  // "AccountToken": "string",
  // "AccountSecret": "string"
  // **
  // ***
  createAccount: function (formData, jwtToken) {
    return axios({
      method: 'POST',
      url: process.env.VUE_APP_API_ENDPOINT + 'createAccount',
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    })
      .then((res) => {
        location.reload()
        return res
      })
      .catch((e) => {
        console.log(e)
        return { error: e.response ? e.response : 'null' }
      })
  },
}
