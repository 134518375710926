import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active"
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    try {
      // comment this line to disable auth check, actaully don't know why we need this 
      // await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      if (!localStorage._token) {
        next({ name: 'account' })
      } else {
        next() // go to wherever I'm going
      }
    } catch (e) {
      next({
        path: "/login"
      });
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router;
