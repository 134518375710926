<template>
  <div class="bubble-editor">
    <h4>{{ $t("__bubble") }} {{ index + 1 }}</h4>

    <div class="form-group">
      <label>{{ $t("__image") }}</label>

      <div class="form-check">
        <vue-dropzone
          ref="bubbleImageDropzone"
          id="bubbleImageDropzone"
          :options="dropzoneOptions"
          v-on:vdropzone-file-added="handleUploadImage"
        ></vue-dropzone>
      </div>
      <div v-if="bubble.image.url" class="mt-2">
        <label>{{ $t("__imageUrl") }}</label>
        <input
          type="text"
          :value="bubble.image.url"
          class="form-control"
          readonly
          disabled
        />
        <label>{{ $t("__imageLinkUri") }}</label>
        <input
          type="text"
          v-model="bubble.image.linkUri"
          maxlength="2000"
          class="form-control"
          required
          @input="updateBubble"
        />
      </div>
    </div>
    <div class="border my-3"></div>

    <button class="btn btn-danger mt-3" @click="$emit('deleteBubble')">
      {{ $t("__deleteBubble") }}
    </button>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  props: ["bubble", "index", "uploadImage", "dropzoneOptions"],
  components: { vueDropzone: vue2Dropzone },

  methods: {
    async handleUploadImage(file) {
      if (file)
        try {
          const imgUrl = await this.uploadImage(file);
          this.bubble.image.url = imgUrl;
          this.updateBubble();
        } catch (error) {
          console.error("Image upload failed: ", error);
        }
    },

    updateBubble() {
      this.$emit("updateBubble", this.index, this.bubble);
    },
  },
};
</script>
