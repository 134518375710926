var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar",
      attrs: {
        "data-background-color": _vm.backgroundColor,
        "data-active-color": _vm.activeColor
      }
    },
    [
      _c(
        "div",
        { staticClass: "sidebar-wrapper", attrs: { id: "style-3" } },
        [
          _c(
            "div",
            {
              staticClass: "logo",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.redirectToAccount }
            },
            [_vm._m(0), _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
          _vm._t("default"),
          _c(
            "ul",
            { staticClass: "nav", attrs: { id: "sidebar-link" } },
            [
              _vm._t(
                "links",
                _vm._l(_vm.sidebarLinks, function(link, index) {
                  return _c("sidebar-link", {
                    key: index,
                    attrs: { to: link.path, name: link.name, icon: link.icon }
                  })
                })
              )
            ],
            2
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-img" }, [
      _c("img", { attrs: { src: require("@/assets/img/logo.png"), alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }