<template>
  <div>
    <div class="mt-2 mb-3">
      <label> {{ $t('__Form') }}</label>
      <a href="javascript:void(0)" class="text-info m-1" @click="showCreateFormModal()">
        <i class="fas fa-plus text-info"></i>
      </a>
      <div class="input-group mb-1" v-for="(varFormId, index) in allVarFormIds" :key="index">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input
              type="checkbox"
              :value="varFormId"
              :id="'varFormId' + index"
              v-model="checkedFormIds"
            />
          </div>
        </div>
        <div class="py-2 border rounded col">
          <span>{{ varFormNameById(varFormId) }}</span>
          <a class="pull-right" href="javascript:void(0)" @click="removeVarForm(varFormId)">
            <i class="fas fa-trash text-danger mx-1"></i>
          </a>
          <a class="pull-right" href="javascript:void(0)" @click="editVarForm(varFormId)">
            <i class="fas fa-pencil-alt cursor-pointer mx-1"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'var-form-setting',
  components: {},
  props: {
    allVarForms: Array,
    varForms: Array,
  },
  data() {
    return {
      allVarFormIds: [],
      checkedFormIds: [],
    }
  },
  watch: {
    allVarForms: {
      deep: true,
      handler() {
        this.updateAllVarFormIds()
      },
    },
    varForms: {
      deep: true,
      handler(val) {
        let needUpdated = false
        val.forEach((form) => {
          let formId = form.formId
          if (this.checkedFormIds.indexOf(formId) <= -1) {
            needUpdated = true
          }
        })
        if (needUpdated) {
          this.updateCheckedFormIds()
        }
      },
    },
    checkedFormIds: {
      deep: true,
      handler(val) {
        let varForms = []
        val.forEach((formId) => {
          varForms.push({
            formId: formId,
          })
        })
        this.$emit('update', 'varForms', varForms)
      },
    },
  },
  created: function () {
    this.updateAllVarFormIds()
    this.updateCheckedFormIds()
  },
  methods: {
    updateAllVarFormIds() {
      this.allVarFormIds.splice(0)
      this.allVarForms.forEach((form) => {
        this.allVarFormIds.push(form.id)
      })
    },
    updateCheckedFormIds() {
      let newCheckFormIds = []
      this.varForms.forEach((form) => {
        newCheckFormIds.push(form.formId)
      })
      this.checkedFormIds = newCheckFormIds
    },
    varFormNameById(formId) {
      let name = ''
      let form = this.allVarForms.find((form) => form.id === formId)
      if (form) {
        name = form.name
      }
      return name
    },
    editVarForm(formId) {
      let form = this.allVarForms.find((form) => form.id === formId)
      this.$emit('showCreateFormModal', form)
    },
    removeVarForm(varFormId) {
      this.$emit('deleteForm', varFormId)
    },
    showCreateFormModal() {
      this.$emit('showCreateFormModal')
    },
  },
}
</script>
