<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>Copyright © ASNET Co., Ltd. {{ currentYear }}</span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
<style></style>
